// External Imports
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

// Internal Imports
import { plainTextNoHTML } from '../../config/sanitizeHtml.mjs';

//  Search engines should not show a cached link to the page on a SERP.
const metaRobotsNoarchive = <meta name="robots" content="noarchive" />;

// Component Definition
function Meta({ metaTags, title }) {
  const metaTitle = metaTags.find((tag) => tag.name === 'title');
  const metaTitleContent = metaTitle && metaTitle.content;
  let containsRobots = false;

  return (
    <Helmet>
      <title>{metaTitleContent || title}</title>
      {metaTags.map(({ name, content }) => {
        // Special exception for OpenGraph tags improperly using the property attribute instead of name
        let updatedContent = content;
        if (name === 'robots') {
          // If content field does not contain archive or noarchive, add noarchive
          if (updatedContent.search('archive') === -1) {
            updatedContent = `${content}, noarchive`;
          }
          containsRobots = true;
        }

        if (name === 'is_live_specific' && content === true) {
          return <meta key={name} name="product_category" content="live" />;
        }

        return /^(?:og|fb):/.test(name) ? (
          <meta
            key={name}
            property={name}
            content={sanitizeHtml(updatedContent, plainTextNoHTML)}
          />
        ) : (
          <meta key={name} name={name} content={sanitizeHtml(updatedContent, plainTextNoHTML)} />
        );
      })}
      {containsRobots ? undefined : metaRobotsNoarchive}
    </Helmet>
  );
}

Meta.propTypes = {
  metaTags: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
};

Meta.defaultProps = {
  metaTags: [],
  title: 'Babbel Magazine',
};

// Module Exports
export { Meta };
