// External Imports
import get from 'lodash/fp/get.js';
import omit from 'lodash/omit.js';
import { createSelector } from 'reselect';
import { compile } from 'path-to-regexp';

// Internal Imports
import { createDebug } from '../utils/createDebug.mjs';
import { selectMatch } from './selectMatch.mjs';

// Local Variables
const debugRouter = createDebug('router');

// Local Functions
const selectRouteKey = createSelector(
  [selectMatch, get('router')],
  ({ params, path, pathType }, { location: { pathname, search } }) => {
    let routerPath = pathname
      // Make key safe for lodash path resolution.
      .replaceAll('.', '')
      // Cap string length to avoid paths of very long strings.
      // Lodash memoizes path resolution for performance, so if
      // we pass in a large string, that will cause large chunks
      // of memory to be taken up without ever being freed by GC.
      // We also shouldn't need a path longer than 100 characters
      // to uniquely identify a route.
      .slice(0, 100);

    // TODO: REMOVE THIS ONCE BERLIN'S REDIRECTS STOP STRIPPING TRAILING SLASHES FROM REQUESTS
    if (routerPath !== '/') {
      routerPath = routerPath.replace(/\/$/, '');
    }

    debugRouter(`pathType: ${pathType}`);

    // Do not include route :page param in the route key in order to keep
    // all cached `pages` under the same route key
    if (routerPath && Object.keys(params).length > 0 && get('page', params)) {
      const toPath = compile(path);
      const pathWithoutPagination = toPath(omit(params, 'page'));

      debugRouter(`path: ${pathWithoutPagination}`);
      return `${pathWithoutPagination}${search}`;
    }

    debugRouter(`path: ${routerPath}`);
    return `${routerPath}${search}`;
  },
);

// Module Exports
export { selectRouteKey };
