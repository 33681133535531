// External Imports
import React from 'react';
import PropTypes from 'prop-types';

// Internal Imports
import { withLinkLogic } from './withLinkLogic.jsx';

// Component Definition
function UnstyledLinkBase({ as: Component, ...props }) {
  if (Component) {
    // eslint-disable-next-line react/jsx-props-no-spreading -- props spreading required as this is a generic component
    return <Component {...props} />;
  }
  // eslint-disable-next-line jsx-a11y/anchor-has-content, react/jsx-props-no-spreading -- content will be part of props, props spreading required as this acts as a generic component
  return <a {...props} />;
}

UnstyledLinkBase.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};

UnstyledLinkBase.defaultProps = {
  as: undefined,
};

const UnstyledLink = withLinkLogic(UnstyledLinkBase);

// Module Exports
export { UnstyledLink };
