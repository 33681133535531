import { breakpoints } from '../css/breakpoints.mjs';

/**
 * Configuration for responsive image sizes
 *
 * Should contain the following:
 * - createTransformSourcePhoton factory function. See 'src/utils/images/photon.js' for an example
 * - positions object containing configurations for each position in which an image could appear on the site.
 *    Each position config should contain:
 *    - sizes: an array of objects containing a `mediaQuery` and `value` to be use for constructing the `sizes` attribute
 *    - sources: an array of objects containing transformation properties to be used for generating the `srcset` attribute.
 *        NOTE: currently this must contain a `width` property in order to generate a descriptor for each souce in the `srcset`
 *    - any other arbitrary properties you need to use in the createTransformSourcePhoton function
 *
 * TODO: refactor to allow a `descriptor` property for use as a custom descriptor (you might want to use a density descriptor, for example, instead of a `w`)
 */
const positions = {
  authorHeader: {
    sizes: [
      {
        mediaQuery: breakpoints.mobile.min,
        value: '220px',
      },
      { value: '140px' },
    ],
    sources: [
      { height: 220, width: 220 },
      { height: 440, width: 440 },
      { height: 140, isDefault: true, width: 140 },
      { height: 280, width: 280 },
    ],
  },
  authorOverview: {
    sizes: [
      {
        mediaQuery: breakpoints.tabletPortrait.min,
        value: '140px',
      },
      { value: '72px' },
    ],
    sources: [
      { height: 140, isDefault: true, width: 140 },
      { height: 280, width: 280 },
      { height: 72, width: 72 },
      { height: 144, width: 144 },
    ],
  },
  featuredImage: {
    sizes: [
      {
        mediaQuery: breakpoints.tabletLandscape.min,
        value: '830px',
      },
      {
        mediaQuery: breakpoints.mobile.min,
        value: '85vw',
      },
      { value: '100vw' },
    ],
    sources: [
      { height: 467, width: 830 },
      { height: 675, width: 1_200 },
      { height: 360, isDefault: true, width: 640 },
      { height: 540, width: 960 },
      { height: 287, width: 511 },
      { height: 431, width: 766 },
    ],
    transform: 'resize',
  },
  featuredImageFlexible: {
    quality: 100,
    sizes: [{ value: '993px' }],
    sources: [{ isDefault: true, width: 993 }],
    transform: 'none',
  },
  full: {
    sizes: [],
    sources: [],
  },
  linearListView: {
    sizes: [
      {
        mediaQuery: breakpoints.mobile.min,
        value: '223px',
      },
      { value: '80vw' },
    ],
    sources: [
      { height: 125, isDefault: true, width: 223 },
      { height: 270, width: 480 },
      { height: 297, width: 528 },
      { height: 342, width: 608 },
      { height: 405, width: 720 },
    ],
    transform: 'resize',
  },
  sample: {
    quality: 60,
    sizes: [
      {
        mediaQuery: breakpoints.tabletLandscape.min,
        value: '1280px',
      },
      { value: '300px' },
    ],
    sources: [
      { height: 720, isDefault: true, width: 1_280 },
      { height: 200, width: 300 },
    ],
    transform: 'resize',
  },
  threeUpListView: {
    sizes: [
      {
        mediaQuery: breakpoints.desktop.min,
        value: '394px',
      },
      {
        mediaQuery: breakpoints.mobile.min,
        value: '30vw',
      },
      { value: '80vw' },
    ],
    sources: [
      { height: 270, isDefault: true, width: 480 },
      { height: 405, width: 720 },
      { height: 222, width: 394 },
      { height: 342, width: 608 },
      { height: 174, width: 310 },
      { height: 297, width: 528 },
    ],
    transform: 'resize',
  },
  twoUpListView: {
    sizes: [
      {
        mediaQuery: breakpoints.mobile.min,
        value: '45vw',
      },
      { value: '80vw' },
    ],
    sources: [
      { height: 342, width: 608 },
      { height: 615, width: 1_094 },
      { height: 270, isDefault: true, width: 480 },
      { height: 405, width: 720 },
      { height: 198, width: 352 },
      { height: 297, width: 528 },
    ],
    transform: 'resize',
  },
};

// Module Exports
export { positions };
export { createTransformSourcePhoton } from '../../utils/photon.mjs';
