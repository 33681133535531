// External Imports
import { connect } from 'react-redux';
import React from 'react';
import { Button, Header, Stack } from '@lessonnine/design-system.lib';
import PropTypes from 'prop-types';

// Internal Imports
import { buildCTALink } from '../../../utils/url.mjs';
import { selectEdition } from '../../../selectors/selectEdition.mjs';
import { I18n } from '../../I18n/I18n.jsx';
import { LANGUAGES } from '../../../config/types/prop.mjs';
import { MobileNavExplore } from '../NavExplore/MobileNavExplore.jsx';
import { options } from '../../../config/reactRedux.mjs';
import { SearchForm } from '../../SearchForm/SearchForm.jsx';
import { actionCtaClicked } from '../../../actions/index.mjs';

// Component Definition
function MenuNavBase({ currentEdition, dispatchActionCtaClicked }) {
  const ctaUrl = buildCTALink(currentEdition);
  return (
    <Stack gap={32}>
      {/* eslint-disable-next-line react/jsx-pascal-case -- External import */}
      <Header.h2>
        <I18n
          field="which-language-do-you-want-to-learn"
          defaultText="Speak a new langauge with confidence"
        />
      </Header.h2>
      <Button
        as="a"
        onClick={() => dispatchActionCtaClicked({ placement: 'hamburger', url: ctaUrl })}
        href={ctaUrl}
      >
        <I18n field="try-babbel" defaultText="Try Babbel" />
      </Button>
      <SearchForm />
      <MobileNavExplore />
    </Stack>
  );
}

MenuNavBase.propTypes = {
  currentEdition: LANGUAGES.isRequired,
  dispatchActionCtaClicked: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentEdition: selectEdition(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchActionCtaClicked: (payload) => {
    dispatch(actionCtaClicked(payload));
  },
});

const withRedux = connect(mapStateToProps, mapDispatchToProps, undefined, options);
const MenuNav = withRedux(MenuNavBase);

// Module Exports
export { MenuNav };
