// External Imports
import React from 'react';
import { Stack } from '@lessonnine/design-system.lib';

// Internal Imports
import { ContentList } from '../ContentList/ContentList.jsx';
import { LANDING_PAGE } from '../../config/types/prop.mjs';
import { ShowMoreButton } from '../ShowMoreButton/ShowMoreButton.jsx';
import { ListViewContext } from '../../context/listView.mjs';
import { useAccessibilityFocusLastAfterLoading } from '../../hooks/useAccessibilityFocusLastAfterLoading.mjs';
import { Divider } from '../Divider/Divider.jsx';
import { ScreenReaderOnly } from '../ScreenReaderOnly/ScreenReaderOnly.jsx';
import { I18n } from '../I18n/I18n.jsx';

// Component Definition
function Homepage({ relatedItems, loadingMore, onLoadMore, pagination: { hasMore } }) {
  const handleFocus = useAccessibilityFocusLastAfterLoading({ loading: loadingMore });
  const showLoadMore = hasMore || loadingMore;

  // Sequences of two-up and three-up sections.
  // If more articles can be loaded, only renders complete rows,
  // Otherwise, the last row may be incomplete.
  const feedSequences = hasMore
    ? [
        [relatedItems.slice(0, 2), relatedItems.slice(2, 8)],
        [relatedItems.slice(8, 10), relatedItems.slice(10, -1)],
      ]
    : [
        [relatedItems.slice(0, 2), relatedItems.slice(2, 8)],
        [relatedItems.slice(8, 10), relatedItems.slice(10)],
      ];

  return (
    <section>
      <ScreenReaderOnly as="h1">
        <I18n key="homepage-h1" defaultText="Homepage" />
      </ScreenReaderOnly>
      <Stack>
        {feedSequences.map((sequence) => (
          <>
            <ListViewContext.Provider value="twoUp">
              <ContentList
                onFocus={handleFocus}
                items={sequence[0]}
                interstitials={[
                  {
                    content: <Divider />,
                    count: Number.POSITIVE_INFINITY,
                    frequency: 3,
                  },
                ]}
              />
            </ListViewContext.Provider>
            <Divider />
            <ListViewContext.Provider value="threeUp">
              <ContentList
                onFocus={handleFocus}
                items={sequence[1]}
                interstitials={[
                  {
                    content: <Divider />,
                    count: Number.POSITIVE_INFINITY,
                    frequency: 3,
                  },
                ]}
              />
            </ListViewContext.Provider>
            <Divider />
          </>
        ))}
        {showLoadMore && (
          <ShowMoreButton onClick={onLoadMore} disabled={loadingMore} loading={loadingMore} />
        )}
      </Stack>
    </section>
  );
}

Homepage.propTypes = LANDING_PAGE;

// Module Exports
export { Homepage };
