// Internal Imports
import { sendEventToGA } from '../../../utils/googleAnalytics.mjs';
import { trackPageEvent } from '../../../utils/tracking.mjs';

// Local Functions
const makeFlagLinkOnClick =
  ({ flagType, trackingData, url }) =>
  () => {
    sendEventToGA(flagType);
    trackPageEvent({
      cta_placement: 'flags',
      link_url: url,
      name: 'magazine_page:cta_clicked',
      version: 2,
      ...trackingData,
    });
  };

// Module Exports
export { makeFlagLinkOnClick };
