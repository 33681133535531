// Internal Imports
import {
  SHORTLINK_CODE_LANDING_PAGE_DEU,
  SHORTLINK_CODE_LANDING_PAGE_ENG,
  SHORTLINK_CODE_LANDING_PAGE_FRA,
  SHORTLINK_CODE_LANDING_PAGE_ITA,
  SHORTLINK_CODE_LANDING_PAGE_POL,
  SHORTLINK_CODE_LANDING_PAGE_POR,
  SHORTLINK_CODE_LANDING_PAGE_SPA,
  SHORTLINK_CODE_LANDING_PAGE_SWE,
  SHORTLINK_CODE_LANDING_PAGE_USA,
} from '../config/shortlinkCodes.mjs';
import {
  ENGLISH_USA,
  FRENCH,
  GERMAN,
  ITALIAN,
  POLISH,
  PORTUGUESE,
  SPANISH,
  SWEDISH,
} from '../config/types/language.mjs';

// Local Functions
const getFacebookLocale = (languageCode) => {
  switch (languageCode) {
    case FRENCH:
      return 'fr_FR';
    case GERMAN:
      return 'de_DE';
    case ITALIAN:
      return 'it_IT';
    case POLISH:
      return 'pl_PL';
    case PORTUGUESE:
      return 'pt_BR';
    case SPANISH:
      return 'es_ES';
    case SWEDISH:
      return 'sv_SE';
    default:
      return 'en_US';
  }
};

const getFacebookPageIDs = (languageCode) => {
  switch (languageCode) {
    case FRENCH:
      return ['730630120408466'];
    case GERMAN:
      return ['522040488002422'];
    case ITALIAN:
      return ['315019868861144'];
    case PORTUGUESE:
      return ['347605872246588'];
    case SPANISH:
      return ['1576231026019053'];
    case POLISH:
    case SWEDISH:
      return ['23820609833'];
    default:
      return ['23820609833', '1272562162872574'];
  }
};

const getLandingPageShortcode = (languageCode) => {
  switch (languageCode) {
    case ENGLISH_USA:
      return SHORTLINK_CODE_LANDING_PAGE_USA;
    case FRENCH:
      return SHORTLINK_CODE_LANDING_PAGE_FRA;
    case GERMAN:
      return SHORTLINK_CODE_LANDING_PAGE_DEU;
    case ITALIAN:
      return SHORTLINK_CODE_LANDING_PAGE_ITA;
    case POLISH:
      return SHORTLINK_CODE_LANDING_PAGE_POL;
    case PORTUGUESE:
      return SHORTLINK_CODE_LANDING_PAGE_POR;
    case SPANISH:
      return SHORTLINK_CODE_LANDING_PAGE_SPA;
    case SWEDISH:
      return SHORTLINK_CODE_LANDING_PAGE_SWE;
    default:
      return SHORTLINK_CODE_LANDING_PAGE_ENG;
  }
};

const getMetaImageDimensions = (metaList) => {
  const imageMeta = metaList.find((meta) => 'name' in meta && meta.name === 'og:image');
  if (!imageMeta || !imageMeta.content) {
    return;
  }

  const dimensions = imageMeta.content.match(/resize=(\d+),(\d+)/);
  if (!dimensions[1] || !dimensions[2]) {
    return;
  }

  // eslint-disable-next-line consistent-return -- optional return
  return {
    height: dimensions[2],
    width: dimensions[1],
  };
};

// Module Exports
export { getFacebookLocale, getFacebookPageIDs, getLandingPageShortcode, getMetaImageDimensions };
