// External Imports
import PropTypes from 'prop-types';
import React from 'react';

// Internal Imports
import { languageToFlagMap } from './languageToFlagMap.mjs';
import { PlusIllustration } from './PlusIllustration.jsx';
import { FlagItemPropTypes } from './flagItemPropTypes.mjs';

// Component Definition
function FlagIcon({ flagCode, size }) {
  if (flagCode === 'plus') {
    return <PlusIllustration size={size} />;
  }
  const Flag = languageToFlagMap[flagCode];
  return <Flag height={size} width={size} />;
}

FlagIcon.propTypes = {
  // eslint-disable-next-line react/require-default-props -- already required at the definition site
  flagCode: FlagItemPropTypes.flagCode,
  size: PropTypes.number.isRequired,
};

// Module exports
export { FlagIcon };
