// External Imports
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from '@lessonnine/design-system.lib';

// Internal Imports
import { getDisplayName } from '../../../utils/getDisplayName.mjs';

// Component Definition
function WithLoader(WrappedComponent) {
  function Loader({ loading, ...props }) {
    // eslint-disable-next-line react/jsx-props-no-spreading -- allow spread
    return loading ? <Spinner size={120} /> : <WrappedComponent {...props} />;
  }

  Loader.propTypes = {
    loading: PropTypes.bool.isRequired,
  };

  Loader.displayName = getDisplayName('Loader', WrappedComponent);

  return Loader;
}

// Module Exports
export { WithLoader };
