// External Imports
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Inline, pxToRem, Stack, styled, Text } from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

// Internal Imports
import { buildCTALink } from '../../utils/url.mjs';
import { createSelectTranslation } from '../../selectors/createSelectTranslation.mjs';
import { selectEdition } from '../../selectors/selectEdition.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { LANGUAGE, LANGUAGES } from '../../config/types/prop.mjs';
import { LanguagePickerCondensed } from './LanguagePickerCondensed.jsx';
import { numberOfVisibleFlags } from './numberOfVisibleFlags.mjs';
import { options } from '../../config/reactRedux.mjs';
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { SpacedItem } from '../SpacedItem/SpacedItem.jsx';
import { FlagItemBig } from './FlagLanguageItem/FlagItemBig.jsx';
import { siteInnerMaxWidth } from '../../config/css/ui.mjs';

const StickyMobileWrapper = styled.aside`
  @media ${breakpoints.tabletPortrait.max} {
    position: sticky;
    top: 0;
    z-index: 3;
  }
`;

const LeituraText = styled(Text)`
  font-family: ${tokens.fonts.expressive.name};
`;

const DesktopInlineWrapper = styled(Inline)`
  border-bottom: solid 1px ${({ theme }) => theme.color.surface.layer.separator};
  display: none;
  margin: auto;
  max-width: ${pxToRem(siteInnerMaxWidth)};

  @media ${breakpoints.tabletPortrait.min} {
    display: flex;
  }
`;

const TitleWrapper = styled(Stack)`
  flex-shrink: 1;
`;

const SpacedElement = styled(SpacedItem)`
  @media ${breakpoints.tabletLandscape.max} {
    margin: 0 ${pxToRem(6)};
  }
`;

class MastheadLanguagePickerBase extends Component {
  constructor(props) {
    super(props);
    this.masthead = undefined;
    this.intersectionObserver = undefined;

    this.state = {
      isSticky: false,
    };
  }

  componentDidMount() {
    this.intersectionObserver = new IntersectionObserver(
      ([entry]) => this.setState({ isSticky: entry.intersectionRatio < 1 }),
      {
        threshold: 1,
      },
    );

    this.intersectionObserver.observe(this.masthead);
  }

  componentWillUnmount() {
    this.intersectionObserver.unobserve(this.masthead);
  }

  render() {
    const { links, currentEdition, getTranslation } = this.props;
    const { isSticky } = this.state;
    const showingLanguages = links.slice(0, numberOfVisibleFlags);
    const goLink = buildCTALink(currentEdition);
    const seeMoreText = getTranslation('masthead-more', 'More...');

    return (
      <>
        <aside title="Language picker">
          <DesktopInlineWrapper
            align="center"
            alignItems="center"
            gap={{ small: 35, xsmall: 20 }}
            padding={[32, 10]}
          >
            <TitleWrapper gap={20} wrap>
              <LeituraText fontSize="huge" fontWeight={700}>
                <I18n field="masthead-title" defaultText="Ready to learn?" />
              </LeituraText>
              <Text fontSize="small">
                <I18n field="masthead-subtitle" defaultText="" />
              </Text>
            </TitleWrapper>
            <Inline as="ul">
              {showingLanguages.map((item) => (
                <SpacedElement as="li" key={`${item.text}${item.url}`} space={32}>
                  <FlagItemBig
                    flagCode={item.flag}
                    flagType="masthead_button"
                    text={item.text}
                    url={item.url}
                  />
                </SpacedElement>
              ))}
              {links.length > numberOfVisibleFlags && (
                <SpacedElement as="li" space={32}>
                  <FlagItemBig
                    flagCode="plus"
                    flagType="masthead_button"
                    text={seeMoreText}
                    url={goLink}
                  />
                </SpacedElement>
              )}
            </Inline>
          </DesktopInlineWrapper>
        </aside>
        <StickyMobileWrapper
          ref={(masthead) => {
            this.masthead = masthead;
          }}
          title="Condensed language picker"
        >
          <LanguagePickerCondensed
            currentEdition={currentEdition}
            getTranslation={getTranslation}
            isSticky={isSticky}
            languages={links}
          />
        </StickyMobileWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const currentEdition = selectEdition(state);
  const languagesByEdition = get('languagePicker', state);
  const getTranslation = createSelectTranslation(state);

  return {
    ...languagesByEdition[currentEdition],
    currentEdition,
    getTranslation,
  };
};

MastheadLanguagePickerBase.propTypes = {
  currentEdition: LANGUAGES.isRequired,
  getTranslation: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape(LANGUAGE)),
};

MastheadLanguagePickerBase.defaultProps = {
  links: [],
};

const withRedux = connect(mapStateToProps, undefined, undefined, options);
const MastheadLanguagePicker = withRedux(MastheadLanguagePickerBase);

// Module Exports
export { MastheadLanguagePicker };
