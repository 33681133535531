// External Imports
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { stringify } from 'query-string';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Inline,
  TextField,
  IconMagnifyingGlass,
  styled,
} from '@lessonnine/design-system.lib';

// Internal Imports
import { createSelectTranslation } from '../../selectors/createSelectTranslation.mjs';
import { selectSearchPath, selectSearchParameters } from '../../selectors/selectSearch.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { options } from '../../config/reactRedux.mjs';
import { ToggleContainerContext } from '../../context/toggleContainer.mjs';

const TextFieldContainer = styled.div`
  display: flex;
  flex-grow: 1;

  /* TODO: this is needed because the DS FullWidth doesnt seem to work */
  div {
    flex-grow: 1;
  }
`;

function SearchFormBase({ params, search, getTranslation }) {
  const [searchParameter, setSearchParameter] = useState(params.s);
  // TODO: currently the design-system TextField doesn't support ref, so finding it through the DOM for now but it's not great and we should update
  const searchInputContainer = useRef();
  const { close: closeContainer, isOpened } = useContext(ToggleContainerContext);
  const previouslyOpened = useRef();

  const onSubmit = useCallback(
    (formEvent) => {
      formEvent.preventDefault();

      // Hide search overlay, if it's open
      closeContainer();

      // Auto-hide mobile, software keyboards
      const blurElement = formEvent.currentTarget[0];
      blurElement.focus();
      blurElement.blur();
      const searchTerm = searchParameter && searchParameter.trim();
      if (searchTerm) {
        search({ s: searchTerm });
      }
    },
    [closeContainer, search, searchParameter],
  );

  const closeContainerOnEsc = useCallback(
    (keyDownEvent) => {
      if (keyDownEvent.key === 'Esc' || keyDownEvent.key === 'Escape') {
        closeContainer();
      }
    },
    [closeContainer],
  );

  useEffect(() => {
    if (previouslyOpened.current === false && isOpened) {
      searchInputContainer.current.querySelector('input').focus();
    }
    previouslyOpened.current = isOpened;
  }, [isOpened]);

  useEffect(() => {
    setSearchParameter(params.s);
  }, [params.s]);

  return (
    <form action="/" onSubmit={onSubmit} role="search">
      <Inline alignItems="trailing" gap={24}>
        <TextFieldContainer ref={searchInputContainer}>
          <TextField
            name="search"
            type="search"
            /* ref={searchInputElement} */
            placeholder={getTranslation('search-bar', 'Search Babbel Magazine')}
            value={searchParameter}
            onChange={(changeEvent) => setSearchParameter(changeEvent.target.value)}
            onKeyDown={closeContainerOnEsc}
            fullWidth
            icon={<IconMagnifyingGlass />}
          />
        </TextFieldContainer>
        <Button color="secondary" type="submit">
          <I18n field="search" defaultText="Search" />
        </Button>
      </Inline>
    </form>
  );
}

SearchFormBase.propTypes = {
  getTranslation: PropTypes.func.isRequired,
  params: PropTypes.shape({ s: PropTypes.string.isRequired }).isRequired,
  search: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getTranslation: createSelectTranslation(state),
  params: selectSearchParameters(state),
  searchPath: selectSearchPath(state),
});

const mapDispatchToProps = (dispatch) => ({
  search: (searchPath) => (parameters) => {
    const queryString = stringify(parameters, { arrayFormat: 'bracket' });
    dispatch(push(`${searchPath}?${queryString}`));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  search: dispatchProps.search(stateProps.searchPath),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps, mergeProps, options);
const SearchForm = withRedux(SearchFormBase);

// Module Exports
export { SearchForm };
