// External Imports
import { styled } from '@lessonnine/design-system.lib';

// Component Definition
const ScreenReaderOnly = styled.div`
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
`;

// Module Exports
export { ScreenReaderOnly };
