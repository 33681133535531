// Internal Imports
import { logger } from './rollbar.mjs';

// Local Functions
// Cookie access can be blocked by different policy, so to access safely we should wrap the access in a try/catch
const safeGetCookie = () => {
  if (typeof document === 'undefined') {
    return '';
  }
  try {
    return document.cookie;
  } catch (error) {
    logger.warning('Error when trying to access cookies', error);
  }
  return '';
};

// Module Exports
export { safeGetCookie };
