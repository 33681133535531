// External Imports
import React from 'react';
import PropTypes from 'prop-types';

// Internal Imports
import { CONTENT_ITEM } from '../../config/types/prop.mjs';

// Component Definition
function DefaultLandingPage({ item }) {
  return <div>{item.title}</div>;
}

DefaultLandingPage.propTypes = {
  item: PropTypes.shape(CONTENT_ITEM).isRequired,
};

// Module Exports
export { DefaultLandingPage };
