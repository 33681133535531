// External Imports
import sanitizeHtml from 'sanitize-html';

// Internal Imports
import { richText } from '../config/sanitizeHtml.mjs';

// Local Functions
const setRichTextInnerHTML = (content) => ({ __html: sanitizeHtml(content, richText) });

// Module Exports
export { setRichTextInnerHTML };
