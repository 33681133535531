// External Imports
import React, { Component } from 'react';
import { Stack, styled } from '@lessonnine/design-system.lib';

// Internal Imports
import { ShareButtons } from '../ShareButtons/ShareButtons.jsx';

// TODO: eventually it might make sense to get this from a ref to the actual sticky header
const stickyHeaderHeight = 140;

const Wrapper = styled(Stack)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
`;

// TODO: this could be a Stack once Layout components in the design-system support refs
const StickyContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;

  ${({ isStickyTop }) =>
    isStickyTop &&
    `
      position: fixed;
      top: ${stickyHeaderHeight}px;
    `}

  ${({ isStickyBottom }) =>
    isStickyBottom &&
    `
      bottom: 0;
      position: absolute;
      top: auto;
    `}
`;

class StickyShareButtons extends Component {
  constructor(props) {
    super(props);

    this.topPin = undefined;
    this.bottomPin = undefined;

    this.state = {
      isStickyBottom: false,
      isStickyTop: false,
    };
  }

  componentDidMount() {
    // const { height, top } = this.stickyButtonsContainerRef.getBoundingClientRect();
    // const stickyContainerHeight = height + top;
    const { height: stickyContainerHeight } =
      this.stickyButtonsContainerRef.getBoundingClientRect();
    this.intersectionObserverForTopPin = new IntersectionObserver(
      ([entry]) => this.setState({ isStickyTop: entry.intersectionRatio < 1 }),
      {
        rootMargin: `-${stickyHeaderHeight}px 0px 1000000% 0px`,
        // Intersection will be detected when viewport top + headerHeight (134px) will reach the observed element
        // Setting rootMargin bottom to 1000000% will make sure the observed element will never intersect with the bottom of viewport
        threshold: 1,
      },
    );

    this.intersectionObserverForBottomPin = new IntersectionObserver(
      ([entry]) => this.setState({ isStickyBottom: entry.intersectionRatio < 1 }),
      {
        rootMargin: `-${stickyHeaderHeight + stickyContainerHeight}px 0px 1000000% 0px`,
        // Intersection will be detected when viewport top + (headerHeight (134px) + ShareButtons height (254px)) + ShareButtonsBottomMargin (20px) will reach the observed element
        // Setting rootMargin bottom to 1000000% will make sure the observed element will never intersect with the bottom of viewport
        threshold: 1,
      },
    );

    this.intersectionObserverForTopPin.observe(this.topPin);
    this.intersectionObserverForBottomPin.observe(this.bottomPin);
  }

  componentWillUnmount() {
    this.intersectionObserverForTopPin.unobserve(this.topPin);
    this.intersectionObserverForBottomPin.unobserve(this.bottomPin);
  }

  render() {
    const { isStickyBottom, isStickyTop } = this.state;
    return (
      <Wrapper alignItems="spaceBetween" as="aside">
        <div
          ref={(topPin) => {
            this.topPin = topPin;
          }}
        />
        <StickyContainer
          isStickyBottom={isStickyBottom}
          isStickyTop={isStickyTop}
          ref={(stickyButtonsContainerRef) => {
            this.stickyButtonsContainerRef = stickyButtonsContainerRef;
          }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading -- allow spread here */}
          <ShareButtons {...this.props} />
        </StickyContainer>
        <div
          ref={(bottomPin) => {
            this.bottomPin = bottomPin;
          }}
        />
      </Wrapper>
    );
  }
}

// Module Exports
export { StickyShareButtons };
