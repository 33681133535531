// External Imports
import { Box, font, Inline, pxToRem, Stack, styled, Text } from '@lessonnine/design-system.lib';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import Helmet from 'react-helmet';
import React from 'react';

// Internal Imports
import { AuthorHeader } from './Header/AuthorHeader.jsx';
import { breakpoints } from '../../../config/css/breakpoints.mjs';
import { CONTENT_ARCHIVE } from '../../../config/types/prop.mjs';
import { ContentList } from '../../ContentList/ContentList.jsx';
import { createSelectTranslation } from '../../../selectors/createSelectTranslation.mjs';
import { I18n } from '../../I18n/I18n.jsx';
import { ListViewContext } from '../../../context/listView.mjs';
import { Meta } from '../../Meta/Meta.jsx';
import { options } from '../../../config/reactRedux.mjs';
import { ShowMoreButton } from '../../ShowMoreButton/ShowMoreButton.jsx';
import { useAccessibilityFocusLastAfterLoading } from '../../../hooks/useAccessibilityFocusLastAfterLoading.mjs';
import { Divider } from '../../Divider/Divider.jsx';

// Local Variables
const ArticlesHeader = styled(Text)`
  ${font({ fontSize: 24, lineHeight: 29 / 24, type: 'expressive' })};
`;

const NoArticlesText = styled(Text)`
  font-size: ${pxToRem(16)};
  line-height: 1.4;

  @media ${breakpoints.mobile.max} {
    font-size: ${pxToRem(14)};
    line-height: ${20 / 14};
  }
`;

// Component Definition
function ContentArchiveAuthorBase({
  pagination: { hasMore },
  items,
  loadingMore,
  onLoadMore,
  currentEdition,
  getTranslation,
}) {
  const onFocus = useAccessibilityFocusLastAfterLoading({ loading: loadingMore });
  const showLoadMore = hasMore || loadingMore;
  const hasItems = items.length > 0;
  const author = get('0.metadata.coauthors.0', items);
  const {
    absolutePath: authorAbsolutePath,
    displayName,
    firstName,
    relativePath: authorRelativePath,
  } = author || {};

  const magazineName = getTranslation('meta-title', 'Babbel Magazine');
  const titleText = `${displayName} - ${magazineName}`;

  return (
    <>
      <Meta title={titleText} />
      <Helmet>
        <link rel="author" href={authorRelativePath} />
        <link rel="canonical" href={authorAbsolutePath} />
      </Helmet>
      <Stack as="section">
        {author && (
          <>
            {/* eslint-disable-next-line react/jsx-props-no-spreading -- TODO: remove this potentially-dangerous spread */}
            <AuthorHeader {...author} currentEdition={currentEdition} />
            <Divider shouldShowOnMobile />
          </>
        )}
        <ArticlesHeader>
          <I18n field="articles-by" defaultText="Articles by" />
          &nbsp;{firstName}
        </ArticlesHeader>
        {/* TODO [redesign]: invisible divider here is meant to take the space, after rebranding should be replaced by gap */}
        <Divider isInvisible />
        {hasItems ? (
          <ListViewContext.Provider value="threeUp">
            <ContentList
              items={items}
              interstitials={[
                {
                  content: <Divider />,
                  count: Number.POSITIVE_INFINITY,
                  frequency: 3,
                },
              ]}
              onFocus={onFocus}
            />
          </ListViewContext.Provider>
        ) : (
          <Inline align="center" padding={[50, 0]}>
            <Box>
              <NoArticlesText>No articles</NoArticlesText>
            </Box>
          </Inline>
        )}
        <Divider />
        {showLoadMore && (
          <ShowMoreButton onClick={onLoadMore} disabled={loadingMore} loading={loadingMore} />
        )}
      </Stack>
    </>
  );
}

ContentArchiveAuthorBase.propTypes = CONTENT_ARCHIVE;

const mapStateToProps = (state) => ({
  getTranslation: createSelectTranslation(state),
});

const withRedux = connect(mapStateToProps, undefined, undefined, options);
const ContentArchiveAuthor = withRedux(ContentArchiveAuthorBase);

// Module Exports
export { ContentArchiveAuthor };
