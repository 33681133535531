// Local Variables
const LANGUAGE_EDITION = 'LANGUAGE_EDITION';
const HOME = 'HOME';
const CONTENT_ITEM_SINGLE = 'CONTENT_ITEM_SINGLE';
const TERM_ARCHIVE = 'TERM_ARCHIVE';
const CATEGORY_ARCHIVE = 'CATEGORY_ARCHIVE';
const AUTHOR_ARCHIVE = 'AUTHOR_ARCHIVE';
const SEARCH_ARCHIVE = 'SEARCH_ARCHIVE';
const CATCH_ALL = 'CATCH_ALL';

// TODO: I think we don't have anymore overlapping path, so we could delete this in the future
// Defines the order that paths should be matched against, with more specific
// paths taking precedence over less specific paths
const pathTypeOrder = [
  SEARCH_ARCHIVE,
  AUTHOR_ARCHIVE,
  CATEGORY_ARCHIVE,
  TERM_ARCHIVE,
  CONTENT_ITEM_SINGLE,
  HOME,
  CATCH_ALL,
];

// Defines the order that global paths (including language edition path)
// should be matched against. This excludes page, as page is our catch-all fallback pattern.
const editionPathTypeOrder = [
  SEARCH_ARCHIVE,
  AUTHOR_ARCHIVE,
  TERM_ARCHIVE,
  CATEGORY_ARCHIVE,
  CONTENT_ITEM_SINGLE,
  HOME,
  CATCH_ALL,
];

// Defines the pathTypes that are content archives.
const archivePaths = [CATEGORY_ARCHIVE, TERM_ARCHIVE, AUTHOR_ARCHIVE, SEARCH_ARCHIVE];

const pathTypes = {
  AUTHOR_ARCHIVE,
  CATCH_ALL,
  CATEGORY_ARCHIVE,
  CONTENT_ITEM_SINGLE,
  HOME,
  LANGUAGE_EDITION,
  SEARCH_ARCHIVE,
  TERM_ARCHIVE,
};

// Module Exports
export {
  archivePaths,
  AUTHOR_ARCHIVE,
  CATEGORY_ARCHIVE,
  CONTENT_ITEM_SINGLE,
  editionPathTypeOrder,
  HOME,
  LANGUAGE_EDITION,
  pathTypeOrder,
  pathTypes,
  SEARCH_ARCHIVE,
  TERM_ARCHIVE,
  CATCH_ALL,
};
