// External Imports
import React, { useEffect } from 'react';
import { createGlobalStyle, useTheme } from '@lessonnine/design-system.lib';

// Local Variables
const SoundciteStyles = createGlobalStyle`
  .soundcite-play,
  .soundcite-pause {
    border: solid 1px ${({ theme }) => theme.color.interactive.secondary.border};
    color: ${({ theme }) => theme.color.interactive.secondary.foreground};

    &:hover {
      background-color: ${({ theme }) => theme.color.interactive.secondary.hover.background};
    }

    &&::before {
      border-left-color: ${({ theme }) => theme.color.interactive.secondary.foreground};
    }
  }
`;

// Component Definition
function SoundciteEmbedConfiguration() {
  const theme = useTheme();
  // This is a bit of a weird trick we are using here, unfortunately soundcite uses one background-color of which they lower the opacity of strongly, and then use with a gradient for progress, so we need something that stands strongly against the container's background for the progress to be visible
  const backgroundColor = theme.color.interactive.inverse.background;

  useEffect(() => {
    /* define global SOUNDCITE_CONFIG */
    globalThis.SOUNDCITE_CONFIG = { background_color: backgroundColor };
  }, [backgroundColor]);
  return <SoundciteStyles />;
}

// Module Exports
export { SoundciteEmbedConfiguration };
