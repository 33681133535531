// External Imports
import { styled } from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

// Internal Imports
import { UnstyledLink } from '../Link/UnstyledLink.jsx';

// Component Definition
const DisclosureMenuLink = styled(UnstyledLink)`
  align-items: center;
  color: ${({ theme }) => theme.color.accentInverse.onEnabled};
  display: flex;
  font-family: ${tokens.fonts.default.name};
  padding: ${tokens.size.x2} ${tokens.size.x4};
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.accentInverse.hover};
    color: ${({ theme }) => theme.color.accentInverse.onEnabled};
  }
`;

// Module Exports
export { DisclosureMenuLink };
