// External Imports
import get from 'lodash/fp/get.js';
import { createSelector } from 'reselect';

// Internal Imports
import { selectRouteKey } from './selectRouteKey.mjs';

// Local Functions
const selectContentArchiveBase = createSelector(
  [selectRouteKey, get('archive')],
  (routeKey, archiveState) => archiveState[routeKey],
);

// Module Exports
export { selectContentArchiveBase };
