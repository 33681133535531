// External Imports
import { css } from '@lessonnine/design-system.lib';

// Internal Imports
import { breakpoints } from '../config/css/breakpoints.mjs';

// Component Definition
const conditionalDisplay = css`
  ${({ hideAbove }) =>
    hideAbove
      ? `
    @media ${breakpoints[hideAbove].min} {
      display: none;
    }
  `
      : ''}
  ${({ hideUpTo }) =>
    hideUpTo
      ? `
    @media ${breakpoints[hideUpTo].max} {
      display: none;
    }
  `
      : ''}
`;

// Module Exports
export { conditionalDisplay };
