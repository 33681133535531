// External Imports
import {
  Header as DesignSystemHeader,
  Layout,
  pxToRem,
  Stack,
  styled,
  Text,
} from '@lessonnine/design-system.lib';
import PropTypes from 'prop-types';
import React from 'react';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

// Internal Imports
import { articleContentMaxWidth } from '../../config/css/ui.mjs';
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { DsLink } from '../Link/DsLink.jsx';
import { formatDate } from '../../utils/dateUtils.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { LANGUAGES } from '../../config/types/prop.mjs';
import { setInnerHtml } from '../../utils/setInnerHtml.jsx';

// Local Variables
const AuthorName = styled(Text)`
  line-height: 1.5;
`;

const AuthorNameLink = styled(DsLink)`
  text-align: inherit;
`;

const Byline = styled(Layout)`
  font-family: ${tokens.fonts.default.name}, sans-serif;
  font-size: ${pxToRem(12)};
  margin-bottom: ${pxToRem(24)};

  @media ${breakpoints.tabletPortrait.max} {
    margin-bottom: ${pxToRem(12)};
  }
`;

const Excerpt = styled(Text)`
  font-size: ${pxToRem(18)};
  line-height: 1.5;

  @media ${breakpoints.mobile.max} {
    font-size: ${pxToRem(14)};
    line-height: 1.7143;
  }
`;

const H1WithLineHeight = styled(DesignSystemHeader.h1)`
  line-height: 1.1667;

  @media ${breakpoints.mobile.max} {
    font-size: ${pxToRem(28)};
    line-height: 1.1429;
  }
`;

const Header = styled.header`
  margin: 0 auto;
  max-width: ${pxToRem(articleContentMaxWidth)};
  text-align: center;
`;

const Separator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.surface.layer.separator};
  display: none;
  height: ${pxToRem(22)};
  margin: 0 ${pxToRem(8)};

  @media (min-width: ${tokens.viewports.breakpoints.xxsmall}) {
    display: inline-block;
  }
`;

// Component Definition
function ArticleHeader({
  articleDate,
  authorLink,
  authorName,
  currentEdition,
  excerpt,
  isInProductMagazine,
  title,
}) {
  return (
    <Header>
      <Stack gap={16}>
        <H1WithLineHeight dangerouslySetInnerHTML={setInnerHtml(title)} />
        <Stack padding={[24, 0]}>
          <Excerpt dangerouslySetInnerHTML={setInnerHtml(excerpt)} />
        </Stack>
      </Stack>
      {authorName && (
        <Byline
          align="center"
          alignItems="center"
          // eslint-disable-next-line sort-keys -- ordered by size
          direction={{ xxxsmall: 'stack', xxsmall: 'inline' }}
          // eslint-disable-next-line sort-keys -- ordered by size
          gap={{ xxxsmall: 8, xxsmall: 0 }}
        >
          {/* eslint-disable-next-line sort-keys -- ordered by size */}
          <AuthorName fontSize={{ xxxsmall: 'small', xxsmall: 'base' }}>
            <I18n field="by" defaultText="by" />
            &nbsp;
            {isInProductMagazine ? (
              authorName
            ) : (
              <AuthorNameLink inherited to={authorLink} underlined={false}>
                {authorName}
              </AuthorNameLink>
            )}
          </AuthorName>
          <Separator />
          {/* eslint-disable-next-line sort-keys -- ordered by size */}
          <Text fontSize={{ xxxsmall: 'small', xxsmall: 'base' }}>
            {formatDate(articleDate, currentEdition)}
          </Text>
        </Byline>
      )}
    </Header>
  );
}

ArticleHeader.propTypes = {
  articleDate: PropTypes.string.isRequired,
  authorLink: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  currentEdition: LANGUAGES,
  excerpt: PropTypes.string.isRequired,
  isInProductMagazine: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

ArticleHeader.defaultProps = {
  currentEdition: 'en',
};

// Module Exports
export { ArticleHeader };
