// External Imports
import { connect } from 'react-redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Internal Imports
import { selectPageTrackingData } from '../../selectors/selectPageTrackingData.mjs';
import { options } from '../../config/reactRedux.mjs';
import { trackPageEvent } from '../../utils/tracking.mjs';

function PageShownTracking({ pageTrackingData }) {
  const { isLoading, data } = pageTrackingData;
  useEffect(() => {
    if (!isLoading) {
      trackPageEvent({ name: 'magazine_page:shown', ...data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- the data object is built in selector getPageTrackingData(), so instead rely on string page_path which should remain consistent across renders
  }, [data.page_path, isLoading]);

  // eslint-disable-next-line unicorn/no-null -- React expects empty renders to use null
  return null;
}

PageShownTracking.propTypes = {
  pageTrackingData: PropTypes.shape({
    data: PropTypes.shape({
      page_path: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  pageTrackingData: selectPageTrackingData(state),
});

const withRedux = connect(mapStateToProps, undefined, undefined, options);
const PageShownTrackingHoc = withRedux(PageShownTracking);

// Module Exports
export { PageShownTrackingHoc as PageShownTracking };
