// Local Functions
const isNode = () =>
  typeof process !== 'undefined' && process.release && process.release.name === 'node';

const isProductionMode = () => process.env.NODE_ENV === 'production';

const isRetinaDisplay = () => window.devicePixelRatio >= 2;

const isWindows = () => window.navigator.platform === 'Win32';

const isIosDevice = () => /i(?:Pad|Phone)/.test(window.navigator.platform);

// Module Exports
export { isIosDevice, isNode, isProductionMode, isRetinaDisplay, isWindows };
