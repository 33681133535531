// External Imports
import { matchPath } from 'react-router';
import { createSelector } from 'reselect';

// Internal Imports
import { pathTypeOrder } from '../config/types/path.mjs';
import { selectPathMap } from './selectPathMap.mjs';

// Local Functions
const findFirst = (value) => !!value;

/**
 * Find first path that matches pathname. A match is always expected to be
 * returned because of the open ended page path type will become the fallback.
 *
 * @param {Object} pathMap     Object with identifiers as keys and path regex as values.
 * @param {String} pathname    location pathname
 * @returns {Object}           react-router Match object
 */
const getFirstMatch = (pathMatchOrder, pathMap, pathname) =>
  pathMatchOrder
    .map((pathType) => {
      const match = matchPath(pathname, {
        exact: true,
        path: pathMap[pathType],
      });

      if (!match) {
        return;
      }

      match.pathType = pathType;

      // eslint-disable-next-line consistent-return -- optional return
      return match;
    })
    .find((element) => findFirst(element));

const getRouter = (state) => state.router;

const selectFirstMatch = createSelector([getRouter, selectPathMap], (router, pathMap) =>
  getFirstMatch(pathTypeOrder, pathMap, router.location.pathname),
);

// Module Exports
export { getFirstMatch, selectFirstMatch };
