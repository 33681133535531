// External Imports
import { Component } from 'react';
import PropTypes from 'prop-types';

// Internal Imports
import { logger } from '../../utils/rollbar.mjs';

// Local Variables
const prismJsLanguages = {
  bash: () => import('prismjs/components/prism-bash'),
  c: () => import('prismjs/components/prism-c'),
  clike: () => import('prismjs/components/prism-clike'),
  css: () => import('prismjs/components/prism-css'),
  elixir: () => import('prismjs/components/prism-elixir'),
  go: () => import('prismjs/components/prism-go'),
  groovy: () => import('prismjs/components/prism-groovy'),
  hcl: () => import('prismjs/components/prism-hcl'),
  html: () => import('prismjs/components/prism-markup'),
  java: () => import('prismjs/components/prism-java'),
  javascript: () => import('prismjs/components/prism-javascript'),
  js: () => import('prismjs/components/prism-javascript'),
  json: () => import('prismjs/components/prism-json'),
  jsx: () => import('prismjs/components/prism-jsx'),
  kotlin: () => import('prismjs/components/prism-kotlin'),
  markup: () => import('prismjs/components/prism-markup'),
  none: () => {},
  objectivec: () => import('prismjs/components/prism-objectivec'),
  plain: () => {},
  python: () => import('prismjs/components/prism-python'),
  r: () => import('prismjs/components/prism-r'),
  ruby: () => import('prismjs/components/prism-ruby'),
  rust: () => import('prismjs/components/prism-rust'),
  shell: () => import('prismjs/components/prism-bash'),
  'shell-session': () => import('prismjs/components/prism-shell-session'),
  sql: () => import('prismjs/components/prism-sql'),
  swift: () => import('prismjs/components/prism-swift'),
  typescript: () => import('prismjs/components/prism-typescript'),
  xml: () => import('prismjs/components/prism-markup'),
  yaml: () => import('prismjs/components/prism-yaml'),
  yml: () => import('prismjs/components/prism-yaml'),
};

// Local Functions
async function loadDependencies(language) {
  switch (language) {
    case 'css':
      await prismJsLanguages.markup();
      break;
    case 'jsx':
      await prismJsLanguages.clike();
      await prismJsLanguages.javascript();
      await prismJsLanguages.markup();
      break;
    case 'objectivec':
      await prismJsLanguages.clike();
      await prismJsLanguages.c();
      break;
    case 'shell-session':
      await prismJsLanguages.bash();
      break;
    case 'typescript':
      await prismJsLanguages.clike();
      await prismJsLanguages.javascript();
      break;
    case 'go':
    case 'groovy':
    case 'java':
    case 'javascript':
    case 'js':
    case 'kotlin':
    case 'ruby':
    case 'swift':
      await prismJsLanguages.clike();
      break;
    default:
  }
}

async function loadLanguageToHighlight(language) {
  try {
    await loadDependencies(language);
    await prismJsLanguages[language]();
  } catch (error) {
    logger.error(`Prism.js: Error loading unsupported language ${language}.`, error);
  }
}

async function loadPrism(languagesToHighlight) {
  // Import base/lightweight configuration of the Prism.js library
  // that doesn't include any languages or plugins
  // TODO: PNAPL-199 this should be loading dark or light mode based on theme context
  const PrismCSSPromise = import('prismjs/themes/prism.css');
  const Prism = await import('prismjs/components/prism-core.js');

  // Can't use dynamic import with template literals in this Webpack version
  // e.g. await import(`prismjs/components/prism-${language}`);
  await Promise.all([
    ...languagesToHighlight.map((language) => loadLanguageToHighlight(language)),
    PrismCSSPromise,
  ]);

  Prism.highlightAll();
}

// Component Definition
class CodeBlockSyntaxHighlighter extends Component {
  componentDidMount() {
    // Parsing content of the article coming from WP to collect languages
    // that needs to be supported and loaded for code block syntax highlighting
    const parser = new DOMParser();
    const { articleContent } = this.props;
    const pageContentHtml = parser.parseFromString(articleContent, 'text/html');
    const codeBlockElements = pageContentHtml.querySelectorAll('.wp-block-code');

    const languagesToHighlight = [];

    for (const codeBlockElement of codeBlockElements) {
      const codeBlockElementClasses = codeBlockElement.className;
      if (codeBlockElementClasses.includes('language')) {
        const language = codeBlockElementClasses.split('language-').pop();

        // Pushing only unique language to the Array
        if (!languagesToHighlight.includes(language)) {
          languagesToHighlight.push(language);
        }
      }
    }

    loadPrism(languagesToHighlight);
  }

  // eslint-disable-next-line class-methods-use-this -- renderless component
  render() {
    return null; // eslint-disable-line unicorn/no-null -- React expects null for empty renders
  }
}

// Component Metadata
CodeBlockSyntaxHighlighter.propTypes = {
  articleContent: PropTypes.string.isRequired,
};

// Module Exports
export { CodeBlockSyntaxHighlighter };
