// External Imports
import { createSelector } from 'reselect';

// Local Functions
const selectTitle = createSelector(
  ({ info }) => info,
  (info) => info?.title?.value,
);

// Module Exports
export { selectTitle };
