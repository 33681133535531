// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { selectSearchParameters } from './selectSearch.mjs';
import { selectMatch } from './selectMatch.mjs';
import { selectHasFetchedActiveContent } from './selectHasFetchedActiveContent.mjs';
import { CATCH_ALL } from '../config/types/path.mjs';
import { selectLoadingContent, selectLoadingMore } from './selectLoading.mjs';

// Local Functions
const selectIsRoute404 = createSelector(
  [selectMatch],
  (match) => !match || match.pathType === CATCH_ALL,
);

const selectIsContent404 = createSelector(
  [selectHasFetchedActiveContent, selectLoadingContent, selectLoadingMore, selectSearchParameters],
  (hasFetched, isLoading, isLoadingMore, { s }) =>
    !(isLoading || isLoadingMore) && !hasFetched && !s,
);

const selectIs404 = createSelector(
  [selectIsContent404, selectIsRoute404],
  (content404, route404) => content404 || route404,
);

// Module Exports
export { selectIs404, selectIsRoute404 };
