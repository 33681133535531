// External Imports
import queryString from 'query-string';

// Local Functions
/**
 * URL transform factory for Photon
 *
 * All image URL transform functions should follow this model:
 * - Factory function receives the original image src
 *    and a configuration for the particular position in which this image will appear (sources, sizes, global transforms)
 * - Factory returns a function that takes a series of transformation parameters (width, height, type of transform, etc.)
 * - Returned function will generate a new URL based on those parameters
 *
 * @see 'src/config/images/index.js'
 * @param {object} positionConfig - configuration options for an image position (called an image size in WordPress)
 */
function createTransformSourcePhoton(positionConfig) {
  /**
   * URL transform function for Photon
   *
   * @param {string} originalSource - original source URL for an image
   * @param {Object} parameters - transform parameters to be used for modifying original image URL
   * @param {number} parameters.width - target width value for image transformation
   * @param {number} parameters.height - target height value for image transformation
   * @param {string} parameters.transform - transformation type for image ('fit', 'resize')
   * @param {number} parameters.quality - target image compression quality
   */
  return (originalSource, parameters) => {
    // Return original image if width or height aren't set
    if (!parameters.width) {
      return originalSource;
    }

    const cleanSource = originalSource.split('?')[0];
    const newParameters = {
      height: parameters.height || 9_999,
      quality: parameters.quality || positionConfig.quality,
      transform: parameters.transform || positionConfig.transform || 'fit', // set height to an arbitrarily high value (auto height) if none specified
      ...parameters,
    };
    const { width, height, transform, quality } = newParameters;
    let transformParameters = {};

    // Set params for provided transform
    switch (transform) {
      case 'crop':
        transformParameters = { crop: `0,0,${width}px,${height}px` };
        break;

      case 'fit':
      case 'resize':
        transformParameters = { [transform]: `${width},${height}` };
        break;

      default:
        transformParameters = {
          h: height,
          w: width,
        };
        break;
    }

    const photonParameters = queryString.stringify({
      quality,
      ...transformParameters,
    });

    return `${cleanSource}?${photonParameters}`;
  };
}

// Module Exports
export { createTransformSourcePhoton };
