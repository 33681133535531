// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { isItemPreview } from '../utils/isItemPreview.mjs';
import { selectActivePagination } from './selectActivePagination.mjs';
import { selectContentType, selectMatch } from './selectMatch.mjs';
import { selectContentArchiveBase } from './selectContentArchiveBase.mjs';
import { createSelectContentItemBySlug } from './createSelectContentItem.mjs';
import { selectRelatedContentItemsByItem } from './selectRelatedItems.mjs';

// Local Functions
const selectActiveContentItemBySlug = createSelector([selectContentType], (contentType) =>
  createSelectContentItemBySlug(contentType),
);

const selectActiveContentItem = createSelector(
  [
    selectMatch,
    selectActiveContentItemBySlug,
    // This sucks for memoization, but getItem for now is made in a way that doesn't get better than that
    (state) => state,
  ],
  ({ isArchive }, getItem, state) => (isArchive ? undefined : getItem(state)),
);

const selectActiveContentItemRelatedItems = createSelector(
  [selectActiveContentItem, (state) => state],
  (contentItem, state) => selectRelatedContentItemsByItem(state, contentItem),
);

const selectHasFetchedActiveArchiveContent = createSelector(
  [selectMatch, selectActivePagination, selectContentArchiveBase],
  ({ isArchive }, pagination, { pages = {} } = {}) =>
    isArchive ? !!pages[pagination.currentPage] : false,
);

const selectHasFetchedActiveContentItem = createSelector(
  [selectActiveContentItem, selectActiveContentItemRelatedItems],
  // Preview items don't have related items
  (contentItem, relatedItems) =>
    !!contentItem && (relatedItems.relatedPosts.length > 0 || isItemPreview(contentItem)),
);

const selectHasFetchedActiveContent = createSelector(
  [selectHasFetchedActiveArchiveContent, selectHasFetchedActiveContentItem],
  (hasFetchedArchive, hasFetchedItem) => hasFetchedArchive || hasFetchedItem,
);

// Module Exports
export { selectHasFetchedActiveContent };
