import { useCallback, useEffect, useRef } from 'react';

function useAccessibilityFocusLastAfterLoading({ loading }) {
  const previousLoading = useRef();
  const lastFocusedElementRef = useRef();

  const onItemFocus = useCallback((error) => {
    lastFocusedElementRef.current = error.target;
  }, []);

  useEffect(() => {
    const lastFocusedElement = lastFocusedElementRef.current;
    if (loading === false && previousLoading.current === true && lastFocusedElement) {
      lastFocusedElement.focus();
    }
    previousLoading.current = loading;
  }, [loading]);

  return onItemFocus;
}

export { useAccessibilityFocusLastAfterLoading };
