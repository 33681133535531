// Internal Imports
import clientSideEnvironmentVariablesConfig from '../config/clientSideEnvironmentVariables.json';
import { isNode, isProductionMode } from './environment.mjs';

// Local Variables
const { allowedKeys, injectionKey } = clientSideEnvironmentVariablesConfig;

// Local Functions
const getRuntimeConfiguration = (variableName) => {
  if (isNode()) {
    return process.env[variableName];
  }
  if (!allowedKeys.includes(variableName)) {
    if (!isProductionMode()) {
      throw new Error(`Variable ${variableName} is not accessible on the client.`);
    }
    // eslint-disable-next-line consistent-return -- optional return
    return;
  }
  return window[injectionKey]?.[variableName];
};

// Module Exports
export { getRuntimeConfiguration };
