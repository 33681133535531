// External Imports
import template from 'lodash/template.js';
import omit from 'lodash/fp/omit.js';
import { createSelector } from 'reselect';

// Internal Imports
import { globalParentPath, pathRegex } from '../config/pathRegex.mjs';
import { CONTENT_ITEM_SINGLE, CATEGORY_ARCHIVE, TERM_ARCHIVE } from '../config/types/path.mjs';

// Local Functions
const createGetSlugs = (stateKey) => (state) =>
  Object.keys(state[stateKey]).map((id) => state[stateKey][id].slug);

const selectLocalPathMap = createSelector([createGetSlugs('contentType')], (contentTypes) => {
  const compileItemPath = template(pathRegex[CONTENT_ITEM_SINGLE]);
  const compileCategoryPath = template(pathRegex[CATEGORY_ARCHIVE]);
  const compileTermPath = template(pathRegex[TERM_ARCHIVE]);
  const contentTypeList = contentTypes.join('|');
  const compiledMap = {
    ...pathRegex,
    [CATEGORY_ARCHIVE]: compileCategoryPath({
      contentTypes: contentTypeList,
    }),
    [CONTENT_ITEM_SINGLE]: compileItemPath({
      contentTypes: contentTypeList,
    }),
    [TERM_ARCHIVE]: compileTermPath({
      contentTypes: contentTypeList,
    }),
  };

  return compiledMap;
});

/**
 * @return {object} A map of path types to path regex
 */
const selectPathMap = createSelector([selectLocalPathMap], (localPathMap) => {
  if (globalParentPath) {
    // Don't compile parent route
    const pathsWithParent = omit([globalParentPath], localPathMap);
    const compiledMapWithParent = Object.fromEntries(
      Object.keys(pathsWithParent).map((pathName) => [
        pathName,
        `${localPathMap[globalParentPath]}${localPathMap[pathName]}`,
      ]),
    );

    return {
      ...localPathMap,
      ...compiledMapWithParent,
    };
  }

  return localPathMap;
});

// Module Exports
export { selectPathMap };
