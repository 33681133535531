// Local Variables
const SHORTLINK_CODE_LANDING_PAGE_DEU = 'deumag-index-org';
const SHORTLINK_CODE_LANDING_PAGE_ENG = 'engmag-index-org';
const SHORTLINK_CODE_LANDING_PAGE_FRA = 'framag-index-org';
const SHORTLINK_CODE_LANDING_PAGE_ITA = 'itamag-index-org';
const SHORTLINK_CODE_LANDING_PAGE_POL = 'polmag-index-org';
const SHORTLINK_CODE_LANDING_PAGE_POR = 'pormag-index-org';
const SHORTLINK_CODE_LANDING_PAGE_SPA = 'spamag-index-org';
const SHORTLINK_CODE_LANDING_PAGE_SWE = 'swemag-index-org';
const SHORTLINK_CODE_LANDING_PAGE_USA = 'usamag-index-org';

// Module Exports
export {
  SHORTLINK_CODE_LANDING_PAGE_DEU,
  SHORTLINK_CODE_LANDING_PAGE_ENG,
  SHORTLINK_CODE_LANDING_PAGE_FRA,
  SHORTLINK_CODE_LANDING_PAGE_ITA,
  SHORTLINK_CODE_LANDING_PAGE_POL,
  SHORTLINK_CODE_LANDING_PAGE_POR,
  SHORTLINK_CODE_LANDING_PAGE_SPA,
  SHORTLINK_CODE_LANDING_PAGE_SWE,
  SHORTLINK_CODE_LANDING_PAGE_USA,
};
