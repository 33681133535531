// External Imports
import { Link as DSStyledLink } from '@lessonnine/design-system.lib';

// Internal Imports
import { withLinkLogic } from './withLinkLogic.jsx';

// Component Definition
// TODO [design-system migration]: DS-migration after migration is complete, this component should be the standard link renamed to just `Link`
const DsLink = withLinkLogic(DSStyledLink);

// Module Exports
export { DsLink };
