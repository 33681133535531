// External Imports
import { connectRouter } from 'connected-react-router';
import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';

// Internal Imports
import { abTesterReducer } from './abTesterReducer.mjs';
import { activeReducer } from './activeReducer.mjs';
import { archiveReducer } from './archiveReducer.mjs';
import { combineEntityReducers } from './combineEntityReducers.mjs';
import { defaultState } from '../config/defaultState.mjs';
import { RECEIVE_PRIMARY_CONTENT, RECEIVE_SITE_OPTIONS } from '../config/types/action.mjs';
import { createContentGroupReducer } from './createContentGroupReducer.mjs';
import { createOptionReducer } from './createOptionReducer.mjs';
import { featureFlagReducer } from './featureFlagReducer.mjs';
import { landingPageReducer } from './landingPageReducer.mjs';
import { menuReducer } from './menuReducer.mjs';
import { userReducer } from './userReducer.mjs';
import { siteOptionsErrorReducer } from './siteOptionsErrorReducer.mjs';

// Local Functions
function createRootReducer(history) {
  const sliceReducers = combineReducers({
    abTester: abTesterReducer,
    active: (state = defaultState.active) => state,
    archive: (state = defaultState.archive) => state,
    contentItem: createContentGroupReducer('contentType'),
    featureFlags: featureFlagReducer,
    landingPage: (state = defaultState.landingPage) => state,
    languagePicker: createOptionReducer('languagePicker'),
    languages: createOptionReducer('languages'),
    menu: menuReducer,
    router: connectRouter(history),
    siteOptionsError: siteOptionsErrorReducer,
    socialMedia: createOptionReducer('socialMedia'),
    term: createContentGroupReducer('taxonomy'),
    translations: createOptionReducer('translations'),
    user: userReducer,
    ...combineEntityReducers({
      author: RECEIVE_PRIMARY_CONTENT,
      contentType: RECEIVE_SITE_OPTIONS,
      footer: RECEIVE_SITE_OPTIONS,
      info: RECEIVE_SITE_OPTIONS,
      redirect: RECEIVE_SITE_OPTIONS,
      taxonomy: RECEIVE_SITE_OPTIONS,
    }),
  });
  return reduceReducers(sliceReducers, archiveReducer, landingPageReducer, activeReducer);
}

// Module Exports
export { createRootReducer };
