// Local Variables
const FOOTER = 'footer';
const FOOTER_COLUMN_1 = 'footer1';
const FOOTER_COLUMN_2 = 'footer2';
const FOOTER_COLUMN_3 = 'footer3';
const FOOTER_COLUMN_4 = 'footer4';
const FOOTER_COPYRIGHT = 'footerCopyright';

// Module Exports
export {
  FOOTER,
  FOOTER_COLUMN_1,
  FOOTER_COLUMN_2,
  FOOTER_COLUMN_3,
  FOOTER_COLUMN_4,
  FOOTER_COPYRIGHT,
};
