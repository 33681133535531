// External Imports
import PropTypes from 'prop-types';
import React from 'react';
import { Inline, Stack, Text, IconCaretLeft, IconCaretRight } from '@lessonnine/design-system.lib';

// Internal Imports
import { I18n } from '../I18n/I18n.jsx';
import { DsLink } from '../Link/DsLink.jsx';
import { ConditionalDisplay } from '../ConditionalDisplay/ConditionalDisplay.jsx';

// Local Variables
const propTypes = {
  currentPage: PropTypes.number.isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalPages: PropTypes.number.isRequired,
};

function Links({ currentPage, links, maxDistance }) {
  return (
    <Inline as="ul" align="center" alignItems="trailing" gap={16}>
      {links.map((link, index) => {
        const pageNumber = index + 1;
        const distance = Math.abs(pageNumber - currentPage);

        // Render first and last links
        if (pageNumber === 1 || links.length === pageNumber || maxDistance > distance) {
          return (
            <li key={link}>
              <DsLink
                color={currentPage === pageNumber ? 'highlight' : 'default'}
                size="large"
                to={link}
              >
                {pageNumber}
              </DsLink>
            </li>
          );
        }

        // If the distance is equal to the max distance, render an ellipsis
        if (maxDistance === distance) {
          return (
            <Text as="li" key={pageNumber}>
              ...
            </Text>
          );
        }

        return false;
      })}
    </Inline>
  );
}

Links.propTypes = {
  // eslint-disable-next-line react/require-default-props -- already required in the shared type
  currentPage: propTypes.currentPage,
  // eslint-disable-next-line react/require-default-props -- already required in the shared type
  links: propTypes.links,
  maxDistance: PropTypes.number,
};

Links.defaultProps = {
  maxDistance: 3,
};

const paginationButtonPropTypes = {
  isShown: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
};

function PreviousButton({ isShown, to }) {
  return (
    <div>
      {isShown && (
        <DsLink alignIcon="start" icon={<IconCaretLeft />} to={to}>
          <I18n field="previous" defaultText="Previous" />
        </DsLink>
      )}
    </div>
  );
}

PreviousButton.propTypes = paginationButtonPropTypes;

function NextButton({ isShown, to }) {
  return (
    <div>
      {isShown && (
        <DsLink alignIcon="end" icon={<IconCaretRight />} to={to}>
          <I18n field="next" defaultText="Next" />
        </DsLink>
      )}
    </div>
  );
}

NextButton.propTypes = paginationButtonPropTypes;

function Pagination({ currentPage, totalPages, links }) {
  const previousButton = <PreviousButton isShown={currentPage !== 1} to={links[currentPage - 2]} />;
  const nextButton = <NextButton isShown={currentPage !== totalPages} to={links[currentPage]} />;
  const linksComponent = <Links currentPage={currentPage} links={links} />;
  return (
    <>
      <ConditionalDisplay hideUpTo="tabletPortrait">
        <Inline align="spaceBetween" alignItems="center" padding={[12, 0]}>
          {previousButton}
          {linksComponent}
          {nextButton}
        </Inline>
      </ConditionalDisplay>
      <ConditionalDisplay hideAbove="tabletPortrait">
        <Stack gap={24} padding={[12, 0]}>
          {linksComponent}
          <Inline align="spaceBetween">
            {previousButton}
            {nextButton}
          </Inline>
        </Stack>
      </ConditionalDisplay>
    </>
  );
}

Pagination.propTypes = propTypes;

// Module Exports
export { Pagination };
