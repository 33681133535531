import React from 'react';
import { useTheme } from '@lessonnine/design-system.lib';

function RssIcon() {
  const theme = useTheme();
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 23.5c6.351 0 11.5-5.149 11.5-11.5S18.351.5 12 .5.5 5.649.5 12 5.649 23.5 12 23.5z"
        stroke={theme.color.surface.foreground.primary}
      />
      <path
        d="M9.482 13.032c-.817 0-1.482.664-1.482 1.482 0 .816.665 1.481 1.482 1.481.818 0 1.483-.665 1.483-1.48 0-.82-.665-1.483-1.483-1.483z"
        fill={theme.color.surface.foreground.primary}
      />
      <path
        d="M8.154 9.268A.155.155 0 008 9.423v1.881c0 .085.07.154.154.154a4.388 4.388 0 014.383 4.383c0 .084.068.154.154.154h1.89c.085 0 .155-.07.155-.154l-.008-.048c-.027-3.602-2.967-6.525-6.574-6.525z"
        fill={theme.color.surface.foreground.primary}
      />
      <path
        d="M17.991 15.794c-.026-5.404-4.43-9.79-9.837-9.79A.155.155 0 008 6.16v1.936c0 .085.07.154.154.154 4.186 0 7.592 3.406 7.592 7.592 0 .084.069.154.153.154h1.945c.084 0 .156-.07.156-.154l-.009-.048z"
        fill={theme.color.surface.foreground.primary}
      />
    </svg>
  );
}

export { RssIcon };
