// External imports
import { Button } from '@lessonnine/design-system.lib';
import React from 'react';

// Internal Imports
import { withLinkLogic } from './withLinkLogic.jsx';

// Local Functions
function ButtonAsLink(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading -- this enhances the DS Button, so props spreading is required
  return <Button as="a" {...props} />;
}

// Component Definition
const ButtonLink = withLinkLogic(ButtonAsLink);

// Module Exports
export { ButtonLink };
