// External Imports
import { styled } from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

// Internal Imports
import { breakpoints } from '../../config/css/breakpoints.mjs';

// Component Definition
const Divider = styled.div`
  ${({ isInvisible, theme }) =>
    isInvisible ? '' : `border-bottom: 1px solid ${theme.color.surface.container.separator};`}

  /* TODO [redesign]: replace by gap after rebranding, but for now because of the broken SSR and the fact this is hidden on mobile, it's impossible to do */
  margin-bottom: ${tokens.size.space.medium};
  padding-bottom: ${tokens.size.space.medium};
  width: 100%;

  @media ${breakpoints.mobile.max} {
    /* As invisible dividers are used for spacing until the fix, they should be displayed everywhere */
    ${({ isInvisible, shouldShowOnMobile }) =>
      isInvisible || shouldShowOnMobile ? '' : 'display: none;'}
  }
`;

// Module Exports
export { Divider };
