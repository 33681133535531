// Local Functions
const isNavigationPerformanceEntryForPagePath = (navigationPerformanceEntry, pagePath) =>
  navigationPerformanceEntry.name?.endsWith(pagePath);

const makeGetPerformanceOffset = () => {
  let isEntryUsed = false;
  return (pagePath) => {
    if (isEntryUsed) {
      return 0;
    }
    isEntryUsed = true;
    const performanceEntry = performance.getEntriesByType('navigation')[0];
    if (!performanceEntry) {
      return 0;
    }
    if (!isNavigationPerformanceEntryForPagePath(performanceEntry, pagePath)) {
      return 0;
    }
    return performanceEntry.domComplete;
  };
};

const getPerformanceOffset = makeGetPerformanceOffset();

// Module Exports
export { makeGetPerformanceOffset, getPerformanceOffset };
