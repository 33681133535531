// External Imports
import React from 'react';
import { useSelector } from 'react-redux';

// Internal Imports
import babbelLogo from '../../assets/images/babbel-logo.svg';
import { createSelectTranslation } from '../../selectors/createSelectTranslation.mjs';

// Component Definition
function Logo() {
  const selectTranslation = useSelector(createSelectTranslation);
  return (
    <img alt={selectTranslation('meta-title', 'Babbel Magazine')} src={babbelLogo} height="30" />
  );
}

// Module Exports
export { Logo };
