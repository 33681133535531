// External Imports
import { createSelector } from 'reselect';

// Local Functions
const selectContentTypes = ({ contentType }) => Object.keys(contentType);

const selectTaxonomies = ({ taxonomy }) => Object.keys(taxonomy);

const selectContentGroupTypes = createSelector(
  [selectContentTypes, selectTaxonomies],
  (contentTypes, taxonomies) => [contentTypes, taxonomies],
);

// Module Exports
export { selectContentGroupTypes };
