// Internal Imports
import { QUERY_AB_TESTER } from '../config/types/action.mjs';

// Local Functions
/**
 * State slice reducer for menu related actions.
 * @param {object}   state   menu state slice
 * @param {string}   type
 * @param {*}        payload
 * @returns {object}
 */
function abTesterReducer(state = {}, { type, payload } = {}) {
  switch (type) {
    case QUERY_AB_TESTER:
      return {
        ...state,
        bucket: payload.bucket,
        error: payload.error,
        isInExperimentGroup: payload.isInExperimentGroup,
        isLoading: payload.isLoading,
        wasQueried: payload.wasQueried,
      };
    default:
      return state;
  }
}

// Module Exports
export { abTesterReducer };
