// External Imports
import { pxToRem } from '@lessonnine/design-system.lib';

// Local Variables
const breakpoints = {
  mobile: {
    max: `(max-width: ${pxToRem(512)})`,
    min: `(min-width: ${pxToRem(512)})`,
    rawValue: 512,
  },
  tabletPortrait: {
    max: `(max-width: ${pxToRem(768)})`,
    min: `(min-width: ${pxToRem(768)})`,
    rawValue: 768,
  },
  // eslint-disable-next-line sort-keys -- author from smallest to largest size
  tabletLandscape: {
    max: `(max-width: ${pxToRem(1_024)})`,
    min: `(min-width: ${pxToRem(1_024)})`,
    rawValue: 1_024,
  },
  // eslint-disable-next-line sort-keys -- author from smallest to largest size
  desktop: {
    max: `(max-width: ${pxToRem(1_280)})`,
    min: `(min-width: ${pxToRem(1_280)})`,
    rawValue: 1_280,
  },
};

// Module Exports
export { breakpoints };
