// External Imports
import { Button, Inline } from '@lessonnine/design-system.lib';
import PropTypes from 'prop-types';
import React from 'react';

// Internal Imports
import { I18n } from '../I18n/I18n.jsx';

// Component Definition
function ShowMoreButton({ loading, disabled, onClick }) {
  return (
    <Inline align="center">
      <Button disabled={disabled} loading={loading} onClick={onClick}>
        <I18n field="show-more" defaultText="Show More" />
      </Button>
    </Inline>
  );
}

ShowMoreButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ShowMoreButton.defaultProps = {
  disabled: false,
};

// Module Exports
export { ShowMoreButton };
