// External Imports
import { useContext } from 'react';

// Internal Imports
import { ContentEmbedContext } from '../context/contentEmbed.mjs';

// Local Functions
const useContentEmbed = () => useContext(ContentEmbedContext);

// Module Exports
export { useContentEmbed };
