// External Imports
import { Box, pxToRem, Stack, styled } from '@lessonnine/design-system.lib';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

// Internal Imports
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { CONTENT_ITEM, POSITIONS } from '../../config/types/prop.mjs';
import { DsLink } from '../Link/DsLink.jsx';
import { ListViewContext } from '../../context/listView.mjs';
import { ListViewItem } from '../ContentListViewItem/ListViewItem.jsx';

// Local Variables

// WrapContainer should use Inline, but because of the broken gap polyfill, it breaks the layouting of space-between (the extra divs are considered to be elements by flex, and they are also spaced around, making it look like the UI is not taking 100% of the space), so for now making our own container, after rebranding we can replace by Inline
const WrapContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

const itemsSpacing = pxToRem(22);

const ItemThreeUp = styled(Box)`
  @media ${breakpoints.mobile.max} {
    border-bottom: 1px solid ${({ theme }) => theme.color.surface.container.separator};
    margin-bottom: ${pxToRem(20)};
    padding-bottom: ${pxToRem(16)};
    width: 100%;
  }

  @media ${breakpoints.mobile.min} {
    /* We have to set these manually because lost can't account for the interstitial elements */

    flex: 0 0 auto;
    width: calc(33.334% - ${itemsSpacing});
  }
`;

const ItemTwoUp = styled(Box)`
  @media ${breakpoints.mobile.min} {
    width: calc(50% - ${itemsSpacing});
  }

  @media ${breakpoints.mobile.max} {
    border-bottom: 1px solid ${({ theme }) => theme.color.surface.container.separator};
    margin-bottom: ${pxToRem(20)};
    padding-bottom: ${pxToRem(16)};
    width: 100%;
  }
`;

const Heading = styled.div`
  font-size: ${pxToRem(24)};
  font-weight: 700;
  margin-bottom: ${pxToRem(20)};
  position: relative;
  text-align: center;

  span {
    background-color: ${({ theme }) => theme.color.surface.layer.background};
    display: inline-block;
    padding: ${pxToRem(5)} ${pxToRem(10)};
    position: relative;
  }

  &::before {
    content: '';
    height: ${pxToRem(2)};
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
`;

const Interstitial = styled.div`
  width: 100%;

  &:last-of-type {
    display: none;
  }
`;

const listViewComponents = {
  linear: React.Fragment,
  threeUp: ItemThreeUp,
  twoUp: ItemTwoUp,
};

const listViewContainers = {
  linear: Stack,
  threeUp: WrapContainer,
  twoUp: WrapContainer,
};

// Component Definition
function ContentList({
  items,
  interstitials,
  heading,
  moreLink,
  moreLabel,
  ListItemComponent,
  imagePosition,
  onFocus,
}) {
  const listView = useContext(ListViewContext);
  // Merge provided interstitials into list of content items
  // so we can render them in a single loop
  // eslint-disable-next-line unicorn/no-array-reduce -- TODO: refactor out this reduce()
  const mergedList = items.reduce((accumulator, current, index) => {
    // eslint-disable-next-line unicorn/no-array-reduce -- TODO: refactor out this reduce()
    const validInterstitials = interstitials.reduce(
      (interstitialAccumulator, { frequency, count, content }) => {
        const currentCount = count || 1; // Default to displaying once
        const position = index + 1;

        if (position % frequency === 0 && currentCount >= position / frequency) {
          // eslint-disable-next-line unicorn/prefer-spread -- TODO: fix this apparent bug; concat() should only ever be passed arrays so it's not clear what the intended behavior is
          return interstitialAccumulator.concat({
            content,
            contentListItemType: 'interstitial',
            key: `${position}${currentCount}`,
          });
        }

        return interstitialAccumulator;
      },
      [],
    );

    // eslint-disable-next-line unicorn/prefer-spread -- TODO: fix this apparent bug; concat() should only ever be passed arrays so it's not clear what the intended behavior is
    return accumulator.concat(
      {
        content: current,
        contentListItemType: 'contentItem',
      },
      validInterstitials,
    );
  }, []);

  const Container = listViewContainers[listView];
  const ContentItem = listViewComponents[listView];

  return (
    <div>
      {heading && (
        <Heading>
          <span>{heading}</span>
        </Heading>
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- optional props */}
      <Container align="spaceBetween" {...(listView === 'linear' ? { gap: 32 } : {})}>
        {mergedList.map(({ contentListItemType, content, key }) => {
          if (contentListItemType === 'interstitial') {
            return <Interstitial key={key}>{content}</Interstitial>;
          }

          return (
            <ContentItem key={content.id} onFocus={onFocus}>
              <ListItemComponent item={content} imagePosition={imagePosition} />
            </ContentItem>
          );
        })}
      </Container>
      {moreLink && (
        <div>
          <DsLink to={moreLink}>{moreLabel}</DsLink>
        </div>
      )}
    </div>
  );
}

ContentList.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  imagePosition: POSITIONS,
  interstitials: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      count: PropTypes.number,
      frequency: PropTypes.number.isRequired,
    }),
  ),
  items: PropTypes.arrayOf(PropTypes.shape(CONTENT_ITEM)).isRequired,
  ListItemComponent: PropTypes.elementType,
  moreLabel: PropTypes.string,
  moreLink: PropTypes.string,
  onFocus: PropTypes.func,
};

ContentList.defaultProps = {
  heading: false,
  imagePosition: 'full',
  interstitials: [],
  ListItemComponent: ListViewItem,
  moreLabel: '',
  moreLink: '',
  onFocus: () => {},
};

// Module Exports
export { ContentList };
