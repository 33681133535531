// Internal Imports
import { RECEIVE_SITE_OPTIONS } from '../config/types/action.mjs';
import { defaultState } from '../config/defaultState.mjs';

// Local Functions
/**
 * Create a shallow state slice reducer for a global site option field.
 *
 * @param {string} slice name of slice to update
 * @return {function}
 */
const createOptionReducer =
  (slice) =>
  (state = defaultState[slice], { type, payload } = {}) => {
    if (type === RECEIVE_SITE_OPTIONS) {
      return payload[slice];
    }

    return state;
  };

// Module Exports
export { createOptionReducer };
