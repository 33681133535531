// External Imports
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Component Definition
class ScrollToTop extends Component {
  componentDidUpdate(nextProps) {
    const { location } = this.props;
    if (location.pathname !== nextProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- convert to TypeScript type eventually
};

const ScrollToTopHoc = withRouter(ScrollToTop);

// Module Exports
export { ScrollToTopHoc as ScrollToTop };
