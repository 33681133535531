import { HOME } from './path.mjs';

const HOMEPAGE = 'homepage';

// Map connecting path type to landing page type
const landingPageTypePathTypeMap = {
  [HOME]: HOMEPAGE,
};

// Module Exports
export { HOMEPAGE, landingPageTypePathTypeMap };
