// Local Imports
import { RECEIVE_SITE_OPTIONS_ERROR } from '../config/types/action.mjs';
import { defaultState } from '../config/defaultState.mjs';

// Reducer Definition
// eslint-disable-next-line default-param-last -- Redux API
const siteOptionsErrorReducer = (state = defaultState.siteOptionsError, action) => {
  switch (action.type) {
    case RECEIVE_SITE_OPTIONS_ERROR:
      return { error: action.payload };
    default:
      return state;
  }
};

// Module Exports
export { siteOptionsErrorReducer };
