// External Imports
import get from 'lodash/fp/get.js';
import { createSelector } from 'reselect';

// Internal Imports
import {
  languageCodeTagTaxonomyMap,
  languageCodeCategoryTaxonomyMap,
} from '../config/types/language.mjs';
import { CATEGORY_ARCHIVE } from '../config/types/path.mjs';
import { selectMatch } from './selectMatch.mjs';
import { selectEdition } from './selectEdition.mjs';

// Local Functions
const selectTaxonomy = ({ pathType }, currentEdition) => {
  if (pathType === CATEGORY_ARCHIVE) {
    return languageCodeCategoryTaxonomyMap[currentEdition];
  }
  return languageCodeTagTaxonomyMap[currentEdition];
};

const selectTerm = createSelector(
  [selectMatch, get('term'), selectEdition],
  (match, terms, currentEdition) => {
    const { slug } = match.params;
    const taxonomy = selectTaxonomy(match, currentEdition);
    const id = get(`${taxonomy}.idBySlug.${slug}`, terms);
    const term = get(`${taxonomy}.byId.${id}`, terms);
    return term
      ? {
          ...term,
        }
      : false;
  },
);

// Module Exports
export { selectTaxonomy, selectTerm };
