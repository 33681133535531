// External Imports
import get from 'lodash/fp/get.js';
import { createSelector } from 'reselect';

// Internal Imports
import { selectMatch } from './selectMatch.mjs';

// Local Functions
const selectLocale = createSelector(
  selectMatch,
  (pathMatch) => get('query.locale', pathMatch) || get('params.language', pathMatch),
);

// Module Exports
export { selectLocale };
