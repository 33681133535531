// External Imports
import React from 'react';
import { createGlobalStyle } from '@lessonnine/design-system.lib';
import Helmet from 'react-helmet';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

// Local Variables
const getBackgroundColor = (color) => color.neutral.surfaceDefault;

const GlobalBackgroundColorStyle = createGlobalStyle`
  html, body {
    background-color: ${({ theme }) => getBackgroundColor(theme.color)};
  }
`;

function BackgroundColor() {
  return (
    <>
      <Helmet>
        <meta name="theme-color" content={getBackgroundColor(tokens.color.theme.light)} />
        <meta
          name="theme-color"
          media="(prefers-color-scheme: dark)"
          content={getBackgroundColor(tokens.color.theme.dark)}
        />
      </Helmet>
      <GlobalBackgroundColorStyle />
    </>
  );
}

// Module Exports
export { BackgroundColor };
