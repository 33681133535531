// Internal Imports
import {
  FRENCH,
  GERMAN,
  ENGLISH,
  ENGLISH_USA,
  ITALIAN,
  SPANISH,
  POLISH,
  PORTUGUESE,
  SWEDISH,
} from '../config/types/language.mjs';

// Local Variables
const LearnLanguages = {
  DAN: 'DAN',
  DEU: 'DEU',
  ENG: 'ENG',
  FRA: 'FRA',
  IND: 'IND',
  ITA: 'ITA',
  NLD: 'NLD',
  NOR: 'NOR',
  POL: 'POL',
  POR: 'POR',
  QMS: 'QMS',
  RUS: 'RUS',
  SPA: 'SPA',
  SWE: 'SWE',
  TUR: 'TUR',
};

const { DAN, DEU, ENG, FRA, IND, ITA, NLD, NOR, POL, POR, QMS, RUS, SPA, SWE, TUR } =
  LearnLanguages;

const englishMap = {
  danish: DAN,
  dutch: NLD,
  french: FRA,
  german: DEU,
  indonesian: IND,
  italian: ITA,
  'mexican-spanish': QMS,
  norwegian: NOR,
  polish: POL,
  portuguese: POR,
  russian: RUS,
  spanish: SPA,
  swedish: SWE,
  turkish: TUR,
};

// Commented tags do not exist currently
const tagToLearnLanguageMap = {
  [ENGLISH]: englishMap,
  [ENGLISH_USA]: englishMap,
  [FRENCH]: {
    allemand: DEU,
    anglais: ENG,
    danois: DAN,
    espagnol: SPA,
    indonesien: IND,
    italien: ITA,
    neerlandais: NLD,
    norvegien: NOR,
    // polonais: POL,
    portugais: POR,
    russe: RUS,
    suedois: SWE,
    turc: TUR,
  },
  [GERMAN]: {
    danisch: DAN,
    englisch: ENG,
    franzosisch: FRA,
    indonesisch: IND,
    italienisch: ITA,
    niederlandisch: NLD,
    norwegisch: NOR,
    polnisch: POL,
    portugiesisch: POR,
    russisch: RUS,
    schwedisch: SWE,
    spanisch: SPA,
    turkisch: TUR,
  },
  [ITALIAN]: {
    francese: FRA,
    inglese: ENG,
    // indonesiano: IND,
    olandese: NLD,
    // norvegese: NOR,
    // polacco: POL,
    portoghese: POR,
    russo: RUS,
    spagnolo: SPA,
    svedese: SWE,
    // danese: DAN,
    tedesco: DEU,
    turco: TUR,
  },
  [POLISH]: {
    // dunski: DAN,
    angielski: ENG,
    niemiecki: DEU,
    // francuski: FRA,
    // indonezyjski: IND,
    // italski: ITA,
    // holenderski: NLD,
    norweski: NOR,
    // portugalski: POR,
    // ruski: RUS,
    // hiszpanski: SPA,
    // szwedzki: SWE,
    // turecki: TUR,
  },
  [PORTUGUESE]: {
    // aprender-alemao and alemanha also exists
    alemao: DEU,
    // aprender-espanhol also exists
    espanhol: SPA,
    // aprender-frances also exists
    frances: FRA,
    // holanda also exists
    holandes: NLD,
    // dinamarquês: DAN,
    // aprender-ingles also exists
    ingles: ENG,
    // indonesio: IND,
    // aprender-italiano and italia also exist
    italiano: ITA,
    // noruegues: NOR,
    // polones: POL,
    russo: RUS,
    // suecia also exists
    sueco: SWE,
    turco: TUR,
  },
  [SPANISH]: {
    aleman: DEU,
    danes: DAN,
    frances: FRA,
    ingles: ENG,
    // indonesio: IND,
    italiano: ITA,
    // holandes: NLD,
    noruego: NOR,
    // polaco: POL,
    portugues: POR,
    ruso: RUS,
    sueco: SWE,
    turco: TUR,
  },
  [SWEDISH]: {
    // danska: DAN,
    // engelska: ENG,
    // tyska: DEU,
    franska: FRA,
    // indonesiska: IND,
    // italienska: ITA,
    // hollandska: NLD,
    // norska: NOR,
    // polska: POL,
    // portugisiska: POR,
    // ryska: RUS,
    spanska: SPA,
    turkiska: TUR,
  },
};

// Local Functions
const tagToLearnLanguage = (edition, tagSlug) => tagToLearnLanguageMap[edition][tagSlug];
const learnLanguageToTag = (edition, learnLanguageTarget) =>
  Object.entries(tagToLearnLanguageMap[edition]).find(
    ([, learnLanguage]) => learnLanguageTarget === learnLanguage,
  )[0];

// Module Exports
export { learnLanguageToTag, tagToLearnLanguage };
