// External Imports
import queryString from 'query-string';

// Local Functions
/**
 * URL transform factory for Gravatar
 *
 * All image URL transform functions should follow this model:
 * - Factory function receives the original image src
 *    and a configuration for the particular position in which this image will appear (sources, sizes, global transforms)
 * - Factory returns a function that takes a series of transformation parameters (width, height, type of transform, etc.)
 * - Returned function will generate a new URL based on those parameters
 *
 * @see 'src/config/images/index.js'
 * @param {object} positionConfig - configuration options for an image position (called an image size in WordPress)
 */
function createTransformSourceGravatar(positionConfig) {
  /**
   * URL transform function for Gravatar
   *
   * @param {string} originalSource - original source URL for an image
   * @param {Object} params - transform parameters to be used for modifying original image URL
   * @param {number} params.width - target width/height value for image transformation (images always square)
   */
  return (originalSource, parameters = {}) => {
    // Return original URL if width isn't set
    if (!parameters.width) {
      return originalSource;
    }

    const extractedQueryString = queryString.extract(originalSource);
    const queryParameters = queryString.parse(extractedQueryString);

    const cleanSource = originalSource.split('?')[0];
    const { width, ...newParametersWithoutWidth } = parameters;
    const gravatarParameters = queryString.stringify({
      ...queryParameters,
      ...newParametersWithoutWidth,
      s: width || positionConfig.width,
    });

    return `${cleanSource}?${gravatarParameters}`;
  };
}

// Module Exports
export { createTransformSourceGravatar };
