// Internal Imports
import { ContentItem } from '../../../components/ContentItem/ContentItem.jsx';
import { ContentArchive } from '../../../components/ContentArchive/ContentArchive.jsx';
import { LandingPage } from '../../../components/LandingPage/LandingPage.jsx';
import {
  TERM_ARCHIVE,
  SEARCH_ARCHIVE,
  AUTHOR_ARCHIVE,
  CATEGORY_ARCHIVE,
  CONTENT_ITEM_SINGLE,
  HOME,
} from '../path.mjs';

// Local Variables
// Defines the container component to render for each path type.
const containerMap = {
  [AUTHOR_ARCHIVE]: ContentArchive,
  [CATEGORY_ARCHIVE]: ContentArchive,
  [CONTENT_ITEM_SINGLE]: ContentItem,
  [HOME]: LandingPage,
  [SEARCH_ARCHIVE]: ContentArchive,
  [TERM_ARCHIVE]: ContentArchive,
};

// Module Exports
export { containerMap };
