// External Imports
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Layout, TextField } from '@lessonnine/design-system.lib';

// Internal Imports
import { createSelectTranslation } from '../../../selectors/createSelectTranslation.mjs';
import { I18n } from '../../I18n/I18n.jsx';
import { options } from '../../../config/reactRedux.mjs';

// Component Definition
class SearchFieldBase extends Component {
  constructor(props) {
    super(props);

    this.state = { searchParam: props.params.s };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { params } = this.props;
    if (!isEqual(params, newProps.params)) {
      this.setState({ searchParam: newProps.params.s });
    }
  }

  onInputChange = (inputEvent) => {
    this.setState({ searchParam: inputEvent.target.value });
  };

  onSubmit = (formEvent) => {
    formEvent.preventDefault();

    // Auto-hide mobile, software keyboards
    const blurElement = formEvent.currentTarget[0];
    blurElement.focus();
    blurElement.blur();

    const { params, onSubmit } = this.props;
    const { searchParam } = this.state;
    if (searchParam.trim()) {
      onSubmit({ ...params, s: searchParam });
    }
  };

  render() {
    const { getTranslation } = this.props;
    const { searchParam } = this.state;

    return (
      <Layout
        action="/"
        // eslint-disable-next-line sort-keys -- ordered from small to big
        alignItems={{ xxxsmall: 'start', xxsmall: 'center' }}
        as="form"
        // eslint-disable-next-line sort-keys -- ordered from small to big
        direction={{ xxxsmall: 'stack', xxsmall: 'inline' }}
        gap={8}
        onSubmit={this.onSubmit}
        role="search"
      >
        <TextField
          appearance="outlined"
          name="search"
          type="search"
          placeholder={getTranslation('search', 'Search')}
          value={searchParam}
          onChange={this.onInputChange}
          fullWidth
        />
        <Button type="submit">
          <I18n field="search" defaultText="Search" />
        </Button>
      </Layout>
    );
  }
}

SearchFieldBase.propTypes = {
  getTranslation: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  params: PropTypes.shape({
    s: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  getTranslation: createSelectTranslation(state),
});

const withRedux = connect(mapStateToProps, undefined, undefined, options);
const SearchField = withRedux(SearchFieldBase);

// Module Exports
export { SearchField };
