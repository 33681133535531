// External Imports
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { Spinner, styled } from '@lessonnine/design-system.lib';

// Internal Imports
import { createTransformSourcePhoton, positions } from '../../config/images/index.mjs';
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { DsLink } from '../Link/DsLink.jsx';

const StyledFigure = styled.figure`
  overflow: hidden;
  position: relative;
  ${({ imageType }) =>
    (imageType === 'featuredImage' || imageType === 'articleImage') &&
    `
    aspect-ratio: 16 / 9;
    border-radius: 9px;
    @media ${breakpoints.mobile.min} {
      border-radius: 6px;
    }
    @media ${breakpoints.tabletLandscape.min} {
      border-radius: 8px;
    }
  `}
`;

const LoadImage = styled.img`
  position: relative;
  ${({ imageType }) =>
    (imageType === 'featuredImage' || imageType === 'articleImage') &&
    `
    width:100%;
    height: auto;
    border-radius: 9px;
    ${
      imageType === 'featuredImage' &&
      `
      &:hover {
        transform: scale(1.05);
        transition: transform 0.4s ease-in-out;
      }
    `
    }
    @media ${breakpoints.mobile.min} {
      border-radius: 6px;
    }
    @media ${breakpoints.tabletLandscape.min} {
      border-radius: 8px;
    }
  `}
  ${({ imageType }) =>
    imageType === 'authorOverview' &&
    `
    border-radius: 4px;
    height: 140px;
    width: 140px;
    @media ${breakpoints.tabletPortrait.max} {
      height: 72px;
      width: 72px;
    }
  `}
  ${({ imageType }) =>
    imageType === 'authorHeader' &&
    `
    border-radius: 8px;
    height: 220px;
    width: 220px;
    @media ${breakpoints.mobile.max} {
      height: 140px;
      width: 140px;
    }
  `}
`;

const SpinnerContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.surface.layer.shimmer.background};
  border-radius: 6px;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

class Thumbnail extends Component {
  constructor(props) {
    super(props);

    const { originalSource, position } = props;
    this.imageRef = createRef();
    this.positionConfig = positions[position];
    this.shouldUseSrcSet = this.positionConfig.sources.length > 1;
    this.state = {
      currentSizes: this.getSizes(),
      currentSrc: this.getSrc(originalSource),
      currentSrcSet: this.getSrcSet(originalSource),
      isError: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    const imgElement = this.imageRef.current;

    // If the image loads before React mounts, trigger the load condition so the loading spinner is removed
    if (imgElement.complete) {
      // Image width of zero implies an error, otherwise success
      if (imgElement.naturalWidth === 0) {
        this.onErrorImage();
      } else {
        this.onLoadImage();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { originalSource } = this.props;
    if (originalSource !== nextProps.originalSource) {
      this.setState({
        currentSrc: this.getSrc(nextProps.originalSource),
        currentSrcSet: this.getSrcSet(nextProps.originalSource),
      });
    }
  }

  onErrorImage = () => this.setState({ isError: true });

  onLoadImage = () => this.setState({ isLoading: false });

  getSrc(originalSource) {
    const sourceParameters = this.positionConfig.sources;

    // If zero sources are configured, return original image
    if (sourceParameters.length === 0) {
      return originalSource;
    }

    // Use the configured default src, otherwise use the first configured src
    let defaultParameters = sourceParameters.filter(({ isDefault }) => isDefault);
    defaultParameters = defaultParameters.length > 0 ? defaultParameters[0] : sourceParameters[0];
    return this.transformSource(originalSource, defaultParameters);
  }

  getSrcSet(originalSource) {
    // Only use srcset if there is more than one configured source
    if (!this.shouldUseSrcSet) {
      return null; // eslint-disable-line unicorn/no-null -- consistent return value
    }

    const sourceParameters = this.positionConfig.sources;

    return sourceParameters
      .map((parameters) => {
        const transformedSource = this.transformSource(originalSource, parameters);
        return `${transformedSource} ${parameters.width}w`;
      })
      .join(',');
  }

  getSizes() {
    // Only use sizes attribute if there is more than one configured source
    if (!this.shouldUseSrcSet) {
      return null; // eslint-disable-line unicorn/no-null -- consistent return value
    }

    const { sizes } = this.positionConfig;

    // Return default value if no sizes specified
    if (sizes.length === 0) {
      return '100vw';
    }

    return sizes.map(({ mediaQuery, value }) => `${mediaQuery || ''} ${value}`).join(',');
  }

  transformSource = (originalSource, parameters) => {
    const { transformFactory, position } = this.props;
    const transform = transformFactory(positions[position]);

    return transform(originalSource, parameters);
  };

  render() {
    const {
      alt,
      isLazyLoading,
      hideLinkFromAccessibility,
      wrapperStyle,
      children,
      imageLink,
      onClickImage,
    } = this.props;
    const { isLoading, isError, currentSrc, currentSrcSet, currentSizes } = this.state;

    const imgComponent = isLazyLoading ? (
      <LoadImage
        className="lazyload"
        data-srcset={currentSrcSet}
        data-sizes={currentSizes}
        data-src={currentSrc}
        alt={alt}
        onLoad={this.onLoadImage}
        onError={this.onErrorImage}
        key={currentSrc}
        isLoading={isLoading}
        isError={isError}
        imageType={wrapperStyle}
        ref={this.imageRef}
      />
    ) : (
      <LoadImage
        srcSet={currentSrcSet}
        sizes={currentSizes}
        src={currentSrc}
        alt={alt}
        onLoad={this.onLoadImage}
        onError={this.onErrorImage}
        isLoading={isLoading}
        isError={isError}
        imageType={wrapperStyle}
        ref={this.imageRef}
      />
    );
    const imageWithSpinner = (
      <>
        {imgComponent}
        {isLoading && !isError && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </>
    );

    return (
      <StyledFigure imageType={wrapperStyle}>
        {imageLink ? (
          <DsLink
            inherited
            to={imageLink}
            onClick={onClickImage}
            // eslint-disable-next-line react/jsx-props-no-spreading -- only apply these as a set or none at all
            {...(hideLinkFromAccessibility ? { 'aria-hidden': true, tabIndex: -1 } : {})}
          >
            {imageWithSpinner}
          </DsLink>
        ) : (
          <div>{imageWithSpinner}</div>
        )}
        {children && <figcaption className="imageCaption">{children}</figcaption>}
      </StyledFigure>
    );
  }
}

Thumbnail.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  hideLinkFromAccessibility: PropTypes.bool,
  imageLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isLazyLoading: PropTypes.bool,
  onClickImage: PropTypes.func,
  originalSource: PropTypes.string.isRequired,
  position: PropTypes.oneOf(Object.keys(positions)),
  transformFactory: PropTypes.func,
  wrapperStyle: PropTypes.string,
};

Thumbnail.defaultProps = {
  alt: '',
  children: null, // eslint-disable-line unicorn/no-null -- consistent return value
  hideLinkFromAccessibility: false,
  imageLink: '',
  isLazyLoading: false,
  onClickImage: () => {},
  position: 'full',
  transformFactory: createTransformSourcePhoton,
  wrapperStyle: '',
};

// Module Exports
export { Thumbnail };
