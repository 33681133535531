// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { CONTENT_ITEM_SINGLE } from '../config/types/path.mjs';
import { pageType } from '../utils/tracking.mjs';
import { createSelectContentItemBySlug } from './createSelectContentItem.mjs';
import { selectIsInProductMagazine } from './selectIsInProductMagazine.mjs';
import { selectLocale } from './selectLocale.mjs';
import { selectLearnLanguage } from './selectLearnLanguage.mjs';
import {
  selectArticleSlug,
  selectContentType,
  selectPathType,
  selectUrl,
  selectQuery,
} from './selectMatch.mjs';
import { selectUUID, selectIsInitialized } from './selectUser.mjs';

// Local Functions
/**
 * Selector that will return an object containing common tracking fields
 *
 * @param {string} uuid User's UUID
 * @param {string} locale Magazine language edition
 * @param {string} path URL path
 * @param {string} query URL query parameters
 * @param {string} isInProductMagazine Is this app being loaded in the mobile app
 * @return {function} selector function
 */
const selectPageTrackingDataCommon = createSelector(
  [selectUUID, selectLocale, selectUrl, selectQuery, selectIsInProductMagazine],
  (uuid, locale, path, query, isInProductMagazine) => {
    const search = Object.entries(query)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    return {
      locale: locale === 'en-US' ? 'en' : locale,
      page_path: `${path}${search.length > 0 ? `?${search}` : ''}`,
      ...(uuid && { uuid }),
      in_product: isInProductMagazine,
    };
  },
);

/**
 * Selector that will return the payload for event tracking non-404 pages
 *
 * @param {string} state Application state
 * @param {object} pageTrackingDataCommon Common data shared between all pageTrackingData* functions
 * @param {string} isUserInitialized Is the request done
 * @param {string} pathType Page type
 * @param {string} contentType data of the article
 * @param {string} articleSlug slug of the article
 * @param {string} learnLanguage Learn language
 * @return {function} selector function
 */
const selectPageTrackingData = createSelector(
  [
    (state) => state,
    selectPageTrackingDataCommon,
    selectIsInitialized,
    selectPathType,
    selectContentType,
    selectArticleSlug,
    selectLearnLanguage,
  ],
  (
    state,
    pageTrackingDataCommon,
    isUserInitialized,
    pathType,
    contentType,
    articleSlug,
    learnLanguage,
  ) => {
    if (!isUserInitialized) {
      return { data: {}, isLoading: true };
    }

    let articlePayload = {};
    if (pathType === CONTENT_ITEM_SINGLE) {
      const selectContentItemBySlug = createSelectContentItemBySlug(contentType);
      const item = selectContentItemBySlug(state);
      if (!item) {
        return { data: {}, isLoading: true };
      }
      const {
        metadata: { categories: [category] = [], coauthors: [author] = [], tags },
      } = item;
      articlePayload = {
        ...articlePayload,
        ...(author?.slug && { article_author: author.slug }),
        ...(category && { article_category: category }),
        ...(tags?.length && { article_tags: tags.map(({ label }) => label) }),
        article_slug: articleSlug,
      };
    }

    return {
      data: {
        ...pageTrackingDataCommon,
        ...articlePayload,
        learn_language_alpha3: learnLanguage,
        page_type: pageType[pathType],
      },
      isLoading: false,
    };
  },
);

/**
 * Selector that will return the payload for event tracking 404 pages
 *
 * @param {object} pageTrackingDataCommon Common data shared between all pageTrackingData* functions
 * @param {string} isUserInitialized Is the request done
 * @return {function} selector function
 */
const selectPageTrackingData404 = createSelector(
  [selectPageTrackingDataCommon, selectIsInitialized],
  (pageTrackingDataCommon, isUserInitialized) => {
    if (!isUserInitialized) {
      return { data: {}, isLoading: true };
    }

    return {
      data: {
        ...pageTrackingDataCommon,
        page_type: '404',
      },
      isLoading: false,
    };
  },
);

// Module Exports
export { selectPageTrackingData, selectPageTrackingData404 };
