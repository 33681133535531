// Local Variables
const FRENCH = 'french_edition';
const GERMAN = 'german_edition';
const ITALIAN = 'italian_edition';
const LANDING = 'landing-page';
const POLISH = 'polish_edition';
const PORTUGUESE = 'portuguese_edition';
const POST = 'post';
const SPANISH = 'spanish_edition';
const SWEDISH = 'swedish_edition';
const searchTypes = [POST, GERMAN, FRENCH, ITALIAN, POLISH, PORTUGUESE, SPANISH, SWEDISH];

// Module Exports
export {
  FRENCH,
  GERMAN,
  ITALIAN,
  LANDING,
  POLISH,
  PORTUGUESE,
  POST,
  searchTypes,
  SPANISH,
  SWEDISH,
};
