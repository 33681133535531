// External Imports
import { styled } from '@lessonnine/design-system.lib';

// Internal Imports
import { conditionalDisplay } from '../../utils/conditionalDisplayStyles.mjs';

// Component Definition
const ConditionalDisplay = styled.div`
  ${conditionalDisplay}
`;

// Module Exports
export { ConditionalDisplay };
