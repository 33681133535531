// External Imports
import React from 'react';
import { Inline, Layout, styled, Text } from '@lessonnine/design-system.lib';

// Internal Imports
import { FILTER_CONTROL } from '../../config/types/prop.mjs';

// Local Variables
const InlineWithRelative = styled(Inline)`
  position: relative;
`;

const HiddenInput = styled.input`
  /* 
    Not using display: none or visibility:hidden 
    here as the inputs need to be in the DOM to receive focus
  */
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const TextRadioLabel = styled(Text)`
  ${({ isSelected, theme }) => `
    color: ${
      isSelected
        ? theme.color.interactive.navigation.foreground.active
        : theme.color.interactive.navigation.foreground.resting
    }
  `}
`;

const Wrapper = styled(Inline)`
  border: solid 1px ${({ theme }) => theme.color.surface.layer.separator};
  border-left: none;
  border-right: none;
`;

// Component Definition
// TODO: The design-system currently doesn't have such controls, it might make sense to check whether they are interesting and if such component should exist in the DS, or if we should review how to do this in accordance with the design-system
function ButtonControl({ label, name, options, onChange, selected }) {
  return (
    <form action="/">
      {/* eslint-disable-next-line sort-keys -- ordering by increasing size is easier to comprehend */}
      <Wrapper gap={{ small: 16, medium: 48 }} padding={[16, 0]}>
        <Text as="label" htmlFor={name} fontWeight={600}>
          {label}
        </Text>
        <Layout
          /* eslint-disable-next-line sort-keys -- ordering by increasing size is easier to comprehend */
          direction={{ xxsmall: 'stack', xsmall: 'inline' }}
          /* eslint-disable-next-line sort-keys -- ordering by increasing size is easier to comprehend */
          gap={{ xxsmall: 12, medium: 32 }}
        >
          {options.map(({ value, label: optionLabel }, index) => (
            <InlineWithRelative key={value}>
              <HiddenInput
                type="radio"
                id={`${name}${index}`}
                value={value}
                name={name}
                checked={value === selected}
                onChange={(changeEvent) => onChange(changeEvent)}
              />
              <TextRadioLabel
                as="label"
                htmlFor={`${name}${index}`}
                isSelected={value === selected}
                fontSize="small"
                fontWeight={600}
              >
                {optionLabel.toUpperCase()}
              </TextRadioLabel>
            </InlineWithRelative>
          ))}
        </Layout>
      </Wrapper>
    </form>
  );
}

ButtonControl.propTypes = FILTER_CONTROL;
ButtonControl.defaultProps = {
  defaultLabel: '',
  selected: '',
};

// Module Exports
export { ButtonControl };
