// External Imports
import get from 'lodash/fp/get.js';
import { createSelector } from 'reselect';

// Internal Imports
import { isInProduct } from '../utils/inProduct.mjs';

// Local Functions
const selectIsInProductMagazine = createSelector(get('router'), ({ location }) =>
  isInProduct(location),
);

// Module Exports
export { selectIsInProductMagazine };
