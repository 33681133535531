// External Imports
import get from 'lodash/fp/get.js';
import keyBy from 'lodash/fp/keyBy.js';
import merge from 'lodash/fp/merge.js';

// Local Functions
/**
 * Map the values of an object from expected action types to
 * entity state slice reducers.
 * @param   {Object} entityTypeActionTypeMap map of entity types to Redux action types
 * @returns {Object}                         map of entity types to Redux reducers
 */
function combineEntityReducers(entityTypeActionTypeMap) {
  const combinedReducers = {};
  for (const [entityType, actionType] of Object.entries(entityTypeActionTypeMap)) {
    combinedReducers[entityType] = createEntityReducer(actionType, entityType);
  }

  return combinedReducers;
}

/**
 * Create a state slice reducer that indexes and merges entities from
 * an expected Redux action type.
 * @param   {string}   actionType Redux action type
 * @param   {string}   entityType entity type the reducer acts on
 * @returns {function}            Redux reducer
 */
function createEntityReducer(actionType, entityType) {
  return (state = {}, { type, payload } = {}) => {
    const entities = get(entityType, payload);
    if (type !== actionType || !entities) {
      return state;
    }

    if (entities.length > 0 && entities[0].id) {
      return merge(state, keyBy('id', entities));
    }

    return merge(state, entities);
  };
}

// Module Exports
export { combineEntityReducers, createEntityReducer };
