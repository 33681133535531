// External Imports
import { css } from '@lessonnine/design-system.lib';

// Component Definition
const separatorStyles = css`
  background-color: ${({ theme }) => theme.color.surface.container.separator};

  /* Useful to reset border for hr elements */
  border: none;
  height: 2px;
  margin: 8px 0;
  width: 100%;
`;

// Module Exports
export { separatorStyles };
