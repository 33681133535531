import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled, useId, BaseButton, IconCaretDownBold } from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

const ButtonMenu = styled(BaseButton)`
  align-items: center;
  border: none;
  border-radius: 0;
  color: ${({ theme }) => theme.color.accentInverse.onEnabled};
  cursor: pointer;
  display: flex;
  font-family: ${tokens.fonts.default.name};
  font-size: inherit;
  gap: ${tokens.space.x1};
  height: auto;
  padding: ${tokens.size.x3} ${tokens.size.x3};

  &:hover {
    border: 0;
    color: ${({ theme }) => theme.color.brand.hover};
  }
`;

const DisclosureMenuContent = styled.ul`
  position: relative;
`;

const DisclosureMenuItemList = styled.ul`
  background-color: ${({ theme }) => theme.color.accentInverse.enabled};
  border: 1px solid ${({ theme }) => theme.color.accentInverse.enabledBorderSubdued};
  border-radius: ${tokens.radius.medium};
  color: ${({ theme }) => theme.color.accentInverse.onEnabled};
  left: 0;
  min-width: 160px;
  overflow: auto;
  padding: ${tokens.size.x1} 0;
  position: absolute;
  top: 100%;
  z-index: 10;
`;

function DisclosureMenu({ label, items, navLabelId }) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const uniqueId = useId();
  const buttonId = `button-menu-${uniqueId}`;
  const ariaLabelledBy = navLabelId.button ? buttonId : navLabelId.id;

  const toggleOpen = () => {
    setIsOpen((currentValue) => !currentValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsOpen((currentValue) => !currentValue);
      buttonRef.current.focus();
    }
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen((currentValue) => !currentValue);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <nav aria-labelledby={ariaLabelledBy}>
      <DisclosureMenuContent ref={menuRef}>
        <li>
          <ButtonMenu
            ref={buttonRef}
            onClick={toggleOpen}
            aria-expanded={isOpen}
            color="ghost"
            id={buttonId}
            aria-controls={uniqueId}
          >
            {label} <IconCaretDownBold size="xsmall" />
          </ButtonMenu>
          {isOpen && (
            <DisclosureMenuItemList id={uniqueId}>
              {items.map((item) => (
                <li key={item.key}>{item}</li>
              ))}
            </DisclosureMenuItemList>
          )}
        </li>
      </DisclosureMenuContent>
    </nav>
  );
}

DisclosureMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  label: PropTypes.node.isRequired,
  navLabelId: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      button: PropTypes.bool.isRequired,
    }),
  ]).isRequired,
};

export { DisclosureMenu };
