// External Imports
import values from 'lodash/values.js';
import PropTypes from 'prop-types';

// Internal Imports
import * as contentTypes from './content/index.mjs';
import { positions } from '../images/index.mjs';
import { languageCodesArray } from './language.mjs';

// Local Variables
const idType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired;

const POSITIONS = PropTypes.oneOf(Object.keys(positions));
const LANGUAGES = PropTypes.oneOf(languageCodesArray);
// TODO: differentiate locales (can be en-US) and editions (can't be en-US)

const SOCIAL_SHARE_SHAPE = PropTypes.shape({
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  link: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  titleAndLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
});

const SOCIAL_LINKS = {
  facebook: PropTypes.string,
  googlePlus: PropTypes.string,
  twitter: PropTypes.string,
  youtube: PropTypes.string,
};

const TAG = {
  id: idType,
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

const TAG_SHAPE = PropTypes.shape(TAG);

const COAUTHOR_SHAPE = {
  absolutePath: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  id: idType,
  lastName: PropTypes.string,
  relativePath: PropTypes.string.isRequired,
  slug: PropTypes.string,
};

const CONTENT_ITEM = {
  coauthors: PropTypes.oneOfType([PropTypes.arrayOf(COAUTHOR_SHAPE), PropTypes.array]),
  count: PropTypes.number,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: idType,
  metadata: PropTypes.shape({
    canonicalUrl: PropTypes.string,
    headerMeta: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        name: PropTypes.string,
      }),
    ),
    languageHreflang: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        hreflang: PropTypes.string.isRequired,
      }),
    ),
    type: PropTypes.oneOfType([PropTypes.oneOf(values(contentTypes)), PropTypes.bool]).isRequired,
  }).isRequired,
  modified: PropTypes.string,
  path: PropTypes.string.isRequired,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
};

const POST = {
  ...CONTENT_ITEM,
  content: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  metadata: PropTypes.shape({
    featuredImageUrl: PropTypes.string.isRequired,
    morePosts: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

const LANDING_PAGE = {
  item: PropTypes.shape(CONTENT_ITEM).isRequired,
  loadingMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    hasMore: PropTypes.bool.isRequired,
    totalPages: PropTypes.number.isRequired,
  }).isRequired,
  relatedItems: PropTypes.arrayOf(PropTypes.shape(CONTENT_ITEM)).isRequired,
};

const CONTENT_ARCHIVE = {
  isInProductMagazine: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(CONTENT_ITEM)).isRequired,
  loadingMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    hasMore: PropTypes.bool.isRequired,
    paginationLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
    totalPages: PropTypes.number.isRequired,
  }).isRequired,
};

const MENU = {
  fullUrl: PropTypes.string,
  id: PropTypes.number,
  label: PropTypes.string,
  postType: PropTypes.string,
  relativeUrl: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
};

const FILTER_CONTROL = {
  defaultLabel: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.string,
};

const LANGUAGE = {
  bgColor: PropTypes.string,
  flag: PropTypes.string.isRequired,
  isCircle: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  url: PropTypes.string.isRequired,
};

// Module Exports
export {
  COAUTHOR_SHAPE,
  CONTENT_ARCHIVE,
  CONTENT_ITEM,
  FILTER_CONTROL,
  LANDING_PAGE,
  LANGUAGE,
  LANGUAGES,
  MENU,
  POSITIONS,
  POST,
  SOCIAL_LINKS,
  SOCIAL_SHARE_SHAPE,
  TAG,
  TAG_SHAPE,
};
