// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import {
  FRENCH,
  GERMAN,
  POLISH,
  SWEDISH,
  ITALIAN,
  PORTUGUESE,
  SPANISH,
} from '../config/types/language.mjs';
import { selectLanguage } from './selectMatch.mjs';

// Local Variables
// When adding a new heading to the nav
// Add it to this array (used for testing)
// and use the mapped version in the navHeadings obj for consistency
const requiredNavHeadingKeys = ['categories', 'aboutLanguages'];
const headingObject = {};
for (const heading of requiredNavHeadingKeys) {
  headingObject[heading] = heading;
}

// Local Functions
const selectNavigationText = createSelector([selectLanguage], (languageCode) => {
  switch (languageCode) {
    case GERMAN:
      return {
        [headingObject.categories]: [
          {
            label: 'Lernen',
            url: '/lernen',
          },
          {
            label: 'Kultur',
            url: '/kultur',
          },
          {
            label: 'Reisen',
            url: '/reisen',
          },
          {
            label: 'Tipps',
            url: '/tipps',
          },
          {
            label: 'Das Produkt',
            url: '/das-produkt',
          },
          {
            label: 'Inside Babbel',
            url: '/inside-babbel',
          },
        ],
        [headingObject.aboutLanguages]: [
          {
            label: 'Englisch',
            url: '/englisch',
          },
          {
            label: 'Französisch',
            url: '/franzosisch',
          },
          {
            label: 'Spanisch',
            url: '/spanisch',
          },
          {
            label: 'Italienisch',
            url: '/italienisch',
          },
          {
            label: 'Türkisch',
            url: '/turkisch',
          },
          {
            label: 'Deutsch',
            url: '/deutsch',
          },
        ],
      };
    case FRENCH:
      return {
        [headingObject.categories]: [
          {
            label: 'Apprendre',
            url: '/apprendre',
          },
          {
            label: 'Conseils',
            url: '/conseils',
          },
          {
            label: 'Cultures',
            url: '/cultures',
          },
          {
            label: 'Infos pratiques',
            url: '/infos-pratiques',
          },
          {
            label: 'Le monde de Babbel',
            url: '/le-monde-de-babbel',
          },
        ],
        [headingObject.aboutLanguages]: [
          {
            label: "L'anglais",
            url: '/anglais',
          },
          {
            label: "L'espagnol",
            url: '/espagnol',
          },
          {
            label: "L'allemand",
            url: '/allemand',
          },
          {
            label: "L'italien",
            url: '/italien',
          },
          {
            label: 'Le russe',
            url: '/russe',
          },
        ],
      };
    case ITALIAN:
      return {
        [headingObject.categories]: [
          {
            label: 'Apprendimento',
            url: '/apprendimento',
          },
          {
            label: 'Consigli',
            url: '/consigli',
          },
          {
            label: 'Cultura',
            url: '/cultura',
          },
          {
            label: 'Storie',
            url: '/storie',
          },
          {
            label: 'Curiosità',
            url: '/curiosita',
          },
          {
            label: 'Babbel si racconta',
            url: '/babbel-si-racconta',
          },
        ],
        [headingObject.aboutLanguages]: [
          {
            label: 'Inglese',
            url: '/inglese',
          },
          {
            label: 'Spagnolo',
            url: '/spagnolo',
          },
          {
            label: 'Francese',
            url: '/francese',
          },
          {
            label: 'Tedesco',
            url: '/tedesco',
          },
          {
            label: 'Portoghese',
            url: '/portoghese',
          },
        ],
      };
    case POLISH:
      return {
        [headingObject.categories]: [
          {
            label: 'O językach',
            url: '/o-jezykach',
          },
          {
            label: 'Porady',
            url: '/porady',
          },
          {
            label: 'Ciekawostki',
            url: '/ciekawostki',
          },
        ],
        [headingObject.aboutLanguages]: [
          {
            label: 'Angielski',
            url: '/angielski',
          },
          {
            label: 'Niemiecki',
            url: '/niemiecki',
          },
          {
            label: 'Francuski',
            url: '/francuski',
          },
          {
            label: 'Włoski',
            url: '/wloski',
          },
          {
            label: 'Hiszpański',
            url: '/hiszpanski',
          },
        ],
      };
    case PORTUGUESE:
      return {
        [headingObject.categories]: [
          {
            label: 'Aprendizado',
            url: '/aprendizado',
          },
          {
            label: 'Dicas',
            url: '/dicas',
          },
          {
            label: 'Cultura',
            url: '/cultura',
          },
          {
            label: 'Histórias',
            url: '/historias',
          },
          {
            label: 'Curiosidades',
            url: '/curiosidades',
          },
          {
            label: 'Histórias da Babbel',
            url: '/historias-da-babbel',
          },
        ],
        [headingObject.aboutLanguages]: [
          {
            label: 'Inglês',
            url: '/ingles',
          },
          {
            label: 'Espanhol',
            url: '/espanhol',
          },
          {
            label: 'Francês',
            url: '/frances',
          },
          {
            label: 'Italiano',
            url: '/italiano',
          },
          {
            label: 'Alemão',
            url: '/alemao',
          },
        ],
      };
    case SPANISH:
      return {
        [headingObject.categories]: [
          {
            label: 'Aprendizaje',
            url: '/aprendizaje',
          },
          {
            label: 'Consejos',
            url: '/consejos',
          },
          {
            label: 'Cultura',
            url: '/cultura',
          },
          {
            label: 'Anécdotas',
            url: '/anecdotas',
          },
          {
            label: 'Curiosidades',
            url: '/curiosidades',
          },
          {
            label: 'Babbel desde dentro',
            url: '/babbel-desde-dentro',
          },
        ],
        [headingObject.aboutLanguages]: [
          {
            label: 'Inglés',
            url: '/ingles',
          },
          {
            label: 'Alemán',
            url: '/aleman',
          },
          {
            label: 'Francés',
            url: '/frances',
          },
          {
            label: 'Portugués',
            url: '/portugues',
          },
          {
            label: 'Italiano',
            url: '/italiano',
          },
        ],
      };
    case SWEDISH:
      return {
        [headingObject.categories]: [
          {
            label: 'Om språk',
            url: '/om-sprak',
          },
          {
            label: 'Tips',
            url: '/tips',
          },
          {
            label: 'Kultur',
            url: '/kultur',
          },
          {
            label: 'Inside Babbel',
            url: '/inside-babbel',
          },
        ],
        [headingObject.aboutLanguages]: [],
      };
    default:
      return {
        [headingObject.categories]: [
          {
            label: 'Learn',
            url: '/learn',
          },
          {
            label: 'Culture',
            url: '/culture',
          },
          {
            label: 'Stories',
            url: '/stories',
          },
          {
            label: 'Fun',
            url: '/fun',
          },
          {
            label: 'Inside Babbel',
            url: '/inside-babbel',
          },
          {
            label: 'Babbel Bytes',
            url: '/babbel-bytes',
          },
        ],
        [headingObject.aboutLanguages]: [
          {
            label: 'Spanish',
            url: '/spanish',
          },
          {
            label: 'French',
            url: '/french',
          },
          {
            label: 'English',
            url: '/english',
          },
          {
            label: 'German',
            url: '/german',
          },
          {
            label: 'Italian',
            url: '/italian',
          },
          {
            label: 'See All',
            url: '/learning-guides',
          },
        ],
      };
  }
});

// Module Exports
export { requiredNavHeadingKeys, selectNavigationText };
