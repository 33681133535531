// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { removeLinks } from '../utils/removeLinks.mjs';
import { getHostname, isLocalHost } from '../utils/url.mjs';

// Function Definition
const selectRemoveLinks = createSelector(
  (state) => state.router.location.pathname,
  (pathname) => (content) => {
    const hostname = getHostname();
    const protocol = isLocalHost(hostname) ? 'http' : 'https';
    const origin = `${protocol}://${hostname}`;
    return removeLinks(content, new URL(pathname, origin));
  },
);

// Module Export
export { selectRemoveLinks };
