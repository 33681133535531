// Internal Imports
import {
  getInProductLocale,
  getInProductLearnLanguage,
  isInProduct,
  makePathInProduct,
} from './inProduct.mjs';

// Local Functions
const createHistoryWithInProductQueryParameter = (history) => {
  if (!isInProduct(history.location)) {
    return history;
  }

  const { push, replace } = history;
  const locale = getInProductLocale(history.location);
  const learnLanguage = getInProductLearnLanguage(history.location);

  const pushWithQueryParameter = (path, ...remainingParameters) =>
    push(makePathInProduct(path, locale, learnLanguage), ...remainingParameters);
  const replaceWithQueryParameter = (path, ...remainingParameters) =>
    replace(makePathInProduct(path, locale, learnLanguage), ...remainingParameters);

  // eslint-disable-next-line no-param-reassign -- the history object has properties that can't be replicated through Object recreation, so we must mutate it
  history.push = pushWithQueryParameter;
  // eslint-disable-next-line no-param-reassign -- the history object has properties that can't be replicated through Object recreation, so we must mutate it
  history.replace = replaceWithQueryParameter;

  return history;
};

// Module Exports
export { createHistoryWithInProductQueryParameter };
