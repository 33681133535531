// External Imports
import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Internal Imports
import { useContentEmbed } from '../../hooks/useContentEmbed.mjs';

// TODO: after migrating to class-based component we could get rid of this component and use the
// hooks directly in the index. For more documentation on this see docs/article-content-embeds.md

// Component Definition
function ContentScriptEmbedHandler({ contentScripts }) {
  const { addScriptEmbeds, removeScriptEmbeds } = useContentEmbed();

  useEffect(() => {
    addScriptEmbeds(contentScripts);
    return () => removeScriptEmbeds(contentScripts);
  }, [addScriptEmbeds, contentScripts, removeScriptEmbeds]);

  // eslint-disable-next-line unicorn/no-null -- React expects null for empty renders
  return null;
}

ContentScriptEmbedHandler.propTypes = {
  contentScripts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// Module Exports
export { ContentScriptEmbedHandler };
