// Local Variables
const richText = {
  allowedAttributes: {
    '*': ['class', 'data-*'],
    a: ['href', 'name', 'target', 'rel'],
    iframe: ['*'],
    img: ['src', 'srcset', 'width', 'height', 'alt'],
    link: ['href', 'rel', 'type'],
    p: ['style'],
    script: ['type', 'src'],
    span: ['style'],
  },
  allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
  allowedSchemesByTag: {},
  allowedTags: [
    'a',
    'b',
    'blockquote',
    'br',
    'button',
    'caption',
    'cite',
    'code',
    'div',
    'em',
    'figcaption',
    'figure',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'i',
    'iframe',
    'img',
    'li',
    'link',
    'nl',
    'ol',
    'p',
    'pre',
    'script',
    'span',
    'strike',
    'strong',
    'style',
    'table',
    'tbody',
    'td',
    'th',
    'thead',
    'tr',
    'ul',
    'sup',
    'sub',
  ],
  allowProtocolRelative: true,
  allowVulnerableTags: true,
  parseStyleAttributes: false,
  selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
};

const plainText = {
  allowedAttributes: {
    a: ['href', 'name', 'target'],
  },
  allowedTags: ['a', 'b', 'i', 'strong', 'em', 'span'],
};

const plainTextNoHTML = {
  allowedAttributes: [],
  allowedTags: [],
};

// Module Exports
export { plainText, plainTextNoHTML, richText };
