// External Imports
import {
  FlagPor,
  FlagDeu,
  FlagDan,
  FlagSpa,
  FlagFra,
  FlagEng,
  FlagInd,
  FlagIta,
  FlagQms,
  FlagNld,
  FlagNor,
  FlagPol,
  FlagRus,
  FlagSwe,
  FlagTur,
} from '@lessonnine/design-system.lib';

// Local Variables
const languageToFlagMap = {
  // FlagPor shows the brazilian flag
  br: FlagPor,
  de: FlagDeu,
  dk: FlagDan,
  es: FlagSpa,
  fr: FlagFra,
  gb: FlagEng,
  id: FlagInd,
  it: FlagIta,
  mx: FlagQms,
  nl: FlagNld,
  no: FlagNor,
  pl: FlagPol,
  ru: FlagRus,
  se: FlagSwe,
  tr: FlagTur,
};

// Module Exports
export { languageToFlagMap };
