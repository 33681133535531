// External Imports
import get from 'lodash/fp/get.js';
import { createSelector } from 'reselect';

// Internal Imports
import { TERM_ARCHIVE } from '../config/types/path.mjs';
import { tagToLearnLanguage } from '../utils/tagToLearnLanguage.mjs';
import { selectMatch } from './selectMatch.mjs';
import { selectEdition } from './selectEdition.mjs';

// Local Functions
const selectLearnLanguage = createSelector([selectMatch, selectEdition], (match, edition) => {
  const {
    pathType,
    params: { slug },
  } = match ?? { params: {} };

  if (pathType !== TERM_ARCHIVE) {
    return get('query.learn_language', match);
  }

  return tagToLearnLanguage(edition, slug);
});

// Module Exports
export { selectLearnLanguage };
