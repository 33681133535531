// External Imports
import compose from 'lodash/fp/compose.js';
import set from 'lodash/fp/set.js';

// Internal Imports
import { RECEIVE_PRIMARY_CONTENT } from '../config/types/action.mjs';
import { selectRouteKey } from '../selectors/selectRouteKey.mjs';
import { selectMatch } from '../selectors/selectMatch.mjs';
import { archiveRoute as defaultState } from '../config/defaultState.mjs';

// Local Functions
/**
 * Root reducer for content archive related actions.
 * @param {object}   state   state container
 * @param {string}   type
 * @param {*}        payload
 * @returns {object}
 */
function archiveReducer(state = {}, { type, payload } = {}) {
  const { isArchive } = selectMatch(state);
  // This reducer is only concerned with archive route matches.
  if (!isArchive) {
    return state;
  }

  const routeKey = selectRouteKey(state);

  let newState = { ...state };
  if (!newState.archive[routeKey]) {
    newState = set(`archive["${routeKey}"]`, defaultState, state);
  }

  switch (type) {
    case RECEIVE_PRIMARY_CONTENT: {
      const { page, refs, total = 0, totalPages = 0 } = payload.meta;

      const { hasLocationChanged = false } = { ...payload.appSpecificMeta };

      // Remove duplicate refs across different pages for the current routeKey
      const existingPages = Object.keys(newState.archive[routeKey].pages);
      let deduplicatedRefs;
      let clonePages;

      if (existingPages.length === 0 || hasLocationChanged) {
        deduplicatedRefs = refs;
        clonePages = {};
      } else {
        clonePages = { ...newState.archive[routeKey].pages };
        deduplicatedRefs = refs.filter(
          (payloadRef) =>
            !existingPages.some((pageNumber) => {
              const pageRefs = clonePages[pageNumber];
              return pageRefs.some((pageRef) => pageRef.id === payloadRef.id);
            }),
        );
      }

      const pagination = {
        total: +total,
        totalPages: +totalPages,
      };

      return compose(
        set(`archive["${routeKey}"].pages.${page}`, deduplicatedRefs),
        set(`archive["${routeKey}"].pages`, clonePages),
        set(`archive["${routeKey}"].pagination`, pagination),
      )(newState);
    }

    default:
      return newState;
  }
}

// Module Exports
export { archiveReducer };
