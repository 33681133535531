// External Imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavBarItem, NavBarSection } from '@lessonnine/design-system.lib';

// Internal Imports
import { selectNavigationText } from '../../../selectors/selectNavigationText.mjs';
import { selectLanguage } from '../../../selectors/selectMatch.mjs';
import { NavBarLink } from '../../Link/NavBarLink.jsx';
import { buildExploreLink } from './buildExploreLink.mjs';
import { createSelectTranslation } from '../../../selectors/createSelectTranslation.mjs';
import { DisclosureMenu } from '../../DisclosureMenu/DisclosureMenu.jsx';
import { DisclosureMenuLink } from '../../DisclosureMenu/DisclosureMenuLink.jsx';

// Component Definition
function DesktopNavExploreBase({ getTranslation, language, navigationTextObj }) {
  const buildLanguageLink = (languages) =>
    languages.map((link) => (
      <DisclosureMenuLink
        to={buildExploreLink({ langCode: language, link, urlPrefix: 'tags' })}
        key={link.label}
      >
        {link.label}
      </DisclosureMenuLink>
    ));

  return (
    // TODO: follow-up whether NavBarSection should be a section tag in the design-system given the naming
    <section aria-label={getTranslation('categories', 'Explore')}>
      <NavBarSection direction="inline">
        {navigationTextObj.categories && navigationTextObj.categories.length > 0
          ? navigationTextObj.categories.map((link) => (
              <NavBarItem key={link.label}>
                <NavBarLink
                  to={buildExploreLink({
                    langCode: language,
                    link,
                    urlPrefix: 'category',
                  })}
                  link={link}
                  key={link.label}
                >
                  {link.label}
                </NavBarLink>
              </NavBarItem>
            ))
          : undefined}
        {navigationTextObj.aboutLanguages && navigationTextObj.aboutLanguages.length > 0 ? (
          <DisclosureMenu
            label={getTranslation('languages', 'Languages')}
            items={buildLanguageLink(navigationTextObj.aboutLanguages)}
            navLabelId={{ button: true }}
          />
        ) : undefined}
      </NavBarSection>
    </section>
  );
}

DesktopNavExploreBase.propTypes = {
  getTranslation: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- fix when migrating to TS
  navigationTextObj: PropTypes.object.isRequired,
};

const mapStateToProperties = (state) => ({
  getTranslation: createSelectTranslation(state),
  language: selectLanguage(state),
  navigationTextObj: selectNavigationText(state),
});

const withRedux = connect(mapStateToProperties);

const DesktopNavExplore = withRedux(DesktopNavExploreBase);

// Module Exports
export { DesktopNavExplore };
