// Internal Imports
import { languageCodesArray } from './types/language.mjs';
import { pathTypes } from './types/path.mjs';

// Local Variables
const globalParentPath = pathTypes.LANGUAGE_EDITION;

const hostnameRegex = /\.(babbel\.(cn|com))$/;

// A few notes on the path exposed here as they will go through a few transformations:
// First, those paths will go through a lodash.template function to replace entities with <%= %> in the getPathMap selector
// Second, all paths will be prefixed with the LANGUAGE_EDITION path
// Finally, those paths will be used by path-to-regexp and react-router
const pathRegex = {
  [pathTypes.LANGUAGE_EDITION]: `/:language(${languageCodesArray.join('|')})/magazine`,
  [pathTypes.HOME]: '/',
  [pathTypes.CONTENT_ITEM_SINGLE]: '/:slug',
  [pathTypes.CATEGORY_ARCHIVE]: '/category/:slug/:contentType(<%= contentTypes %>)?',
  [pathTypes.TERM_ARCHIVE]: '/tags/:slug/:contentType(<%= contentTypes %>)?',
  [pathTypes.AUTHOR_ARCHIVE]: '/contributors/:slug',
  [pathTypes.SEARCH_ARCHIVE]: '/search/:page([1-9]\\d*)?',
  [pathTypes.CATCH_ALL]: '*',
};

// Module Exports
export { globalParentPath, hostnameRegex, pathRegex };
