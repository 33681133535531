// Local Functions
/**
 * Finds <img> tags, adds lazyload class and swaps attributes
 * for lazyloading with lazysizes npm package
 *
 * @param {String} content  Stringified html containing <img> tags
 * @return {String}
 */
function updateImagesForLazyloading(content) {
  const imgElements = content.match(/<img[^>]*\ssrc="[^"]+"[^>]*>/g);
  if (imgElements === null) {
    return content;
  }

  const imgElementsModified = [];
  let updatedContent = content;

  for (const imgElement of imgElements) {
    const imgElementWithLazyloadClass = imgElement.replaceAll(
      /class="[^"]+/g,
      (classes) => `${classes} lazyload`,
    );
    const attributesToReplace = {
      sizes: 'data-sizes',
      src: 'data-src', // 'srcset' attribute will also be replaced with 'data-srcset' as it has the same 'src' root
    };
    const imgElementWithReplacedAttributes = imgElementWithLazyloadClass.replaceAll(
      /src|sizes/gi,
      (matched) => attributesToReplace[matched],
    );
    imgElementsModified.push(imgElementWithReplacedAttributes);
  }

  for (const [index, imgElement] of imgElements.entries()) {
    updatedContent = updatedContent.replace(imgElement, imgElementsModified[index]);
  }

  return updatedContent;
}

// Module Exports
export { updateImagesForLazyloading };
