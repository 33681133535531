// External Imports
import get from 'lodash/fp/get.js';
import set from 'lodash/fp/set.js';
import { createSelector } from 'reselect';
import { compile } from 'path-to-regexp';

// Internal Imports
import { selectActivePagination } from './selectActivePagination.mjs';
import { selectContentArchiveItems } from './selectContentArchive.mjs';
import { selectPathMap } from './selectPathMap.mjs';
import { selectMatch } from './selectMatch.mjs';

// Local Functions
const selectLandingPageItem = createSelector(
  [get('landingPage'), get('contentItem.landing-page.byId')],
  (landingPageId, landingPagesById) =>
    landingPagesById ? landingPagesById[landingPageId] : undefined,
);

/**
 * Selector that will return a landing page entity
 *
 * @param {string} type landing page type
 * @return {function}   selector function
 */
const selectLandingPage = createSelector(
  [
    selectLandingPageItem,
    selectActivePagination,
    selectPathMap,
    selectContentArchiveItems,
    selectMatch,
  ],
  (landingPage, pagination, pathMap, relatedItems, { pathType, params }) => {
    if (!landingPage) {
      return;
    }
    const landingPageToPath = compile(pathMap[pathType]);
    const { totalPages, currentPage } = pagination;

    // eslint-disable-next-line consistent-return -- optional return
    return {
      item: set('path', landingPageToPath({ ...params }), landingPage),
      pagination: set(
        'hasMore',
        totalPages ? totalPages > 1 && currentPage < totalPages : false,
        pagination,
      ),
      relatedItems,
    };
  },
);

// Module Exports
export { selectLandingPage };
