// External Imports
import { createSelector } from 'reselect';
import get from 'lodash/fp/get.js';

// Internal Imports
import { TERM_ARCHIVE, CATEGORY_ARCHIVE } from '../config/types/path.mjs';
import { selectArchivedPagination } from './selectArchivedPagination.mjs';
import { selectMatch } from './selectMatch.mjs';

// Local Variables
const defaultItemsPerPage = 9;
const itemsPerPageOverrides = {
  [CATEGORY_ARCHIVE]: 14,
  [TERM_ARCHIVE]: 14,
};

// Local Functions
const selectActivePagination = createSelector(
  [selectMatch, get('active.pagination'), selectArchivedPagination],
  ({ pathType }, pagination, archivedPagination) => {
    const perPage = itemsPerPageOverrides[pathType] || defaultItemsPerPage;
    return {
      ...archivedPagination,
      ...pagination,
      perPage,
    };
  },
);

// Module Exports
export { selectActivePagination };
