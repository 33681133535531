// External Imports
import React from 'react';
import { Helmet } from 'react-helmet';
import { useToggleMode } from '@lessonnine/design-system.lib';

// Component Definition
function TwitterEmbedConfiguration() {
  const { currentMode } = useToggleMode();
  if (currentMode !== 'dark') {
    // eslint-disable-next-line unicorn/no-null -- React expects null return
    return null;
  }
  return (
    <Helmet>
      <meta name="twitter:widgets:theme" content="dark" />
    </Helmet>
  );
}

// Module Exports
export { TwitterEmbedConfiguration };
