// External Imports
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { stringify } from 'query-string';
import omit from 'lodash/fp/omit';
import PropTypes from 'prop-types';
import React from 'react';
import set from 'lodash/fp/set';

// Internal Imports
import { actionRequestNextPage } from '../../actions/index.mjs';
import { ARCHIVE } from '../../config/types/content/component.mjs';
import { CONTENT_ARCHIVE } from '../../config/types/prop.mjs';
import { createSelectComponentByComponentType } from '../../selectors/createSelectComponent.mjs';
import { selectContentArchive } from '../../selectors/selectContentArchive.mjs';
import { selectEdition } from '../../selectors/selectEdition.mjs';
import { selectMatch } from '../../selectors/selectMatch.mjs';
import { options } from '../../config/reactRedux.mjs';
import { WithLoader } from '../Hoc/WithLoader/WithLoader.jsx';
import { selectLoadingContent, selectLoadingMore } from '../../selectors/selectLoading.mjs';

// Component Definition
function ContentArchive(props) {
  const { component: Archive } = props;
  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- props are shared by all Archive components, so spreading simplifies the code */}
      <Archive {...omit(['component'], props)} />
    </div>
  );
}

ContentArchive.propTypes = {
  ...CONTENT_ARCHIVE,
  component: PropTypes.elementType.isRequired,
};

const mapStateToProps = (state) => {
  const contentArchive = selectContentArchive(state);
  const selectComponent = createSelectComponentByComponentType(contentArchive.contentType, ARCHIVE);
  const component = selectComponent(state);
  const match = selectMatch(state);

  return {
    ...contentArchive,
    component,
    currentEdition: selectEdition(state),
    loading: selectLoadingContent(state) || !component,
    loadingMore: selectLoadingMore(state),
    match,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createOnChangeFilter: (url, query) => (changeEvent) => {
    changeEvent.preventDefault();

    const { name, value } = changeEvent.target;
    const newQuery = stringify(set(name, value || undefined, query), { arrayFormat: 'bracket' });

    dispatch(push(`${url}?${newQuery}`));
  },
  onLoadMore: (clickEvent) => {
    clickEvent.preventDefault();
    dispatch(actionRequestNextPage());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    match: { url, query },
  } = stateProps;
  const { createOnChangeFilter } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onChangeFilter: createOnChangeFilter(url, query),
  };
};

const withRedux = connect(mapStateToProps, mapDispatchToProps, mergeProps, options);
const ContentArchiveHoc = withRedux(WithLoader(ContentArchive));

// Module Exports
export { ContentArchiveHoc as ContentArchive };
