// External Imports
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

// Internal Imports
import { createSelectTranslation } from '../../selectors/createSelectTranslation.mjs';
import { options } from '../../config/reactRedux.mjs';

// Component Definition
function I18n({ getTranslation, field, defaultText, isUpperCase = false }) {
  const translationText = isUpperCase
    ? getTranslation(field, defaultText).toUpperCase()
    : getTranslation(field, defaultText);
  return <span>{translationText}</span>;
}

I18n.propTypes = {
  defaultText: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  getTranslation: PropTypes.func.isRequired,
  isUpperCase: PropTypes.bool,
};

I18n.defaultProps = {
  isUpperCase: false,
};

const mapStateToProps = (state) => ({
  getTranslation: createSelectTranslation(state),
});

const withRedux = connect(mapStateToProps, undefined, undefined, options);
const I18nHoc = withRedux(I18n);

// Module Exports
export { I18nHoc as I18n };
