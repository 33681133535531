// External Imports
import { connect } from 'react-redux';
import { styled, pxToRem, Stack, Text, Button } from '@lessonnine/design-system.lib';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Internal Imports
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { getBabbelHomepageUrl } from '../../utils/url.mjs';
import { selectEdition } from '../../selectors/selectEdition.mjs';
import { selectPageTrackingData404 } from '../../selectors/selectPageTrackingData.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { LANGUAGES } from '../../config/types/prop.mjs';
import { options } from '../../config/reactRedux.mjs';
import { trackPageEvent } from '../../utils/tracking.mjs';

const FourOhFourNumber = styled.span`
  color: ${({ theme }) => theme.color.surface.foreground.tertiary};
  font-size: ${pxToRem(84)};
  font-weight: 600;

  @media ${breakpoints.desktop.min} {
    font-size: ${pxToRem(120)};
    margin-top: ${pxToRem(16)};
  }
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.color.surface.container.separator};
  height: ${pxToRem(1)};
  margin: ${pxToRem(24)} 0;
  width: 80%;

  @media ${breakpoints.desktop.min} {
    width: 70%;
  }
`;

function NotFound({ currentEdition, pageTrackingData404 }) {
  const babbelHomepageUrl = getBabbelHomepageUrl(currentEdition);
  const { isLoading, data } = pageTrackingData404;
  useEffect(() => {
    if (!isLoading) {
      trackPageEvent({ name: 'magazine_page:shown', ...data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- the data object is built in selector getPageTrackingData404(), so instead rely on string page_path which should remain consistent across renders
  }, [data.page_path, isLoading]);

  return (
    <Stack padding={[50, 0, 50, 0]}>
      <Stack alignItems="center" align="center">
        <FourOhFourNumber>404</FourOhFourNumber>
        <Divider />
      </Stack>
      <Stack alignItems="center" align="center" gap={32}>
        <Text fontWeight="700">
          <I18n field="no-page-found2" defaultText="Looks like this page doesn't exist..." />
        </Text>
        <Button href={babbelHomepageUrl} as="a">
          <I18n field="no-page-found3" defaultText="Go to Babbel Homepage" />
        </Button>
      </Stack>
    </Stack>
  );
}

NotFound.propTypes = {
  currentEdition: LANGUAGES.isRequired,
  pageTrackingData404: PropTypes.shape({
    data: PropTypes.shape({
      page_path: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
  }).isRequired,
  pathMatch: PropTypes.shape({
    query: PropTypes.object, // eslint-disable-line react/forbid-prop-types -- object of key/value pairs
    url: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  currentEdition: selectEdition(state),
  pageTrackingData404: selectPageTrackingData404(state),
});

const withRedux = connect(mapStateToProps, undefined, undefined, options);
const NotFoundHoc = withRedux(NotFound);

// Module Exports
export { NotFoundHoc as NotFound };
