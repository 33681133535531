// External Imports
import { LOCATION_CHANGE } from 'connected-react-router';

// Internal Imports
import { featureFlags } from '../config/featureFlags.mjs';

// Local Functions
/**
 * State container reducer for feature flags.
 * @param {object}   state   state container
 * @param {string}   type
 * @param {*}        payload
 * @returns {object}
 */
function featureFlagReducer(state = {}, { type, payload } = {}) {
  switch (type) {
    case LOCATION_CHANGE: {
      const {
        location: { search },
      } = payload;

      const activeFlags = {};
      for (const featureFlag of Object.values(featureFlags)) {
        if (search.includes(`${featureFlag}=true`)) {
          activeFlags[featureFlag] = true;
        }
      }

      return activeFlags;
    }

    default:
      return state;
  }
}

// Module Exports
export { featureFlagReducer };
