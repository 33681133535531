// External Imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pxToRem, Stack, styled } from '@lessonnine/design-system.lib';

// Internal Imports
import { selectPageTrackingData } from '../../../selectors/selectPageTrackingData.mjs';
import { DsLink } from '../../Link/DsLink.jsx';
import { FlagIcon } from './FlagIcon.jsx';
import { makeFlagLinkOnClick } from './makeFlagLinkOnClick.mjs';
import { FlagItemPropTypes } from './flagItemPropTypes.mjs';

const LinkContent = styled(DsLink)`
  color: ${({ theme }) => theme.color.interactive.tertiary.foreground};
  font-size: ${pxToRem(12)};
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    transform: scale(1.05);
  }
`;

// Components Definition
function FlagItemBigBase({ flagCode, flagType, text, pageTrackingData, url }) {
  const { data: trackingData } = pageTrackingData;
  return (
    <LinkContent to={url} onClick={makeFlagLinkOnClick({ flagType, trackingData, url })}>
      <Stack alignItems="center" gap={8}>
        <FlagIcon flagCode={flagCode} size={68} />
        {text.toUpperCase()}
      </Stack>
    </LinkContent>
  );
}

FlagItemBigBase.propTypes = {
  ...FlagItemPropTypes,
  // eslint-disable-next-line react/forbid-prop-types -- will be improved with TS in the future
  pageTrackingData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pageTrackingData: selectPageTrackingData(state),
});

const withRedux = connect(mapStateToProps);

const FlagItemBig = withRedux(FlagItemBigBase);
FlagItemBig.propTypes = FlagItemPropTypes;

// Module Exports
export { FlagItemBig };
