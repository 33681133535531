// External Imports
import { createContext } from 'react';

// Local Variables
const ToggleContainerContext = createContext({
  close: () => {},
  isOpened: false,
});

const ToggleContainerConsumer = ToggleContainerContext.Consumer;

// Module Exports
export { ToggleContainerConsumer, ToggleContainerContext };
