// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { selectContentType } from './selectMatch.mjs';
import { createSelectContentItemBySlug } from './createSelectContentItem.mjs';

// Local Functions
const selectActiveContentItemBySlug = createSelector([selectContentType], (contentType) =>
  createSelectContentItemBySlug(contentType),
);

const selectActiveContentItem = createSelector(
  [
    selectActiveContentItemBySlug,
    // This sucks for memoization, but getItem for now is made in a way that doesn't get better than that
    (state) => state,
  ],
  (getItem, state) => getItem(state),
);

// Module Exports
export { selectActiveContentItem };
