// External Imports
import get from 'lodash/fp/get.js';
import { createSelector } from 'reselect';

// Internal Imports
import { selectEdition } from './selectEdition.mjs';

// Local Functions
const createSelectTranslation = createSelector(
  [selectEdition, get('translations')],
  (edition, translations) => (field, defaultText) =>
    get(`${field}.${edition}`, translations) || defaultText,
);

// Module Exports
export { createSelectTranslation };
