// External Imports
import { styled } from '@lessonnine/design-system.lib';
import PropTypes from 'prop-types';
import React from 'react';

// Local Variables
const Iframe16by9 = styled.iframe`
  aspect-ratio: 16 / 9;
  width: 100%;
`;

// Component Definition
function FeaturedVideo({ title, url }) {
  return (
    <Iframe16by9 allow="picture-in-picture; web-share" frameborder="0" src={url} title={title} />
  );
}

FeaturedVideo.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

// Module Exports
export { FeaturedVideo };
