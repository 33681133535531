// External Imports
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';
import PropTypes from 'prop-types';
import React from 'react';

// Internal Imports
import { actionRequestNextPage } from '../../actions/index.mjs';
import { CampaignsTracker } from '../Tracking/CampaignsTracker.jsx';
import { createSelectComponentByContentItem } from '../../selectors/createSelectComponent.mjs';
import { createSelectTranslation } from '../../selectors/createSelectTranslation.mjs';
import { selectLandingPage } from '../../selectors/selectLandingPage.mjs';
import { getLandingPageShortcode } from '../../utils/metadata.mjs';
import { selectMatch } from '../../selectors/selectMatch.mjs';
import { HeaderLinks } from '../HeaderLinks/HeaderLinks.jsx';
import { LANDING_PAGE } from '../../config/types/prop.mjs';
import { Meta } from '../Meta/Meta.jsx';
import { options } from '../../config/reactRedux.mjs';
import { SINGLE } from '../../config/types/content/component.mjs';
import { WithLoader } from '../Hoc/WithLoader/WithLoader.jsx';
import { selectLoadingContent, selectLoadingMore } from '../../selectors/selectLoading.mjs';

// Component Definition
function LandingPage(props) {
  const { component: LandingPageComponent, item, magazineEditionLanguage } = props;
  const {
    metadata: {
      link,
      languageHreflang,
      // headerMeta, // We need to pass it eventually through the API as we do for the Article Page
    },
  } = item;

  const { getTranslation } = props;

  const pageShortcode = getLandingPageShortcode(magazineEditionLanguage);

  const translatedTitle = getTranslation('meta-title', 'Babbel Magazine');
  const translatedDescription = getTranslation(
    'meta-description',
    'Real-life conversations start with stories that inspire.',
  );
  const metaDescription = { content: translatedDescription, name: 'description' };
  const metaTags = [metaDescription];

  return (
    <div>
      <Meta metaTags={metaTags} title={translatedTitle} />
      <CampaignsTracker shortlinkCode={pageShortcode} />
      <HeaderLinks canonicalUrl={link} languageHreflang={languageHreflang} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- allow spread */}
      <LandingPageComponent {...omit(['component'], props)} />
    </div>
  );
}

LandingPage.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- use TypeScript type eventually
  ...LANDING_PAGE,
};

const mapStateToProps = (state) => {
  const landingPage = selectLandingPage(state);
  const item = get('item', landingPage);
  const selectComponent = createSelectComponentByContentItem(item, SINGLE);
  const getTranslation = createSelectTranslation(state);
  const component = selectComponent(state);
  const magazineEditionLanguage = get('params.language', selectMatch(state));

  return {
    ...landingPage,
    component,
    getTranslation,
    loading: selectLoadingContent(state) || !component,
    loadingMore: selectLoadingMore(state),
    magazineEditionLanguage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoadMore: (event) => {
    event.preventDefault();
    dispatch(actionRequestNextPage());
  },
});

const withRedux = connect(mapStateToProps, mapDispatchToProps, undefined, options);
const LandingPageHoc = withRedux(WithLoader(LandingPage));

// Module Exports
export { LandingPageHoc as LandingPage };
