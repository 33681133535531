// External Imports
import get from 'lodash/fp/get.js';
import isFunction from 'lodash/isFunction.js';
import isObject from 'lodash/isObject.js';

// Internal Imports
import {
  componentMap,
  componentOverride,
  typeMap,
  typeOverride,
} from '../config/types/content/component.mjs';
import { selectMatch } from './selectMatch.mjs';

// Local Functions
const selectComponent = (state, contentType, componentType, contentItem = {}) => {
  const match = selectMatch(state);
  const path = `${contentType}.${componentType}`;
  const getComponentOverride = get(path, componentOverride);
  let result = get(path, componentMap);

  // If no specific content type available, fallback to component type defaults.
  if (!result && (match.isMultipleContentType || !getComponentOverride)) {
    result = get(componentType, typeMap);
  }

  // Custom override function to resolve component.
  if (getComponentOverride) {
    result = getComponentOverride(match, contentItem);
  }

  // Components differ by path type.
  // TODO: this is not great code because it requires React's internals
  // I think we should find a better way to do whatever this does
  const hasPathTypeOverrides =
    isObject(result) && !result.render && !isFunction(result) && !result.$$typeof;
  const getTypeOverride = get(`${componentType}.${match.pathType}`, typeOverride);
  if (getTypeOverride) {
    result = getTypeOverride(match);
  } else if (hasPathTypeOverrides) {
    result = result[match.pathType];
  }

  return result;
};

const createSelectComponentByComponentType = (contentType, componentType) => (state) =>
  selectComponent(state, contentType, componentType);

const createSelectComponentByContentItem = (contentItem, componentType) => {
  if (!contentItem) {
    // It seems like during loading, this function can be called with no content item, returning an empty function in this case
    return () => {};
  }

  const contentType =
    contentItem.type || (contentItem.metadata ? contentItem.metadata.type : false);

  return (state) => selectComponent(state, contentType, componentType, contentItem);
};

// Module Exports
export { createSelectComponentByComponentType, createSelectComponentByContentItem };
