// External Imports
import camelCase from 'lodash/fp/camelCase.js';
import compose from 'lodash/fp/compose.js';
import head from 'lodash/fp/head.js';
import isObject from 'lodash/isObject.js';
import curry from 'lodash/fp/curry.js';
import mapKeys from 'lodash/fp/mapKeys.js';
import mapValues from 'lodash/fp/mapValues.js';
import mergeWith from 'lodash/fp/mergeWith.js';
import uniqBy from 'lodash/fp/uniqBy.js';

// Local Functions
/**
 * Recursively map keys within a nested object of objects or object of collections to camelCase.
 * @param {object} value
 * @return {object}
 */
function camelCaseKeysDeep(value) {
  return compose(mapKeys(camelCase), mapValues(camelCaseValue))(value);
}

function isCollection(value) {
  return Array.isArray(value) && isObject(head(value));
}

function camelCaseValue(value) {
  if (isCollection(value)) {
    return value.map((item) => camelCaseKeysDeep(item));
  }
  if (Array.isArray(value) || typeof value === 'function') {
    return value;
  }
  if (isObject(value)) {
    return camelCaseKeysDeep(value);
  }

  return value;
}

/**
 * Enumerate over an object, and return an array of mapped values generated by
 * running fn on each property value.
 * @param  {Function} callback callback function to operate, receives value as first parameter and key as second
 * @param  {object} object  Object to map over
 * @return {array}
 */
const mapToValues = curry((callback, object) =>
  Object.entries(object).map(([key, value]) => callback(value, key)),
);

const mergeObjectKeysById = mergeWith((targetObject, objectToMerge) =>
  Array.isArray(targetObject) ? uniqBy('id', [...targetObject, ...objectToMerge]) : undefined,
);

// Module Exports
export { camelCaseKeysDeep, mapToValues, mergeObjectKeysById };
