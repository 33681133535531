// External Imports
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Internal Imports
import { ContentEmbedContext } from '../../context/contentEmbed.mjs';
import { findKnownEmbed, isReloadableEmbed } from '../../utils/embeds.mjs';

// Component Definition - for more documentation on this see docs/article-content-embeds.md
function ContentEmbedProvider({ children }) {
  const [loadedReloadableScripts, setLoadedReloadableScripts] = useState([]);
  const [currentScripts, setCurrentScripts] = useState([]);
  const previousScriptsRef = useRef([]);

  const addScriptEmbeds = useCallback(
    (scriptSrcs) => {
      const knownReloadableEmbedsToAdd = scriptSrcs.filter(
        (url) => !loadedReloadableScripts.includes(url) && isReloadableEmbed(findKnownEmbed(url)),
      );
      if (knownReloadableEmbedsToAdd.length > 0) {
        setLoadedReloadableScripts((scripts) => [...scripts, ...knownReloadableEmbedsToAdd]);
      }
      setCurrentScripts((scripts) => [...new Set([...scripts, ...scriptSrcs])]);
    },
    [loadedReloadableScripts],
  );

  const removeScriptEmbeds = useCallback((scriptSrcs) => {
    setCurrentScripts((scripts) => scripts.filter((url) => !scriptSrcs.includes(url)));
  }, []);

  const providerValue = useMemo(
    () => ({ addScriptEmbeds, removeScriptEmbeds }),
    [addScriptEmbeds, removeScriptEmbeds],
  );

  useEffect(() => {
    const newScripts = currentScripts.filter(
      (script) => !previousScriptsRef.current.includes(script),
    );
    for (const scriptSource of newScripts) {
      if (loadedReloadableScripts.includes(scriptSource)) {
        const knownEmbed = findKnownEmbed(scriptSource);
        if (knownEmbed.isReadyForManualProcessing()) {
          knownEmbed.processPageEmbeds();
        }
      }
    }
    const removedScripts = previousScriptsRef.current.filter(
      (script) => !currentScripts.includes(script),
    );
    for (const scriptSource of removedScripts) {
      const embed = findKnownEmbed(scriptSource);
      embed?.onUnload?.();
    }
    previousScriptsRef.current = currentScripts;
  }, [loadedReloadableScripts, currentScripts]);

  const allScripts = [...new Set([...loadedReloadableScripts, ...currentScripts])];

  return (
    <>
      <Helmet>
        {allScripts.map((url) => (
          <script defer key={url} src={url} type="text/javascript" />
        ))}
      </Helmet>
      {currentScripts.map((script) => {
        // eslint-disable-next-line unicorn/no-null -- React expects null return
        const { configurationComponent: Component = () => null } = findKnownEmbed(script) ?? {};
        return <Component key={script} />;
      })}
      <ContentEmbedContext.Provider value={providerValue}>{children}</ContentEmbedContext.Provider>
    </>
  );
}

ContentEmbedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Module Exports
export { ContentEmbedProvider };
