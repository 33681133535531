// External Imports
import sanitizeHtml from 'sanitize-html';

// Internal Imports
import { plainText } from '../config/sanitizeHtml.mjs';

// Local Functions
function setInnerHtml(content) {
  const html = content ? sanitizeHtml(content, plainText) : content;
  return { __html: html };
}

// Module Exports
export { setInnerHtml };
