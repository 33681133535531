// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { englishAlternatives, ENGLISH } from '../config/types/language.mjs';
import { selectLocale } from './selectLocale.mjs';

// Local Functions
const getEditionFromLocale = (locale) =>
  // See docs/in-product-locale.md
  englishAlternatives.includes(locale) ? ENGLISH : locale;

const selectEdition = createSelector(selectLocale, getEditionFromLocale);

// Module Exports
export { getEditionFromLocale, selectEdition };
