// Internal Imports
import { ENGLISH, ENGLISH_USA } from '../config/types/language.mjs';
import { isNode } from './environment.mjs';

// Local Functions
const checkUsEnglish = (fallbackLanguage) => {
  if (isNode()) {
    return fallbackLanguage;
  }

  switch (fallbackLanguage) {
    case ENGLISH:
    case ENGLISH_USA:
      return window.navigator.language === 'en-US' ? ENGLISH_USA : ENGLISH;

    default:
      return fallbackLanguage;
  }
};

// Module Exports
export { checkUsEnglish };
