// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { selectActiveContentItem } from './selectActiveContentItem.js';

// Local Functions
const selectLoading = (state) => state.active.loading;
const selectLoadingContent = createSelector(selectLoading, ({ content }) => content);
const selectLoadingMore = createSelector(selectLoading, ({ more }) => more);

const selectIsActiveContentItemLoading = createSelector(
  [selectActiveContentItem, selectLoadingContent],
  (activeContentItem, loading) => (loading && !activeContentItem) || !activeContentItem,
);

const selectIsActiveContentItemLoadingRelated = createSelector(
  [selectActiveContentItem, selectLoadingContent],
  (activeContentItem, activeLoading) => activeContentItem && activeLoading,
);

// Module Exports
export {
  selectLoadingContent,
  selectLoadingMore,
  selectIsActiveContentItemLoading,
  selectIsActiveContentItemLoadingRelated,
};
