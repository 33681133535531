// External Imports
import { createSelector } from 'reselect';

// Local Functions
const selectSiteOptionsError = (state) => state.siteOptionsError.error;

const selectContentFetchingError = (state) => state.active.error;

const selectHasWordPressFetchError = createSelector(
  selectSiteOptionsError,
  selectContentFetchingError,
  (siteOptionsError, contentFetchingError) =>
    Boolean(siteOptionsError) || Boolean(contentFetchingError),
);

// Module Exports
export { selectHasWordPressFetchError };
