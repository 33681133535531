// External Imports
import { Inline, pxToRem, Stack, styled, Text } from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';
import PropTypes from 'prop-types';
import React from 'react';

// Internal Imports
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { LANGUAGE, LANGUAGES } from '../../config/types/prop.mjs';
import { buildCTALink } from '../../utils/url.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { SpacedItem } from '../SpacedItem/SpacedItem.jsx';
import { FlagItemSmall } from './FlagLanguageItem/FlagItemSmall.jsx';
import { conditionalDisplay } from '../../utils/conditionalDisplayStyles.mjs';
import { numberOfVisibleFlags } from './numberOfVisibleFlags.mjs';

// Local Variables
const containerPadding = 12;

const Wrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.color.surface.layer.background};
  border-bottom: 1px solid ${({ theme }) => theme.color.surface.container.separator};
  overflow: hidden;
  position: relative;

  /* stylelint-disable plugin/no-low-performance-animation-properties -- No alternative for color properties, the alternative opacity for visibility does not remove elements for Accessibility */
  transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out,
    transform 0.2s ease-in-out, visibility 0.1s ease-in-out;
  /* stylelint-enable plugin/no-low-performance-animation-properties */

  @media ${breakpoints.tabletPortrait.min} {
    left: 0;
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    visibility: hidden;
    width: 100%;
    z-index: 3;

    ${({ isSticky }) =>
      isSticky
        ? `
          transform: translateY(0);
          visibility: visible;
          box-shadow: 0 2px 10px ${tokens.boxShadow.bottomNavigation};
          `
        : ''}
  }
`;

const Container = styled(Stack)`
  margin: 0 auto;

  /* The min here is useful for the ScrollContainer to be bound to the right size */
  max-width: min(100%, 1400px);
  padding: ${pxToRem(containerPadding)};
  padding-right: 0;
  position: relative;
`;

// TODO: using a div here for now given Layout components don't forward ref
const ScrollContainer = styled.div`
  display: flex;

  /* Negative margin left allows the container to extend to the screen border on this side */
  margin-left: -${pxToRem(containerPadding)};
  max-width: calc(100% + ${pxToRem(containerPadding)});
  overflow-x: auto;
  padding: 0 ${pxToRem(containerPadding)};

  @media ${breakpoints.tabletPortrait.max} {
    /* Hide scrollbar on mobile */
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ShadowSide = styled.div`
  height: calc(100% - ${pxToRem(1)});
  position: absolute;
  width: ${pxToRem(containerPadding + 8)};
  z-index: 1;

  @media ${breakpoints.tabletLandscape.min} {
    display: none;
  }
`;

const ShadowLeft = styled(ShadowSide)`
  background: linear-gradient(
    to left,
    transparent,
    ${({ theme }) => theme.color.surface.layer.background}
  );
  left: 0;
`;

const ShadowRight = styled(ShadowSide)`
  background: linear-gradient(
    to right,
    transparent,
    ${({ theme }) => theme.color.surface.layer.background}
  );
  right: 0;
`;

const SpacedItemWithConditionalDisplay = styled(SpacedItem)`
  ${conditionalDisplay}
`;

// Component Definition
function LanguagePickerCondensed({ currentEdition, getTranslation, isSticky, languages }) {
  const goLink = buildCTALink(currentEdition);
  const seeMoreText = getTranslation('masthead-more', 'More...');

  return (
    <Wrapper isSticky={isSticky}>
      <ShadowLeft />
      <ShadowRight />
      <Container gap={12}>
        <Inline alignItems="center">
          <Text fontWeight={500} fontSize={14}>
            <I18n field="pick-a-language-to-learn" defaultText="Pick a Language to Learn" />
          </Text>
        </Inline>
        <ScrollContainer>
          <Inline as="ul">
            {languages.map((item, index) => (
              <SpacedItemWithConditionalDisplay
                as="li"
                key={`${item.text}${item.url}`}
                space={16}
                // eslint-disable-next-line react/jsx-props-no-spreading -- conditional prop
                {...(index < numberOfVisibleFlags ? {} : { hideAbove: 'tabletLandscape' })}
              >
                <FlagItemSmall
                  flagCode={item.flag}
                  flagType="flag_button"
                  text={item.text}
                  url={item.url}
                />
              </SpacedItemWithConditionalDisplay>
            ))}
            {languages.length > numberOfVisibleFlags && (
              <SpacedItemWithConditionalDisplay as="li" space={16} hideUpTo="tabletLandscape">
                <FlagItemSmall
                  flagCode="plus"
                  flagType="flag_button"
                  url={goLink}
                  text={seeMoreText}
                />
              </SpacedItemWithConditionalDisplay>
            )}
          </Inline>
        </ScrollContainer>
      </Container>
    </Wrapper>
  );
}

LanguagePickerCondensed.propTypes = {
  currentEdition: LANGUAGES.isRequired,
  getTranslation: PropTypes.func.isRequired,
  isSticky: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.shape(LANGUAGE)).isRequired,
};

LanguagePickerCondensed.defaultProps = {
  isSticky: false,
};

// Module Exports
export { LanguagePickerCondensed };
