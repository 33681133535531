// External Imports
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { Inline, Stack, styled, Text } from '@lessonnine/design-system.lib';

// Internal Imports
import { COAUTHOR_SHAPE, CONTENT_ITEM, LANGUAGES } from '../../config/types/prop.mjs';
import { DsLink } from '../Link/DsLink.jsx';
import { selectRemoveLinks } from '../../selectors/selectRemoveLinks.mjs';
import { Thumbnail } from '../Thumbnail/Thumbnail.jsx';
import { RichText } from '../RichText/RichText.jsx';
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { ConditionalDisplay } from '../ConditionalDisplay/ConditionalDisplay.jsx';

// Local Variables
// TODO [redesign]: replace by Layout components responsive props for the alignment (alignItems={{ medium: 'center', large: 'leading' }}) once we migrate all breakpoints to the DS one
const Container = styled(Inline)`
  align-items: center;

  @media ${breakpoints.tabletPortrait.min} {
    align-items: flex-start;
  }
`;

// Component Definition
function AuthorOverviewBase({
  descriptionFiltered,
  displayName,
  avatarUrl,
  relativePath,
  isInProductMagazine,
}) {
  let isFirstParagraph = true;
  const firstParagraph = sanitizeHtml(descriptionFiltered, {
    allowedAttributes: false,
    allowedTags: false,
    exclusiveFilter: (frame) => {
      if (frame.tag === 'p' && isFirstParagraph) {
        isFirstParagraph = false;
        return false;
      }
      return frame.tag === 'p';
    },
    parseStyleAttributes: false,
  });
  return (
    <Stack as="section" gap={8}>
      {/* eslint-disable-next-line sort-keys -- easier to read sorted by size */}
      <Container gap={{ small: 16, medium: 32 }}>
        <div>
          <Thumbnail
            alt="Author Headshot"
            hideLinkFromAccessibility
            isLazyLoading
            originalSource={avatarUrl}
            position="authorOverview"
            wrapperStyle="authorOverview"
            // eslint-disable-next-line react/jsx-props-no-spreading -- spread is useful here for conditional props
            {...(isInProductMagazine ? {} : { imageLink: relativePath })}
          />
        </div>
        <Stack alignItems="leading" gap={16}>
          {isInProductMagazine ? (
            <Text fontSize="big" fontWeight={700}>
              {displayName}
            </Text>
          ) : (
            <DsLink underlined={false} to={relativePath}>
              <Text color="inherit" fontSize="big" fontWeight={700}>
                {displayName}
              </Text>
            </DsLink>
          )}
          <ConditionalDisplay hideUpTo="tabletPortrait">
            <RichText richText={firstParagraph} />
          </ConditionalDisplay>
        </Stack>
      </Container>
      <ConditionalDisplay hideAbove="tabletPortrait">
        <RichText richText={firstParagraph} />
      </ConditionalDisplay>
    </Stack>
  );
}

AuthorOverviewBase.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape(CONTENT_ITEM)).isRequired,
  currentEdition: LANGUAGES.isRequired,
  descriptionFiltered: PropTypes.string.isRequired,
  isInProductMagazine: PropTypes.bool.isRequired,
  ...COAUTHOR_SHAPE,
};

const mapStateToProps = (state, props) => {
  const { currentEdition, description, descriptions, isInProductMagazine } = props;
  const filterLinksIfNeeded = isInProductMagazine ? selectRemoveLinks(state) : (content) => content;
  const descriptionFiltered = filterLinksIfNeeded(descriptions[currentEdition] || description);
  return {
    descriptionFiltered,
  };
};

const withRedux = connect(mapStateToProps);
const AuthorOverview = withRedux(AuthorOverviewBase);

// Module Exports
export { AuthorOverview };
