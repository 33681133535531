// External Imports
import PropTypes from 'prop-types';

// Internal Imports
import { languageToFlagMap } from './languageToFlagMap.mjs';

// Local Variables
const FlagItemPropTypes = {
  flagCode: PropTypes.oneOf([...Object.keys(languageToFlagMap), 'plus']).isRequired,
  flagType: PropTypes.oneOf(['flag_button', 'masthead_button']).isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

// Module Exports
export { FlagItemPropTypes };
