import deepEqual from 'fast-deep-equal';

const options = {
  areMergedPropsEqual: deepEqual,
  areStatePropsEqual: deepEqual,
  pure: true,
};

// Module Exports
export { options };
