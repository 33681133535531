// External Imports
import { connect } from 'react-redux';
import React from 'react';
import {
  IconFacebookLogoFilled,
  IconTwitterLogoFilled,
  IconInstagramLogoFilled,
  IconLinkedinLogoFilled,
  IconYoutubeLogoFilled,
  Link,
  pxToRem,
  styled,
} from '@lessonnine/design-system.lib';

// Internal Imports
import { selectEdition } from '../../selectors/selectEdition.mjs';
import { getSocialLinks, getBabbelRSSUrl } from '../../utils/url.mjs';
import { LANGUAGES } from '../../config/types/prop.mjs';
import { options } from '../../config/reactRedux.mjs';
import { RssIcon } from './RssIcon.jsx';

const SocialLinksNav = styled.nav`
  display: flex;
`;

const SocialLinkList = styled.ul`
  display: flex;
`;

const SocialLinkItem = styled.li`
  margin-left: ${pxToRem(6)};
`;

const socialIconMapping = {
  facebook: <IconFacebookLogoFilled />,
  instagram: <IconInstagramLogoFilled />,
  linkedin: <IconLinkedinLogoFilled />,
  rss: <RssIcon />,
  twitter: <IconTwitterLogoFilled />,
  youtube: <IconYoutubeLogoFilled />,
};

function SocialLinks({ currentEdition }) {
  const links = {
    ...getSocialLinks(currentEdition),
    rss: 'rss',
  };

  return (
    // TODO: add translations for the aria label text
    <SocialLinksNav aria-label="Social Links">
      <SocialLinkList>
        {Object.keys(links).map((serviceName) => (
          <SocialLinkItem key={serviceName}>
            <Link
              aria-label={serviceName}
              href={serviceName === 'rss' ? getBabbelRSSUrl(currentEdition) : links[serviceName]}
            >
              {socialIconMapping[serviceName]}
            </Link>
          </SocialLinkItem>
        ))}
      </SocialLinkList>
    </SocialLinksNav>
  );
}

SocialLinks.propTypes = {
  currentEdition: LANGUAGES.isRequired,
};

const mapStateToProps = (state) => ({
  currentEdition: selectEdition(state),
});

const withRedux = connect(mapStateToProps, undefined, undefined, options);
const SocialLinksHoc = withRedux(SocialLinks);

// Module Exports
export { SocialLinksHoc as SocialLinks };
