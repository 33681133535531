// External Imports
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import sanitizeHtml from 'sanitize-html';
import {
  IconFacebookLogoFilled,
  IconLinkedinLogoFilled,
  IconTwitterLogoFilled,
  IconEnvelopeSimple,
  IconWhatsappFilled,
  IconPinterestFilled,
  pxToRem,
  styled,
} from '@lessonnine/design-system.lib';

// Internal Imports
import { actionArticleShared } from '../../actions/index.mjs';
import { getRuntimeConfiguration } from '../../utils/getRuntimeConfiguration.mjs';
import { LANGUAGES, SOCIAL_SHARE_SHAPE } from '../../config/types/prop.mjs';
import { options } from '../../config/reactRedux.mjs';
import { sendEventToGA } from '../../utils/googleAnalytics.mjs';
import { breakpoints } from '../../config/css/breakpoints.mjs';

// Local Variables
const FACEBOOK_APP_ID = getRuntimeConfiguration('FACEBOOK_APP_ID');
const shareServices = {
  email: {
    base: 'mailto:?',
    link: 'body',
    title: 'subject',
  },
  facebook: {
    base: `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=popup`,
    link: 'href',
  },
  linkedin: {
    base: 'https://www.linkedin.com/shareArticle?mini=true',
    description: 'summary',
    link: 'url',
    title: 'title',
  },
  pinterest: {
    base: 'https://www.pinterest.com/pin/create/bookmarklet/?',
    image: 'media',
    link: 'url',
    title: 'description',
  },
  twitter: {
    base: 'https://twitter.com/intent/tweet?',
    link: 'url',
    title: 'text',
  },
  whatsapp: {
    base: 'https://api.whatsapp.com/send?',
    titleAndLink: 'text',
  },
};

const socialIconMapping = {
  email: <IconEnvelopeSimple size="xlarge" />,
  facebook: <IconFacebookLogoFilled size="xlarge" />,
  linkedin: <IconLinkedinLogoFilled size="xlarge" />,
  pinterest: <IconPinterestFilled size="xlarge" />,
  twitter: <IconTwitterLogoFilled size="xlarge" />,
  whatsapp: <IconWhatsappFilled size="xlarge" />,
};

const socialIconLabels = {
  email: 'Share by Email',
  facebook: 'Share on Facebook',
  linkedin: 'Share on Linkedin',
  pinterest: 'Share on Pinterest',
  twitter: 'Share on Twitter',
  whatsapp: 'Share on Whatsapp',
};

// TODO [redesign]: after rebranding, let's replace this with a Layout component, but for now there is a disparity in the components' usage between development mode and SSR that always lead to one of the results being wrong depending on as="ul" or forwardedAs="ul", so it's simpler to create our own component
const Wrapper = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: inherit;
  gap: ${pxToRem(12)};
`;

const IconItem = styled.li`
  align-content: center;
`;

// Component Definition
class ShareButtonsBase extends Component {
  onClickShare = (clickEvent, service) => {
    const { onShare } = this.props;
    if (window.matchMedia(breakpoints.tabletLandscape.min).matches) {
      clickEvent.preventDefault();
      const windowWidth = 520;
      const windowHeight = 420;
      const left = (window.screen.width - windowWidth) / 2;
      const top = (window.screen.height - windowHeight) / 2;
      window.open(
        this.generateShareUrl(service),
        'DescriptiveWindowName',
        `width=${windowWidth},height=${windowHeight},top=${top},left=${left},resizable,scrollbars=yes,status=1`,
      );
    }

    onShare({ sharingPlatform: service });
    sendEventToGA(service, 'Share');
  };

  generateShareUrl = (service) => {
    const { currentEdition, shareProps } = this.props;
    const fragments = shareServices[service] || {};

    const urlParts = Object.keys(fragments).map((shareProp) => {
      const name = fragments[shareProp];
      let value = encodeURIComponent(shareProps[shareProp]);

      if (shareProp === 'link' || shareProp === 'titleAndLink') {
        const GtmParameters = `?utm_source=${service}&utm_medium=social&utm_campaign=${currentEdition}mag`;
        value += encodeURIComponent(GtmParameters);
      }

      return shareProp === 'base' ? name : `${name}=${sanitizeHtml(value)}`;
    });

    const base = urlParts[0];
    return base.endsWith('?') ? base + urlParts.slice(1).join('&') : urlParts.join('&');
  };

  render() {
    const { services } = this.props;

    return (
      <Wrapper>
        {services.map((service) => (
          <IconItem key={service}>
            <a
              rel="nofollow noopener noreferrer"
              href={this.generateShareUrl(service)}
              onClick={(clickEvent) => this.onClickShare(clickEvent, service)}
              target="_blank"
              aria-label={socialIconLabels[service]}
            >
              {socialIconMapping[service]}
            </a>
          </IconItem>
        ))}
      </Wrapper>
    );
  }
}

ShareButtonsBase.propTypes = {
  currentEdition: LANGUAGES,
  onShare: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(PropTypes.string),
  shareProps: SOCIAL_SHARE_SHAPE.isRequired,
};

ShareButtonsBase.defaultProps = {
  currentEdition: 'en',
  services: ['facebook', 'twitter', 'linkedin', 'whatsapp', 'pinterest', 'email'],
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onShare: actionArticleShared,
    },
    dispatch,
  );

const withRedux = connect(undefined, mapDispatchToProps, undefined, options);
const ShareButtons = withRedux(ShareButtonsBase);

// Module Exports
export { ShareButtons };
