// Internal Imports
import { ContentArchiveMultiple } from '../../../components/ContentArchive/Multiple/ContentArchiveMultiple.jsx';
import { SearchArchive } from '../../../components/ContentArchive/Search/SearchArchive.jsx';
import { ContentArchiveAuthor } from '../../../components/ContentArchive/Author/ContentArchiveAuthor.jsx';
import { DefaultContentItem } from '../../../components/ContentItem/Default/DefaultContentItem.jsx';
import { Homepage } from '../../../components/LandingPage/Homepage.jsx';
import { DefaultLandingPage } from '../../../components/LandingPage/DefaultLandingPage.jsx';
import {
  TERM_ARCHIVE,
  SEARCH_ARCHIVE,
  AUTHOR_ARCHIVE,
  CATEGORY_ARCHIVE,
  CONTENT_ITEM_SINGLE,
} from '../path.mjs';
import { HOMEPAGE } from '../landingPage.mjs';
import { POST, LANDING } from './index.mjs';

// Local Variables
const ARCHIVE = 'archive';
const SINGLE = 'single';

const typeOverride = {
  [ARCHIVE]: {
    [TERM_ARCHIVE]: () => ContentArchiveMultiple,
  },
};

const componentOverride = {
  [LANDING]: {
    [SINGLE]: (match, item) => {
      switch (item.metadata.landingPageType) {
        case HOMEPAGE:
          return Homepage;

        default:
          return DefaultLandingPage;
      }
    },
  },
};

const typeMap = {
  [ARCHIVE]: {
    [AUTHOR_ARCHIVE]: ContentArchiveAuthor,
    [CATEGORY_ARCHIVE]: ContentArchiveMultiple,
    [SEARCH_ARCHIVE]: SearchArchive,
    [TERM_ARCHIVE]: ContentArchiveMultiple,
  },
  [SINGLE]: {
    [CONTENT_ITEM_SINGLE]: DefaultContentItem,
  },
};

const componentMap = {
  [POST]: {},
};

// Module Exports
export { ARCHIVE, componentMap, componentOverride, SINGLE, typeMap, typeOverride };
