// External Imports
import get from 'lodash/fp/get.js';
import set from 'lodash/fp/set.js';

// Internal Imports
import { contentRelationMap } from '../config/types/content/relations.mjs';
import { createSelectContentItem, selectItemById } from './createSelectContentItem.mjs';
import { selectMatch } from './selectMatch.mjs';

// Local Functions
const selectRelatedContentItemsByItem = (state, item) =>
  selectRelatedContentItems(state, contentRelationMap, {
    metadata: get('metadata', item),
    type: get('metadata.type', item),
  });

const selectPostRelationsMap = (relationMap, match, data) => {
  const pathType = get('pathType', match);
  const contentType = data.type;

  return relationMap[contentType] || relationMap[pathType];
};

function selectRelatedContentItems(state, relationMap, data) {
  let related = {};
  if (!data) {
    return related;
  }

  const relations = selectPostRelationsMap(relationMap, selectMatch(state), data);
  if (!relations) {
    return related;
  }

  for (const path of Object.keys(relations)) {
    const { path: setPath, contentType } = relations[path];
    const ids = get(`metadata.${path}`, data) || [];
    const selectContentItem = contentType ? createSelectContentItem(contentType) : selectItemById;

    const items =
      ids.map((id) => selectContentItem(state, id)).filter((relatedItem) => !!relatedItem) || [];

    related = set(setPath, items, related);
  }

  return related;
}

// Module Exports
export { selectRelatedContentItems, selectRelatedContentItemsByItem, selectPostRelationsMap };
