// External Imports
import { styled } from '@lessonnine/design-system.lib';

// Component Definition
// TODO [redesign]: the gap functionality of the design-system doesn't work well with children, see issue https://github.com/lessonnine/design-system.lib/issues/298, so instead adding gap through margin on the elements here
const SpacedItem = styled.div`
  margin: 0 ${({ space }) => space / 2}px;

  &:first-of-type {
    margin-inline-start: 0;
  }

  &:last-of-type {
    margin-inline-end: 0;
  }
`;

// Module Exports
export { SpacedItem };
