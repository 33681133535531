// External Imports
import { useEffect, useRef } from 'react';

// Internal Imports
import { trackPageEvent } from '../../utils/tracking.mjs';
import { getPerformanceOffset } from './getPerformanceOffset.mjs';

// Local Variables
/*
 * We want to consider a page to be completed once the user scrolls down to the end of it
 * *and* has spent a minimum time on the page matching the threshold defined here.
 * The goal of the additional time threshold is to avoid tracking completions for cases
 * where users quickly scroll down the page to check its length.
 */
const completionTimeThreshold = 30_000;

// Local Functions
function useTrackPageCompleted({
  trackingData,
  trackingEnabled = true,
  threshold = 0.1,
  is404 = false,
}) {
  const observedElementRef = useRef();
  const startTimestamp = useRef();

  useEffect(() => {
    if (!trackingEnabled || is404 || !trackingData?.page_path) {
      // eslint-disable-next-line unicorn/no-null -- consistent return
      return null;
    }
    const performanceOffset = getPerformanceOffset(trackingData?.page_path);
    startTimestamp.current = performance.now() - performanceOffset;
    const contentObserver = new IntersectionObserver(
      (entries) => {
        const timeElapsed = performance.now() - startTimestamp.current;
        const hasEnoughTimeElapsed = timeElapsed > completionTimeThreshold;
        const hasInteresectingEntry = entries.some(
          (entry) => entry.isIntersecting && threshold <= entry.intersectionRatio,
        );
        if (hasInteresectingEntry && hasEnoughTimeElapsed) {
          trackPageEvent({
            name: 'magazine_page:completed',
            ...trackingData,
          });
          contentObserver.disconnect();
        }
      },
      { threshold },
    );
    contentObserver.observe(observedElementRef.current);
    return () => contentObserver.disconnect();
  }, [trackingData, trackingData?.page_path, trackingEnabled, is404, threshold]);

  return observedElementRef;
}

// Module Exports
export { useTrackPageCompleted };
