// Local Functions
const getSessionStartedStatus = () => {
  try {
    return Boolean(globalThis?.sessionStorage?.getItem('session_started'));
  } catch {
    return false;
  }
};

// Module Exports
export { getSessionStartedStatus };
