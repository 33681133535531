// External Imports
import Rollbar from 'rollbar';

// Internal Imports
import { isNode } from './environment.mjs';
import { getRuntimeConfiguration } from './getRuntimeConfiguration.mjs';
import { TimeoutError } from '../errors/timeoutError.mjs';
import { getApigatewayBaseUrl } from './url.mjs';

// Local Variables
const accessToken = isNode()
  ? getRuntimeConfiguration('ROLLBAR_ACCESS_TOKEN_SERVER')
  : getRuntimeConfiguration('ROLLBAR_ACCESS_TOKEN_CLIENT');

const browserConfiguration = {
  hostSafeList: ['cloudfront.net', 'babbel.com', 'babbel.cn'],
};

const getApigatewayDomain = () => new URL(getApigatewayBaseUrl()).host;

const babbelDomains = [...browserConfiguration.hostSafeList, getApigatewayDomain()];

// Local Functions
const isLastNetworkCall3rdParty = (telemetry) => {
  const lastNetworkCall = telemetry.reverse().find(({ type }) => type === 'network');
  return !babbelDomains.some((host) => lastNetworkCall.body.url.includes(host));
};

const checkIgnorePromiseRejection = (payload) => {
  const { telemetry, trace } = payload.body;
  // Load failed errors contain no other information and are generally from 3rd party, so we want to ignore them
  const isLoadFailed = trace.exception.message === 'Load failed';
  if (isLoadFailed) {
    return isLastNetworkCall3rdParty(telemetry);
  }
  return false;
};

const checkIgnore = (isUncaught, parameters, payload) => {
  if (!isUncaught) {
    // We want to track all errors we report ourselves
    return false;
  }
  const isPromiseRejection = parameters.some((argument) => argument instanceof Promise);
  if (isPromiseRejection) {
    // Promise rejections' stackframes can have no information even though
    // they are valid, so we have further checks for them
    return checkIgnorePromiseRejection(payload);
  }
  const { trace } = payload.body;
  const hasAtLeastOneRelevantFrame = trace.frames.some(({ filename, method }) => {
    const isUnknownFilename = filename === '(unknown)';
    const isNativeCodeFilename = filename === '[native code]';
    const location = typeof window === 'undefined' ? undefined : window.location;
    // Stack frames almost always contain the current URL in some form somewhere in their stack as the origin, so those frames aren't relevant
    const isCurrentURL =
      location !== undefined && filename === `${location.origin}${location.pathname}`;
    if (isUnknownFilename || isNativeCodeFilename || isCurrentURL) {
      return false;
    }
    let fileAsUrl;
    // Rollbar is often part of the stack frames as it's the one listening to the error
    // so if a stack has only rollbar frames, we consider it irrelevant
    const isRollbarFrame =
      filename.includes('node_modules/rollbar') || filename.includes('rollbar/src/browser');
    try {
      fileAsUrl = new URL(filename);
    } catch {
      // If the filename wasn't a URL, it was a relative path from the root of the project
      // in this case it's relevant except if it's a Rollbar frame
      return !isRollbarFrame;
    }
    const isDomainFrame = browserConfiguration.hostSafeList.some((host) =>
      fileAsUrl.host.endsWith(host),
    );
    const isGlobalCode = method === 'global code';
    return isDomainFrame && !isRollbarFrame && !isGlobalCode;
  });
  return !hasAtLeastOneRelevantFrame;
};

const rollbarConfig = {
  accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore,
  payload: {
    client: {
      javascript: {
        code_version: BUILD_TIME_CONFIGURATION_COMMIT_SHA,
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
    environment: getRuntimeConfiguration('ENVIRONMENT'),
    server: {
      root: 'webpack:///./',
    },
  },
  ...(isNode() ? {} : browserConfiguration),
};

const logger = accessToken
  ? new Rollbar(rollbarConfig)
  : {
      configure: () => {},
      // eslint-disable-next-line no-console -- we need the consoles here as we are mimicking rollbar for local dev
      critical: console.error.bind(console),
      // eslint-disable-next-line no-console -- we need the consoles here as we are mimicking rollbar for local dev
      debug: console.log.bind(console),
      // eslint-disable-next-line no-console -- we need the consoles here as we are mimicking rollbar for local dev
      error: console.error.bind(console),
      // eslint-disable-next-line no-console -- we need the consoles here as we are mimicking rollbar for local dev
      info: console.log.bind(console),
      lambdaHandler: (lambdaFunction) => lambdaFunction,
      // eslint-disable-next-line no-console -- we need the consoles here as we are mimicking rollbar for local dev
      warning: console.warn.bind(console),
    };

const reportWordPressFetchingError = (message, error) => {
  if (error instanceof TimeoutError) {
    logger.warning(message, error);
    return;
  }
  logger.error(message, error);
};

// Module Exports
export { checkIgnore, logger, reportWordPressFetchingError };
