// External Imports
import cloneDeep from 'lodash/fp/cloneDeep.js';

// Internal Imports
import { RECEIVE_SITE_OPTIONS } from '../config/types/action.mjs';

// Local Functions
/**
 * State slice reducer for menu related actions.
 * @param {object}   state   menu state slice
 * @param {string}   type
 * @param {*}        payload
 * @returns {object}
 */
function menuReducer(state = {}, { type, payload } = {}) {
  switch (type) {
    case RECEIVE_SITE_OPTIONS:
      return cloneDeep(payload.menu);
    default:
  }

  return state;
}

// Module Exports
export { menuReducer };
