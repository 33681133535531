// External Imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header, Stack, styled } from '@lessonnine/design-system.lib';

// Internal Imports
import { selectNavigationText } from '../../../selectors/selectNavigationText.mjs';
import { selectLanguage } from '../../../selectors/selectMatch.mjs';
import { I18n } from '../../I18n/I18n.jsx';
import { ToggleContainerConsumer } from '../../../context/toggleContainer.mjs';
import { NavBarLink } from '../../Link/NavBarLink.jsx';
import { buildExploreLink } from './buildExploreLink.mjs';

const StyledNavBarLink = styled(NavBarLink)`
  color: ${({ theme }) => theme.color.surface.foreground.primary};
`;

// Component Definition
function MobileNavExploreBase({ navigationTextObj, language }) {
  return (
    <ToggleContainerConsumer>
      {({ close: closeContainer }) => (
        <Stack gap={33}>
          {navigationTextObj.categories?.length > 0 ? (
            // TODO [redesign]: remove Stack when SSR gap rendering is fixed in the design system
            <Stack gap={20}>
              {/* eslint-disable-next-line react/jsx-pascal-case -- API of the design-system */}
              <Header.h3>
                <I18n field="categories" defaultText="Explore" />
              </Header.h3>
              <Stack as="ul">
                {navigationTextObj.categories.map((link) => (
                  <li key={link.label}>
                    <StyledNavBarLink
                      to={buildExploreLink({
                        langCode: language,
                        link,
                        urlPrefix: 'category',
                      })}
                      link={link}
                      key={link.label}
                      onClick={closeContainer}
                    >
                      {link.label}
                    </StyledNavBarLink>
                  </li>
                ))}
              </Stack>
            </Stack>
          ) : undefined}
          {navigationTextObj.aboutLanguages?.length > 0 ? (
            // TODO [redesign]: remove Stack when SSR gap rendering is fixed in the design system
            <Stack gap={20}>
              {/* eslint-disable-next-line react/jsx-pascal-case -- API of the design-system */}
              <Header.h3>
                <I18n field="articles-about" defaultText="Articles About" />
              </Header.h3>
              <Stack as="ul">
                {navigationTextObj.aboutLanguages.map((link) => (
                  <li key={link.label}>
                    <NavBarLink
                      to={buildExploreLink({
                        langCode: language,
                        link,
                        urlPrefix: 'tags',
                      })}
                      link={link}
                      key={link.label}
                      onClick={closeContainer}
                    >
                      {link.label}
                    </NavBarLink>
                  </li>
                ))}
              </Stack>
            </Stack>
          ) : undefined}
        </Stack>
      )}
    </ToggleContainerConsumer>
  );
}

MobileNavExploreBase.propTypes = {
  language: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- fix when migrating to TS
  navigationTextObj: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  language: selectLanguage(state),
  navigationTextObj: selectNavigationText(state),
});

const withRedux = connect(mapStateToProps);

const MobileNavExplore = withRedux(MobileNavExploreBase);

// Module Exports
export { MobileNavExplore };
