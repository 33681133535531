// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { selectContentArchiveBase } from './selectContentArchiveBase.mjs';

// Local Functions
const selectArchivedPagination = createSelector(
  [selectContentArchiveBase],
  ({ pagination } = {}) => pagination,
);

// Module Exports
export { selectArchivedPagination };
