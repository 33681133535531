import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@lessonnine/design-system.lib';

// Local Variables
const HiddenImg = styled.img`
  display: none;
`;

// Component Definition
function ShortcodePixel({ url }) {
  return <HiddenImg alt="Shortcode Pixel" src={url} />;
}

ShortcodePixel.propTypes = {
  url: PropTypes.string.isRequired,
};

// Module Exports
export { ShortcodePixel };
