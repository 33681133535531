// External Imports
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Layout, pxToRem, Stack, styled } from '@lessonnine/design-system.lib';

// Internal Imports
import { POST } from '../../config/types/prop.mjs';
import { setInnerHtml } from '../../utils/setInnerHtml.jsx';
import { Thumbnail } from '../Thumbnail/Thumbnail.jsx';
import { RichText } from '../RichText/RichText.jsx';
import { UnstyledLink } from '../Link/UnstyledLink.jsx';
import { DsLink } from '../Link/DsLink.jsx';

// Local Functions
const ThumbnailContainer = styled.div`
  min-width: ${pxToRem(223)};
  width: 100%;
`;

// TODO [redesign]: the current version of the design-system uses capsizes to, theoritecally, make the alignment of text closer to its baseline. Sometimes though, things go awry and the bounding box ends up being all wrong, which is the case here. So for now adding manual padding to fix it, but once rebranding is done, capsizing will be gone so we should be able to get rid of the manual padding
const H3WithCorrectSize = styled(Header.h3)`
  padding: ${pxToRem(8)} 0;
`;

// Component Definition
function SearchListViewItem({ item }) {
  const {
    title,
    excerpt,
    metadata: { featuredImageAlt, featuredImageUrl, link },
  } = item;

  return (
    // eslint-disable-next-line sort-keys -- easier to read in ascending size order
    <Layout direction={{ small: 'stack', medium: 'inline' }} gap={32}>
      {featuredImageUrl && (
        /* Hiding this link as we already have the same link on the header */
        <UnstyledLink aria-hidden tabIndex="-1" to={link}>
          <ThumbnailContainer>
            <Thumbnail
              alt={featuredImageAlt}
              isLazyLoading
              originalSource={featuredImageUrl}
              position="linearListView"
              wrapperStyle="featuredImage"
            />
          </ThumbnailContainer>
        </UnstyledLink>
      )}
      <Stack alignItems="leading" gap={16}>
        <DsLink underlined={false} to={link}>
          {/* TODO [redesign]: We should revisit typography and make sure to change this to improve it */}
          <H3WithCorrectSize
            as="h2"
            color="inherit"
            dangerouslySetInnerHTML={setInnerHtml(title)}
          />
        </DsLink>
        <RichText richText={excerpt} />
      </Stack>
    </Layout>
  );
}

SearchListViewItem.propTypes = {
  item: PropTypes.shape(POST).isRequired,
  theme: PropTypes.shape({
    author: PropTypes.string,
    content: PropTypes.string,
    excerpt: PropTypes.string,
    featuredImage: PropTypes.string,
    featuredImageWrapper: PropTypes.string,
    heading: PropTypes.string,
    noImage: PropTypes.string,
    wrapper: PropTypes.string,
  }).isRequired,
};

// Module Exports
export { SearchListViewItem };
