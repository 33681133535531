// External Imports
import React from 'react';
import { NavBarLink as DSNavBarLink } from '@lessonnine/design-system.lib';
import { withRouter, Route } from 'react-router';
import { Link } from 'react-router-dom';

// Internal Imports
import { withLinkLogic } from './withLinkLogic.jsx';

// Local Variables
const NavBarLinkStyle = withLinkLogic(DSNavBarLink, { useForwardedAs: true });

const withoutTrailingSlash = (path) => (path.endsWith('/') ? path.slice(0, -1) : path);

// Component Definition
function NavBarLinkBase({ location, to, ...props }) {
  const isActive =
    withoutTrailingSlash(location.pathname) === withoutTrailingSlash(to.split('?')[0]);
  // eslint-disable-next-line react/jsx-props-no-spreading -- this component acts as kind of HOC, so spreading props is sensible
  return <NavBarLinkStyle isActive={isActive} to={to} {...props} />;
}

NavBarLinkBase.propTypes = {
  location: Route.propTypes.location.isRequired,
  // eslint-disable-next-line react/require-default-props -- already required in the original type
  to: Link.propTypes.to,
};

const NavBarLink = withRouter(NavBarLinkBase);

// Module Exports
export { NavBarLink };
