// External Imports
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

// Internal Imports
import { logger } from '../../utils/rollbar.mjs';

// Local Functions
function createSpeedCurveScriptElement() {
  const scriptElement = document.createElement('script');
  scriptElement.async = true;
  scriptElement.crossOrigin = 'anonymous';
  scriptElement.defer = true;
  scriptElement.src = 'https://cdn.speedcurve.com/js/lux.js?id=295968638';
  return scriptElement;
}

// prettier-ignore
function initializeSpeedCurveLux() {
  // Because ES modules run in strict mode, create necessary global variables before running minified code
  globalThis.LUX ??= {};
  globalThis.LUX_ae ??= []; // error aggregator array
  globalThis.LUX_al ??= []; // PerformanceObserver aggregator array

  // eslint-disable-next-line -- this minified code provided as-is by SpeedCurve
  globalThis.LUX = function(){function n(){return Date.now?Date.now():+new Date}var r,e=n(),t=window.performance||{},a=t.timing||{navigationStart:(null===(r=window.LUX)||void 0===r?void 0:r.ns)||e};function o(){return t.now?(r=t.now(),Math.floor(r)):n()-a.navigationStart;var r}(LUX=window.LUX||{}).ac=[],LUX.addData=function(n,r){return LUX.cmd(["addData",n,r])},LUX.cmd=function(n){return LUX.ac.push(n)},LUX.getDebug=function(){return[[e,0,[]]]},LUX.init=function(){return LUX.cmd(["init"])},LUX.mark=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.mark)return t.mark.apply(t,n);var e=n[0],a=n[1]||{};void 0===a.startTime&&(a.startTime=o());LUX.cmd(["mark",e,a])},LUX.markLoadTime=function(){return LUX.cmd(["markLoadTime",o()])},LUX.measure=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.measure)return t.measure.apply(t,n);var e,a=n[0],i=n[1],u=n[2];e="object"==typeof i?n[1]:{start:i,end:u};e.duration||e.end||(e.end=o());LUX.cmd(["measure",a,e])},LUX.send=function(){return LUX.cmd(["send"])},LUX.ns=e;var i=LUX;if(window.LUX_ae=[],window.addEventListener("error",(function(n){window.LUX_ae.push(n)})),window.LUX_al=[],"function"==typeof PerformanceObserver&&"function"==typeof PerformanceLongTaskTiming){var u=new PerformanceObserver((function(n){for(var r=n.getEntries(),e=0;e<r.length;e++)window.LUX_al.push(r[e])}));try{u.observe({type:"longtask"})}catch(n){}}return i}();
  
  // SPA optimizations: https://support.speedcurve.com/docs/single-page-applications#recommended-implementation-for-a-spa
  globalThis.LUX.auto = false; // gain full control over how data is collected and when the beacon is sent
  globalThis.LUX.sendBeaconOnPageHidden = true; // reduce data loss due to page abandonment
}

// Component Definition
// See https://support.speedcurve.com/docs/single-page-applications#recommended-implementation-for-a-spa for extra information
function SpeedcurveRum({ history, location }) {
  const [isSpeedcurveLoaded, setIsSpeedcurveLoaded] = useState(false);

  useEffect(() => {
    initializeSpeedCurveLux();
    globalThis.LUX.mark('magazineAppLoaded');

    const handleSpeedcurveLoading = () => setIsSpeedcurveLoaded(true);
    const handleSpeedcurveError = () =>
      logger.warning(
        'SpeedCurve script failed to load, hence RUM data will not be collected for this user session.',
      );

    const scriptElement = createSpeedCurveScriptElement();
    scriptElement.addEventListener('error', handleSpeedcurveError);
    scriptElement.addEventListener('load', handleSpeedcurveLoading);
    document.head.append(scriptElement);

    return () => {
      scriptElement.removeEventListener('error', handleSpeedcurveError);
      scriptElement.removeEventListener('load', handleSpeedcurveLoading);
      scriptElement.remove();
    };
  }, []);

  useEffect(() => {
    if (!isSpeedcurveLoaded) {
      return;
    }

    globalThis.LUX.markLoadTime();
  }, [isSpeedcurveLoaded, location.pathname, location.search]);

  useEffect(() => {
    if (!isSpeedcurveLoaded) {
      return () => {};
    }

    return history.listen(() => {
      // Documentation: https://support.speedcurve.com/docs/single-page-applications#apis
      globalThis.LUX.send(); // Call as late in the page lifecycle as possible to maximize data collection
      globalThis.LUX.init(); // Call at the beginning of a SPA page transition (but not on the initial page load)
    });
  }, [history, isSpeedcurveLoaded]);

  // eslint-disable-next-line unicorn/no-null -- React expects null for empty renders
  return null;
}

SpeedcurveRum.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- this should be a TypeScript type eventually
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- this should be a TypeScript type eventually
};

const SpeedcurveRumHoc = withRouter(SpeedcurveRum);

// Module Exports
export { SpeedcurveRumHoc as SpeedcurveRum };
