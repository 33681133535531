// External Imports
import { styled } from '@lessonnine/design-system.lib';

// Internal Imports
import { separatorStyles } from '../../sharedStyles/separator.mjs';

// Component Definition
const Separator = styled.hr`
  ${separatorStyles}
`;

// Module Exports
export { Separator };
