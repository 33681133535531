// External Imports
import get from 'lodash/fp/get.js';
import trimEnd from 'lodash/trimEnd.js';
import { createSelector } from 'reselect';

// Internal Imports
import { redirects } from '../config/redirects.mjs';
import { selectMatch } from './selectMatch.mjs';

// Local Functions
/**
 * @typedef {object} Redirect
 * @property {string|RegExp} pattern
 * @property {string} replace
 *
 * Select dynamic redirects from state, map them to Redirect objects,
 * then concat and return with static Redirect objects.
 * @return {Redirect[]}
 */
const selectRedirects = createSelector([get('redirect')], (redirectMap) => [
  ...Object.keys(redirectMap).map((pattern) => ({ pattern, replace: redirectMap[pattern] })),
  ...redirects,
]);

/**
 * Find the first redirect pattern that matches the current url path,
 * and return the new url to redirect to.
 * @return {string}
 */
const selectRedirect = createSelector(
  [selectRedirects, selectMatch],
  (redirectsMapped, { url }) => {
    const redirect = redirectsMapped.find(({ pattern }) =>
      pattern instanceof RegExp ? url.match(pattern) : trimEnd(url, '/') === trimEnd(pattern, '/'),
    );
    if (!redirect) {
      return;
    }

    const { pattern, replace } = redirect;
    const replacedString = url.replace(pattern, replace);
    if (!replacedString) {
      return;
    }

    // eslint-disable-next-line consistent-return -- optional return
    return replacedString;
  },
);

// Module Exports
export { selectRedirect };
