// Custom interrelations between content items.

import { CONTENT_ITEM_SINGLE, HOME } from '../path.mjs';

const contentRelationMap = {
  [CONTENT_ITEM_SINGLE]: {
    'coauthors.0.relatedPosts': {
      eager: true,
      path: 'authorRelated',
    },
    relatedPosts: {
      eager: true,
      path: 'relatedPosts',
    },
  },
  [HOME]: {
    'homepage.articles': {
      eager: true,
      path: 'articles',
    },
  },
};

// Module Exports
export { contentRelationMap };
