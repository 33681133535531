// External Imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Inline, styled, Text } from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

// Internal Imports
import { selectPageTrackingData } from '../../../selectors/selectPageTrackingData.mjs';
import { FlagIcon } from './FlagIcon.jsx';
import { makeFlagLinkOnClick } from './makeFlagLinkOnClick.mjs';
import { FlagItemPropTypes } from './flagItemPropTypes.mjs';
import { withLinkLogic } from '../../Link/withLinkLogic.jsx';

// Local Functions
// TODO [redesign]: it's not great we require a custom button here, there will be talk during the rebranding to understand whether we could leverage some other design-system components (e.g. interactive card) to achieve this better, for now we use a custom one
const CustomButtonLinkStyles = styled.a`
  background-color: ${({ theme }) => theme.color.interactive.secondary.background};
  border: solid 1px ${({ theme }) => theme.color.interactive.secondary.border};
  border-radius: ${tokens.size.radius.small};
  color: ${({ theme }) => theme.color.interactive.secondary.foreground};
  display: inline-flex;
  text-decoration: none;

  :hover {
    background-color: ${({ theme }) => theme.color.interactive.secondary.hover.background};
  }

  :active {
    background-color: ${({ theme }) => theme.color.interactive.secondary.active.background};
    border-color: ${({ theme }) => theme.color.interactive.secondary.active.border};
  }
`;

const CustomButtonLink = withLinkLogic(CustomButtonLinkStyles);

// Components Definition
function FlagItemSmallBase({ flagCode, flagType, text, pageTrackingData, url }) {
  const { data: trackingData } = pageTrackingData;
  return (
    <CustomButtonLink onClick={makeFlagLinkOnClick({ flagType, trackingData, url })} to={url}>
      <Inline alignItems="center" gap={8} padding={[8, 12]}>
        <FlagIcon flagCode={flagCode} size={25} />
        <Text fontSize="xsmall" fontWeight={500} whiteSpace="nowrap">
          {text.toUpperCase()}
        </Text>
      </Inline>
    </CustomButtonLink>
  );
}

FlagItemSmallBase.propTypes = {
  ...FlagItemPropTypes,
  // eslint-disable-next-line react/forbid-prop-types -- will be improved with TS in the future
  pageTrackingData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pageTrackingData: selectPageTrackingData(state),
});

const withRedux = connect(mapStateToProps);

const FlagItemSmall = withRedux(FlagItemSmallBase);

// Module Exports
export { FlagItemSmall };
