// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { CONTENT_ITEM_SINGLE } from '../config/types/path.mjs';
import { selectPathType } from './selectMatch.mjs';

// Local Functions
const selectIsArticlePage = createSelector(
  selectPathType,
  (pathType) => pathType === CONTENT_ITEM_SINGLE,
);

// Module Exports
export { selectIsArticlePage };
