// External Imports
import { connect } from 'react-redux';
import React from 'react';
import {
  Inline,
  Stack,
  styled,
  IconMenu,
  IconMagnifyingGlass,
} from '@lessonnine/design-system.lib';
import PropTypes from 'prop-types';

// Internal Imports
import { buildCTALink } from '../../utils/url.mjs';
import { selectEdition } from '../../selectors/selectEdition.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { LANGUAGES } from '../../config/types/prop.mjs';
import { ButtonLink } from '../Link/ButtonLink.jsx';
import { UnstyledLink } from '../Link/UnstyledLink.jsx';
import { Logo } from './Logo.jsx';
import { MenuNav } from '../Nav/MenuNav/MenuNav.jsx';
import { DesktopNavExplore } from '../Nav/NavExplore/DesktopNavExplore.jsx';
import { options } from '../../config/reactRedux.mjs';
import { SearchForm } from '../SearchForm/SearchForm.jsx';
import { ToggleContainer } from '../ToggleContainer/ToggleContainer.jsx';
import { ConditionalDisplay } from '../ConditionalDisplay/ConditionalDisplay.jsx';
import { actionCtaClicked } from '../../actions/index.mjs';
import { breakpoints } from '../../config/css/breakpoints.mjs';

const LogoWrapper = styled.div`
  flex-grow: 1;
`;

const HeaderStack = styled(Stack)`
  ${({ theme }) => `
    background-color: ${theme.color.neutral.surfaceLightened};
    border-bottom: 1px solid ${theme.color.lines.default};
    box-shadow: 0 -30px 32px ${theme.color.surface.layer.shadow};
    color: ${theme.color.surface.layer.foreground};
  `};
`;

const InlineWithMaxWidth = styled(Inline)`
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  width: 100%;
`;

const Separator = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.color.lines.default}`};
  display: none;
  height: 1px;
  width: 100%;

  @media ${breakpoints.tabletPortrait.min} {
    display: block;
  }
`;

const SearchFormWrapper = styled.div`
  margin-top: 60px;
  max-width: 1000px;
  width: 100%;
`;

// Component Definition
function DefaultHeaderBase({ currentEdition, dispatchActionCtaClicked }) {
  const ctaUrl = buildCTALink(currentEdition);
  return (
    <HeaderStack>
      {/* TODO: the best would be if we could use the Inline element `as` header, but this breaks the styles somehow */}
      <header>
        <InlineWithMaxWidth alignItems="center" gap={24} padding={12}>
          <LogoWrapper>
            <UnstyledLink to={`/${currentEdition}/magazine`}>
              <Logo />
            </UnstyledLink>
          </LogoWrapper>
          <ConditionalDisplay hideUpTo="tabletPortrait">
            <ButtonLink
              onClick={() => dispatchActionCtaClicked({ placement: 'header', url: ctaUrl })}
              to={ctaUrl}
            >
              <I18n field="try-babbel" defaultText="Try Babbel" />
            </ButtonLink>
          </ConditionalDisplay>
          <ConditionalDisplay hideAbove="tabletPortrait">
            <ToggleContainer
              button={{
                icon: <IconMenu />,
                label: 'Toggle Menu',
              }}
              modalTitle="Menu"
              modalId="menu-modal"
            >
              <MenuNav />
            </ToggleContainer>
          </ConditionalDisplay>
        </InlineWithMaxWidth>
      </header>
      <Separator />
      <ConditionalDisplay hideUpTo="tabletPortrait">
        <InlineWithMaxWidth align="spaceBetween" alignItems="center" padding={15}>
          <DesktopNavExplore />
          <ToggleContainer
            button={{
              icon: <IconMagnifyingGlass />,
              label: 'Toggle Search',
            }}
            modalTitle="Search"
            modalId="search-modal"
          >
            <SearchFormWrapper>
              <SearchForm />
            </SearchFormWrapper>
          </ToggleContainer>
        </InlineWithMaxWidth>
      </ConditionalDisplay>
    </HeaderStack>
  );
}

DefaultHeaderBase.propTypes = {
  currentEdition: LANGUAGES.isRequired,
  dispatchActionCtaClicked: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentEdition: selectEdition(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchActionCtaClicked: (payload) => {
    dispatch(actionCtaClicked(payload));
  },
});

const withRedux = connect(mapStateToProps, mapDispatchToProps, undefined, options);
const DefaultHeader = withRedux(DefaultHeaderBase);

// Module Exports
export { DefaultHeader };
