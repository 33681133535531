// External Imports
import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import { Text, Inline, useId } from '@lessonnine/design-system.lib';

// Internal Imports
import { I18n } from '../I18n/I18n.jsx';
import { LANGUAGES } from '../../config/types/prop.mjs';
import { DisclosureMenu } from '../DisclosureMenu/DisclosureMenu.jsx';
import { DisclosureMenuLink } from '../DisclosureMenu/DisclosureMenuLink.jsx';

// Component Definition
function EditionPicker({ editions, currentEdition }) {
  const labelId = useId();
  const filteredEditions = omit([currentEdition], editions);
  const menuItems = Object.keys(filteredEditions).map((languageCode) => {
    const { link, name } = editions[languageCode];
    return (
      <DisclosureMenuLink to={link} key={languageCode}>
        {name}
      </DisclosureMenuLink>
    );
  });

  const labelItem = (
    <Text fontWeight={500} fontSize="medium">
      {editions[currentEdition]?.name}
    </Text>
  );

  if (editions[currentEdition]) {
    return (
      <Inline gap={15} alignItems="center">
        <Text
          id={labelId}
          fontWeight={600}
          fontSize={{ medium: 'medium', small: 'small' }}
          letterSpacing="0.06"
        >
          <I18n field="babbel-magazine-edition" defaultText="BABBEL MAGAZINE EDITION" />
        </Text>
        <DisclosureMenu label={labelItem} items={menuItems} navLabelId={{ id: labelId }} />
      </Inline>
    );
  }

  // eslint-disable-next-line unicorn/no-null -- expected return for empty component with React
  return null;
}

EditionPicker.propTypes = {
  currentEdition: LANGUAGES.isRequired,
  editions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- dynamically built
};

// Module Exports
export { EditionPicker };
