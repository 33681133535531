// External Imports
import { pxToRem, styled } from '@lessonnine/design-system.lib';
import PropTypes from 'prop-types';
import React from 'react';

// Internal Imports
import plusIcon from '../../../assets/flags/plus.svg';

// Local Variables
const PlusImg = styled.img`
  padding: ${({ padding }) => pxToRem(padding)};
`;

// Local Functions
// Design-System flag icons include some padding in their design, so to get to the right size for the plus icon we should reduce the size by this factor
const scaleFactor = 0.8;
// Then we divide by 2 to calculate padding (as the padding is applied from both directions to be centered)
const getPadding = (size) => (size - size * scaleFactor) / 2;

// Component Definition
function PlusIllustration({ size }) {
  const padding = getPadding(size);
  return (
    <PlusImg
      alt=""
      aria-hidden="true"
      height={size}
      src={plusIcon}
      width={size}
      padding={padding}
    />
  );
}

PlusIllustration.propTypes = {
  size: PropTypes.number.isRequired,
};

// Module Exports
export { PlusIllustration };
