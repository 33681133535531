// External Imports
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Header, Inline, pxToRem, styled, Text, Stack } from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

// Internal Imports
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { CONTENT_ITEM, POST } from '../../config/types/prop.mjs';
import { selectIsInProductMagazine } from '../../selectors/selectIsInProductMagazine.mjs';
import { selectLandingPage } from '../../selectors/selectLandingPage.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { DsLink } from '../Link/DsLink.jsx';
import { ListViewContext } from '../../context/listView.mjs';
import { SearchListViewItem } from './SearchListViewItem.jsx';
import { setInnerHtml } from '../../utils/setInnerHtml.jsx';
import { Thumbnail } from '../Thumbnail/Thumbnail.jsx';
import { RichText } from '../RichText/RichText.jsx';
import { UnstyledLink } from '../Link/UnstyledLink.jsx';

// Local Variables
const Wrapper = styled(Stack)`
  overflow-wrap: break-word;
  width: 100%;
  ${({ hasImage }) => hasImage && 'margin-top: 3px;'}
`;

const Author = styled(Text)`
  color: ${({ theme }) => theme.color.surface.foreground.secondary};
`;

const HeadingBase = styled(Header.h2)`
  /* TODO [redesign]: once we get the rebranded version of the design-system (v5), we should revisit this with the new Typography component and loose the custom font-sizes, font-weights, line-heigts in favor of the typography's system versions */
  font-size: ${pxToRem(22)};
  font-weight: 400;
  padding: ${pxToRem(14)} 0 ${pxToRem(16)};
`;

const HeadingThreeUp = styled(HeadingBase)`
  font-size: ${pxToRem(18)};
  line-height: calc(22 / 18);

  @media ${breakpoints.mobile.max} {
    font-size: ${pxToRem(22)};
    line-height: calc(26 / 22);
  }
`;

const HeadingTwoUp = styled(HeadingBase)`
  font-weight: 400;
  line-height: calc(26 / 22);
`;

const Excerpt = styled(RichText)`
  color: ${({ theme }) => theme.color.surface.foreground.secondary};
  line-height: 1.25;
  margin-bottom: ${pxToRem(16)};
`;

const FullWidthUnstyledLink = styled(UnstyledLink)`
  width: 100%;
`;

const HeadingComponents = {
  threeUp: HeadingThreeUp,
  twoUp: HeadingTwoUp,
};

// Component Definition
function ListViewItem({ item, isLandingPage, topTwoArticles, isInProductMagazine }) {
  const listView = useContext(ListViewContext);
  const {
    id,
    title,
    excerpt,
    metadata: {
      featuredImageAlt,
      featuredImageUrl,
      coauthors: [
        {
          // Always use the first coauthor
          relativePath,
          displayName,
        },
      ],
      link,
    },
  } = item;
  const isTopTwoArticles = topTwoArticles.some((topArticle) => topArticle.id === id);
  const isTwoUpView = listView === 'twoUp';
  const isLinearView = listView === 'linear';
  const imagePosition = `${listView}ListView`;
  const Heading = HeadingComponents[listView];

  if (isLinearView) {
    return <SearchListViewItem item={item} />;
  }

  return (
    <Wrapper hasImage={featuredImageUrl} gap={4} alignItems="leading">
      {featuredImageUrl && (
        // It's alright to use aria hidden here as this only wraps the image and we have the same link below with the article's name
        <FullWidthUnstyledLink to={link} aria-hidden="true" tabIndex="-1">
          <Thumbnail
            alt={featuredImageAlt}
            isLazyLoading={isLandingPage ? !isTopTwoArticles : !isTwoUpView}
            originalSource={featuredImageUrl}
            position={imagePosition}
            wrapperStyle="featuredImage"
          />
        </FullWidthUnstyledLink>
      )}
      <DsLink to={link} underlined={false}>
        <Heading color="inherit" dangerouslySetInnerHTML={setInnerHtml(title)} />
      </DsLink>
      {isTwoUpView && <Excerpt richText={excerpt} />}
      <Inline alignItems="center" gap={tokens.space.x1}>
        <Author fontWeight={600} fontSize={14} letterSpacing={0.2}>
          <I18n field="by" defaultText="by" />
        </Author>
        {isInProductMagazine ? (
          <Text fontWeight={600} fontSize={14} letterSpacing={0.2}>
            {displayName}
          </Text>
        ) : (
          <DsLink to={relativePath} underlined={false} size="small" weight="strong">
            {displayName}
          </DsLink>
        )}
      </Inline>
    </Wrapper>
  );
}

ListViewItem.propTypes = {
  isInProductMagazine: PropTypes.bool.isRequired,
  isLandingPage: PropTypes.bool.isRequired,
  item: PropTypes.shape(POST).isRequired,
  topTwoArticles: PropTypes.arrayOf(PropTypes.shape(CONTENT_ITEM)).isRequired,
};

const mapStateToProps = (state) => {
  const landingPage = selectLandingPage(state);
  let topTwoArticles = [];

  if (landingPage) {
    topTwoArticles = landingPage.relatedItems.slice(0, 2);
  }

  return {
    isInProductMagazine: selectIsInProductMagazine(state),
    isLandingPage: Boolean(landingPage),
    topTwoArticles,
  };
};

const withRedux = connect(mapStateToProps);
const ListViewItemHoc = withRedux(ListViewItem);

// Module Exports
export { ListViewItemHoc as ListViewItem };
