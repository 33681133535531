// External Imports
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

// Internal Imports
import { DefaultHeader } from './DefaultHeader.jsx';
import { selectIsInProductMagazine } from '../../selectors/selectIsInProductMagazine.mjs';
import { InProductHeader } from './InProductHeader.jsx';

// Component Definition
function Header({ isInProductMagazine }) {
  return isInProductMagazine ? <InProductHeader /> : <DefaultHeader />;
}

Header.propTypes = {
  isInProductMagazine: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isInProductMagazine: selectIsInProductMagazine(state),
});

const withRedux = connect(mapStateToProps);
const HeaderHoc = withRedux(Header);

// Module Exports
export { HeaderHoc as Header };
