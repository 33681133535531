// Local Variables
const articleContentMaxWidth = 680;
const siteMaxWidth = 1_280;
const siteInnerMaxWidth = 960;
const siteGutter = 15;
const bodyTextWidthRatio = 0.7;
const bodyTextFullbleedWidth = `${(1 / bodyTextWidthRatio) * 100}%`;

// Module Exports
export {
  articleContentMaxWidth,
  bodyTextFullbleedWidth,
  siteGutter,
  siteInnerMaxWidth,
  siteMaxWidth,
};
