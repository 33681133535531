// External Imports
import { LOCATION_CHANGE } from 'connected-react-router';
import get from 'lodash/fp/get.js';
import keyBy from 'lodash/fp/keyBy.js';
import set from 'lodash/fp/set.js';

// Internal Imports
import { landingPageTypePathTypeMap } from '../config/types/landingPage.mjs';
import { selectMatch } from '../selectors/selectMatch.mjs';
import { LANDING } from '../config/types/content/index.mjs';
import { RECEIVE_PRIMARY_CONTENT } from '../config/types/action.mjs';

// Local Functions
/**
 * State container reducer for landing page related actions.
 * @param {object}  state   state container
 * @param {string}  type
 * @param {*}       payload
 * @return {object}
 */
function landingPageReducer(state, { type, payload } = {}) {
  const { params, pathType } = selectMatch(state);
  const landingPageType = landingPageTypePathTypeMap[pathType];
  let currentLanding;

  const getCurrentLanding = (landingPages) =>
    Object.keys(landingPages).find((id) => {
      const landingPage = landingPages[id];
      return (
        landingPageType === get('metadata.landingPageType', landingPage) &&
        // This is a babbel-specific parameter
        params.language === get('metadata.language', landingPage)
      );
    });

  switch (type) {
    case RECEIVE_PRIMARY_CONTENT:
      currentLanding = getCurrentLanding(keyBy('id', get(LANDING, payload)) || []);
      break;

    case LOCATION_CHANGE:
      currentLanding = getCurrentLanding(get(`contentItem.${LANDING}.byId`, state) || []);
      break;

    default:
      currentLanding = state.landingPage;
      break;
  }

  return set('landingPage', Number.parseInt(currentLanding, 10), state);
}

// Module Exports
export { landingPageReducer };
