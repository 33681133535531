// External Imports
import get from 'lodash/fp/get.js';
import { compile } from 'path-to-regexp';
import { createSelector } from 'reselect';

// Internal Imports
import { SEARCH_ARCHIVE } from '../config/types/path.mjs';
import { selectActivePagination } from './selectActivePagination.mjs';
import { selectMatch } from './selectMatch.mjs';
import { selectPathMap } from './selectPathMap.mjs';
import { selectRouteKey } from './selectRouteKey.mjs';

// Local Functions
const selectSearchPath = createSelector([selectPathMap, selectMatch], (pathMap, { params }) => {
  const searchToPath = compile(pathMap[SEARCH_ARCHIVE]);
  const languageEdition = params.language === 'en-US' ? 'en' : params.language;
  return searchToPath({
    ...params,
    language: languageEdition,
    page: 1,
  });
});

const selectSearchParameters = createSelector([selectMatch], (match) => ({
  s: '',
  ...match.query,
}));

const selectSearchResultsShowing = createSelector(
  [get('archive'), selectRouteKey, selectActivePagination],
  (archiveState, routeKey, { currentPage, perPage }) => {
    const pages = get([routeKey, 'pages'], archiveState);
    const pageNumbers = Object.keys(pages);

    if (pageNumbers.length > 0 && pages[currentPage]) {
      const firstItemPosition = (currentPage - 1) * perPage + 1;
      return [firstItemPosition, firstItemPosition + (pages[currentPage].length - 1)];
    }

    return [];
  },
);

// Module Exports
export { selectSearchParameters, selectSearchPath, selectSearchResultsShowing };
