// External Imports
import { connect } from 'react-redux';
import React from 'react';
import { Layout, Stack, Header } from '@lessonnine/design-system.lib';

// Internal Imports
import { buildCanonicalLink } from '../../../utils/url.mjs';
import { CONTENT_ARCHIVE } from '../../../config/types/prop.mjs';
import { ContentList } from '../../ContentList/ContentList.jsx';
import { selectIsInProductMagazine } from '../../../selectors/selectIsInProductMagazine.mjs';
import { selectLanguage } from '../../../selectors/selectMatch.mjs';
import { selectNavigationText } from '../../../selectors/selectNavigationText.mjs';
import { HeaderLinks } from '../../HeaderLinks/HeaderLinks.jsx';
import { ListViewContext } from '../../../context/listView.mjs';
import { Meta } from '../../Meta/Meta.jsx';
import { setInnerHtml } from '../../../utils/setInnerHtml.jsx';
import { ShowMoreButton } from '../../ShowMoreButton/ShowMoreButton.jsx';
import { useAccessibilityFocusLastAfterLoading } from '../../../hooks/useAccessibilityFocusLastAfterLoading.mjs';
import { Divider } from '../../Divider/Divider.jsx';

// Local Functions
// Checks whether linkList contains a link matching the provided params
function checkListForPath(linkList, archiveType, langCode, path) {
  return linkList
    .map((exploreLink) => `/${langCode}/magazine/${archiveType}${exploreLink.url}`)
    .includes(path);
}

function getRobotsNoindex(navigationTextObject, language, pathname) {
  // decide whether to index this page
  const shouldIndex =
    checkListForPath(navigationTextObject.aboutLanguages, 'tags', language, pathname) ||
    checkListForPath(navigationTextObject.categories, 'category', language, pathname);

  return shouldIndex ? [] : [{ content: 'noindex', name: 'robots' }];
}

// Component Definition
function ContentArchiveMultiple({
  items,
  loadingMore,
  onLoadMore,
  pagination: { hasMore },
  term: { name },
  location: { pathname },
  currentEdition,
  navigationTextObj,
  language,
  isInProductMagazine,
}) {
  const onFocus = useAccessibilityFocusLastAfterLoading({ loading: loadingMore });
  // tells search engine to index/not index page as desired
  const robotsNoindex = getRobotsNoindex(navigationTextObj, language, pathname);

  const showLoadMore = hasMore || loadingMore;
  const canonicalLink = buildCanonicalLink(currentEdition, pathname);

  // tells view where to slice items so that the view below the two featured articles always has complete rows of three
  const lastItemIndex = hasMore ? items.length - ((items.length - 2) % 3) : items.length;

  return (
    <Layout direction={{ medium: 'row', small: 'column' }}>
      <Meta metaTags={robotsNoindex} title={`${name} - Babbel Magazine`} />
      <HeaderLinks canonicalUrl={canonicalLink} />
      {/* TODO [redesign]: Needed to add a 10px top padding here because the h1's bounding box is wrong so text would get cut off otherwise, should be fixed with rebranded version */}
      <Stack padding={[10, 0, 0]}>
        {!isInProductMagazine && (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case -- design-system's API */}
            <Header.h1 dangerouslySetInnerHTML={setInnerHtml(name)} />
            {/* TODO [redesign]: invisible divider here is meant to take the space, after rebranding should be replaced by gap */}
            <Divider isInvisible />
          </>
        )}
        <ListViewContext.Provider value="twoUp">
          <ContentList items={items.slice(0, 2)} onFocus={onFocus} />
        </ListViewContext.Provider>
        <Divider />
        <ListViewContext.Provider value="threeUp">
          <ContentList
            items={items.slice(2, lastItemIndex)}
            interstitials={[
              {
                content: <Divider />,
                count: Number.POSITIVE_INFINITY,
                frequency: 3,
              },
            ]}
            onFocus={onFocus}
          />
        </ListViewContext.Provider>
        <Divider />
        {showLoadMore && (
          <ShowMoreButton onClick={onLoadMore} disabled={loadingMore} loading={loadingMore} />
        )}
      </Stack>
    </Layout>
  );
}

ContentArchiveMultiple.propTypes = CONTENT_ARCHIVE;

const mapStateToProps = (state) => ({
  isInProductMagazine: selectIsInProductMagazine(state),
  language: selectLanguage(state),
  navigationTextObj: selectNavigationText(state),
});

const withRedux = connect(mapStateToProps);
const ContentArchiveMultipleHoc = withRedux(ContentArchiveMultiple);

// Module Exports
export { ContentArchiveMultipleHoc as ContentArchiveMultiple };
