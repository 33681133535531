import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

function HeaderLinks({ canonicalUrl, languageHreflang }) {
  return (
    <Helmet>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {languageHreflang.length > 0 &&
        languageHreflang.map(({ href, hreflang }) => (
          <link key={hreflang} rel="alternate" href={href} hrefLang={hreflang} />
        ))}
    </Helmet>
  );
}

HeaderLinks.propTypes = {
  canonicalUrl: PropTypes.string,
  languageHreflang: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      hreflang: PropTypes.string.isRequired,
    }),
  ),
};

HeaderLinks.defaultProps = {
  canonicalUrl: '',
  languageHreflang: [],
};

// Module Exports
export { HeaderLinks };
