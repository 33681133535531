// External Imports
import { Header, pxToRem, Stack, styled } from '@lessonnine/design-system.lib';
import React from 'react';

// Internal Imports
import { breakpoints } from '../../../../config/css/breakpoints.mjs';
import { COAUTHOR_SHAPE } from '../../../../config/types/prop.mjs';
import { createTransformSourceGravatar } from '../../../../utils/gravatar.mjs';
import { RichText } from '../../../RichText/RichText.jsx';
import { Thumbnail } from '../../../Thumbnail/Thumbnail.jsx';

// Local Variables
const AuthorHeaderWrapper = styled.div`
  display: flex;

  @media ${breakpoints.tabletPortrait.max} {
    flex-direction: column;
  }
`;

const HeadingWithLineHeight = styled(Header.h1)`
  line-height: 1.5;
`;

const PhotoContainer = styled.div`
  margin-right: ${pxToRem(40)};

  @media ${breakpoints.tabletPortrait.max} {
    margin-bottom: ${pxToRem(24)};
    margin-right: 0;
  }
`;

// Component Definition
function AuthorHeader({ displayName, descriptions, description, avatarUrl, currentEdition }) {
  return (
    <section>
      <AuthorHeaderWrapper>
        <PhotoContainer>
          {/* Thumbnail component is being reworked in another branch */}
          <Thumbnail
            alt="Author Headshot"
            originalSource={avatarUrl}
            position="authorHeader"
            transformFactory={createTransformSourceGravatar}
            wrapperStyle="authorHeader"
          />
        </PhotoContainer>
        <Stack gap={24}>
          <HeadingWithLineHeight>{displayName}</HeadingWithLineHeight>
          <RichText richText={descriptions[currentEdition] || description} />
        </Stack>
      </AuthorHeaderWrapper>
    </section>
  );
}

AuthorHeader.propTypes = COAUTHOR_SHAPE;

// Module Exports
export { AuthorHeader };
