// External Imports
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import {
  Header,
  Inline,
  pxToRem,
  Spinner,
  Stack,
  styled,
  Text,
} from '@lessonnine/design-system.lib';

// Internal Imports
import { actionCtaClicked } from '../../../actions/index.mjs';
import { ArticleHeader } from '../../ArticleHeader/ArticleHeader.jsx';
import { AuthorOverview } from '../../AuthorOverview/AuthorOverview.jsx';
import { BlogPostingStructuredData } from '../BlogPostingStructuredData.jsx';
// import { BreadcrumbLinks } from '../BreadcrumbLinks.jsx';
import { CodeBlockSyntaxHighlighter } from '../../CodeBlockSyntaxHighlighter/CodeBlockSyntaxHighlighter.jsx';
import { CONTENT_ITEM, LANGUAGES } from '../../../config/types/prop.mjs';
import { ContentList } from '../../ContentList/ContentList.jsx';
import { FeaturedVideo } from '../../Video/FeaturedVideo.jsx';
import { selectIs404 } from '../../../selectors/selectIs404.mjs';
import { selectPageTrackingData } from '../../../selectors/selectPageTrackingData.mjs';
import { I18n } from '../../I18n/I18n.jsx';
import { ListViewContext } from '../../../context/listView.mjs';
import { options } from '../../../config/reactRedux.mjs';
import { plainTextNoHTML } from '../../../config/sanitizeHtml.mjs';
import { ShareButtons } from '../../ShareButtons/ShareButtons.jsx';
import { StickyShareButtons } from '../../StickyShareButtons/StickyShareButtons.jsx';
import { Thumbnail } from '../../Thumbnail/Thumbnail.jsx';
import { updateImagesForLazyloading } from '../../../utils/content.mjs';
import { useTrackPageCompleted } from '../../../hooks/useTrackPageCompleted/index.mjs';
import { RichText } from '../../RichText/RichText.jsx';
import { ConditionalDisplay } from '../../ConditionalDisplay/ConditionalDisplay.jsx';
import { ButtonLink } from '../../Link/ButtonLink.jsx';
import { siteInnerMaxWidth } from '../../../config/css/ui.mjs';
import { breakpoints } from '../../../config/css/breakpoints.mjs';

// Local Variables
const StackWithRelative = styled(Stack)`
  position: relative;
`;

const ThumbnailContainer = styled.div`
  margin: 0 auto ${pxToRem(30)};
  max-width: 830px;
  width: 100%;

  @media (${breakpoints.tabletPortrait.max}) {
    padding: 0 ${pxToRem(15)};
  }
`;

const Article = styled.article`
  padding-bottom: ${pxToRem(20)};

  @media ${breakpoints.tabletLandscape.min} {
    width: ${pxToRem(siteInnerMaxWidth)};
  }
`;

const ArticleContent = styled(RichText)`
  font-size: ${pxToRem(16)};
  font-variant-ligatures: no-common-ligatures;
  line-height: 1.625;

  @media ${breakpoints.tabletLandscape.min} {
    font-size: ${pxToRem(18)};
  }

  @media ${breakpoints.tabletPortrait.max} {
    line-height: 1.5;
  }
`;

const AuthorOverviewContainer = styled.div`
  margin: ${pxToRem(50)} 0 ${pxToRem(60)};
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.color.surface.container.separator};
  height: 1px;
  margin: ${pxToRem(24)} 0;
  width: 100%;
`;

const CallToActionWrapper = styled(Stack)`
  margin: ${pxToRem(32)} auto ${pxToRem(40)};
  max-width: ${pxToRem(620)};
  text-align: center;

  @media ${breakpoints.tabletLandscape.min} {
    margin: ${pxToRem(40)} auto;
  }
`;

const CallToActionText = styled(Text)`
  font-size: ${pxToRem(20)};
  line-height: 1.4;
  margin-bottom: ${pxToRem(32)};
`;

// Component Definition
function DefaultContentItemBase({
  contentFiltered,
  item: {
    date,
    excerpt,
    title,
    metadata: {
      coauthors,
      featuredImageAlt,
      featuredImageUrl,
      featuredVideoUrl,
      link,
      featuredImageMeta: { height: featuredImageWidth, width: featuredImageHeight },
      tracking: { ctaDesc, ctaLabel, goLink },
      // breadcrumbLinks,
    },
  },
  related: { relatedPosts, authorRelated },
  currentEdition,
  contentContainerRef,
  pageTrackingData,
  isInProductMagazine,
  is404,
  onCtaClicked,
  isLoadingRelated,
}) {
  const { data: trackingData } = pageTrackingData;
  const contentElement = useTrackPageCompleted({
    is404,
    threshold: 1,
    trackingData,
  });
  const author = coauthors[0]; // Always use the first coauthor
  const { absolutePath: authorUrl, relativePath, avatarUrl, displayName } = author || {};

  const articleDescription = sanitizeHtml(excerpt, plainTextNoHTML);
  const articleTitleAndLink = `${title} ${link}`;
  // Checking if article content contains the code block
  const hasCodeBlock = contentFiltered.includes('wp-block-code');
  const hasImg = contentFiltered.includes('<img');
  const articleContent = hasImg ? updateImagesForLazyloading(contentFiltered) : contentFiltered;

  const shouldShowRecommendArticles = !isInProductMagazine;
  const hasRecommendedArticles = relatedPosts.length > 0;
  const authorImageUrl = `${avatarUrl}?fit=320,320`;

  return (
    <Stack gap={16}>
      <BlogPostingStructuredData
        authorUrl={authorUrl}
        articleWordCount={articleContent.split(/\s+/g).length}
        articleDescription={articleDescription}
        authorImageHeight={320}
        authorImageUrl={authorImageUrl}
        authorImageWidth={320}
        articleUrl={link}
        currentEdition={currentEdition}
        articlePublishDate={date}
        authorName={displayName}
        featuredImageHeight={featuredImageHeight}
        featuredImageUrl={featuredImageUrl}
        featuredImageWidth={featuredImageWidth}
        title={title}
      />
      {/* !isInProductMagazine && <BreadcrumbLinks breadcrumbMetadata={breadcrumbLinks} /> */}
      <Article>
        <Stack>
          <ArticleHeader
            articleDate={date}
            articleAbsoluteLink={link}
            articleImage={featuredImageUrl}
            authorAvatar={authorImageUrl}
            authorLink={relativePath}
            authorName={displayName}
            currentEdition={currentEdition}
            excerpt={excerpt}
            isArticlePage
            title={title}
            isInProductMagazine={isInProductMagazine}
          />
          <Stack as="section">
            <StackWithRelative alignItems="center">
              {!isInProductMagazine && (
                <ConditionalDisplay hideUpTo="tabletLandscape">
                  <StickyShareButtons
                    currentEdition={currentEdition}
                    shareProps={{
                      description: articleDescription,
                      image: featuredImageUrl,
                      link,
                      title,
                      titleAndLink: articleTitleAndLink,
                    }}
                  />
                </ConditionalDisplay>
              )}
              <Stack alignItems="center">
                <ThumbnailContainer>
                  {featuredImageUrl && !featuredVideoUrl ? (
                    <Thumbnail
                      alt={featuredImageAlt}
                      originalSource={featuredImageUrl}
                      position="featuredImage"
                      wrapperStyle="articleImage"
                    />
                  ) : (
                    <FeaturedVideo title={title} url={featuredVideoUrl} />
                  )}
                </ThumbnailContainer>
                <ArticleContent richText={articleContent} ref={contentContainerRef} />
                <div ref={contentElement} />
                {!isInProductMagazine && (
                  <>
                    {goLink && ctaLabel && (
                      <CallToActionWrapper alignItems="center">
                        <CallToActionText fontWeight={600}>{ctaDesc}</CallToActionText>
                        <ButtonLink
                          minWidth={pxToRem(250)}
                          onClick={() => onCtaClicked({ placement: 'article_body', url: goLink })}
                          size="jumbo"
                          to={goLink}
                        >
                          {ctaLabel}
                        </ButtonLink>
                      </CallToActionWrapper>
                    )}{' '}
                    <ConditionalDisplay hideAbove="tabletLandscape">
                      <Stack alignItems="center" gap={32}>
                        <Text fontSize="medium" fontWeight={600}>
                          <I18n field="share" defaultText="Share this article" />
                        </Text>
                        <Inline>
                          <ShareButtons
                            currentEdition={currentEdition}
                            shareProps={{
                              description: articleDescription,
                              image: featuredImageUrl,
                              link,
                              title,
                              titleAndLink: articleTitleAndLink,
                            }}
                          />
                        </Inline>
                      </Stack>
                    </ConditionalDisplay>
                  </>
                )}
              </Stack>
            </StackWithRelative>
            <Separator />
            <Stack as="footer">
              {author && (
                <AuthorOverviewContainer>
                  <AuthorOverview
                    articles={authorRelated}
                    currentEdition={currentEdition}
                    isInProductMagazine={isInProductMagazine}
                    {...author} // eslint-disable-line react/jsx-props-no-spreading -- allow spread
                  />
                </AuthorOverviewContainer>
              )}
              {shouldShowRecommendArticles && hasRecommendedArticles ? (
                <Stack gap={32}>
                  {/* eslint-disable-next-line react/jsx-pascal-case -- API from the design-system */}
                  <Header.h2>
                    <I18n field="recommended-articles" defaultText="More Articles" />
                  </Header.h2>
                  <ListViewContext.Provider value="threeUp">
                    <ContentList items={relatedPosts} />
                  </ListViewContext.Provider>
                </Stack>
              ) : (
                isLoadingRelated && (
                  <Stack alignItems="center" padding={32}>
                    <Spinner size={48} />
                  </Stack>
                )
              )}
            </Stack>
          </Stack>
          {hasCodeBlock && <CodeBlockSyntaxHighlighter articleContent={contentFiltered} />}
        </Stack>
      </Article>
    </Stack>
  );
}

DefaultContentItemBase.propTypes = {
  contentContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  contentFiltered: PropTypes.string.isRequired,
  currentEdition: LANGUAGES.isRequired,
  is404: PropTypes.bool.isRequired,
  isInProductMagazine: PropTypes.bool.isRequired,
  isLoadingRelated: PropTypes.bool.isRequired,
  item: PropTypes.shape(CONTENT_ITEM).isRequired,
  onCtaClicked: PropTypes.func.isRequired,
  pageTrackingData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- dynamically built
  related: PropTypes.shape({
    authorRelated: PropTypes.arrayOf(PropTypes.shape(CONTENT_ITEM)),
    relatedPosts: PropTypes.arrayOf(PropTypes.shape(CONTENT_ITEM)),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  is404: selectIs404(state),
  pageTrackingData: selectPageTrackingData(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCtaClicked: actionCtaClicked,
    },
    dispatch,
  );

const withRedux = connect(mapStateToProps, mapDispatchToProps, undefined, options);
const DefaultContentItem = withRedux(DefaultContentItemBase);

// Module Exports
export { DefaultContentItem };
