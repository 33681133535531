// External Imports
import template from 'lodash/template.js';
import pathToRegexp from 'path-to-regexp';

// Internal Imports
import { globalParentPath, pathRegex } from '../config/pathRegex.mjs';
import { TERM_ARCHIVE } from '../config/types/path.mjs';
import { addQueryParameter } from './addQueryParameter.mjs';
import { tagToLearnLanguage } from './tagToLearnLanguage.mjs';

// Local Variables
const inProductQueryStringParameter = 'in_product';

// Local Functions
const getSearch = (path) => (typeof path === 'string' ? path.split('?')[1] : path?.search);
const getPathname = (path) => (typeof path === 'string' ? path.split('?')[0] : path?.pathname);

const isInProduct = (path) => Boolean(getSearch(path)?.includes(inProductQueryStringParameter));

const getInProductLocale = (path) => {
  const search = getSearch(path);
  const locale = (/locale=([a-z]{2}(_[A-Za-z]{2})?)/.exec(search) || [])[1];
  return locale;
};

const getLearnLanguageFromTags = (path) => {
  const pathname = getPathname(path);
  const tagsPathRegexp = template(pathRegex[TERM_ARCHIVE])({ contentTypes: '[\\w-]+' });
  const fullTagsPathRegexp = `${pathRegex[globalParentPath]}${tagsPathRegexp}`;
  const pathParameters = [];
  const tagsPathMatch = pathToRegexp(fullTagsPathRegexp, pathParameters).exec(pathname);
  const tag = (tagsPathMatch || [])[pathParameters.findIndex(({ name }) => name === 'slug') + 1];
  const locale = (tagsPathMatch || [])[
    pathParameters.findIndex(({ name }) => name === 'language') + 1
  ];
  return tag && locale ? tagToLearnLanguage(locale, tag) : undefined;
};

const getLearnLanguageFromSearch = (path) => {
  const search = getSearch(path);
  const learnLanguageSearchRegexp = /learn_language=([A-Z]{3}(_[A-Za-z]{2})?)/;
  return (learnLanguageSearchRegexp.exec(search) || [])[1];
};

const getInProductLearnLanguage = (path) =>
  getLearnLanguageFromSearch(path) || getLearnLanguageFromTags(path);

const makePathInProduct = (path, locale, learnLanguage) => {
  let pathInProduct = addQueryParameter(path, inProductQueryStringParameter);
  if (locale) {
    pathInProduct = addQueryParameter(pathInProduct, `locale=${locale}`);
  }
  if (learnLanguage) {
    pathInProduct = addQueryParameter(pathInProduct, `learn_language=${learnLanguage}`);
  }
  return pathInProduct;
};

// Module Exports
export { getInProductLearnLanguage, getInProductLocale, isInProduct, makePathInProduct };
