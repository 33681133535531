// Local Variables
const TAG = 'tags';
const ENGLISH_TAGS = 'post_tag';
const GERMAN_TAGS = 'german_tags';
const FRENCH_TAGS = 'french_tags';
const ITALIAN_TAGS = 'italian_tags';
const POLISH_TAGS = 'polish_tags';
const PORTUGUESE_TAGS = 'portuguese_tags';
const SPANISH_TAGS = 'spanish_tags';
const SWEDISH_TAGS = 'swedish_tags';

const CATEGORY = 'categories';
const ENGLISH_CATEGORIES = 'category';
const GERMAN_CATEGORIES = 'german_categories';
const FRENCH_CATEGORIES = 'french_categories';
const ITALIAN_CATEGORIES = 'italian_categories';
const POLISH_CATEGORIES = 'polish_categories';
const PORTUGUESE_CATEGORIES = 'portuguese_categories';
const SPANISH_CATEGORIES = 'spanish_categories';
const SWEDISH_CATEGORIES = 'swedish_categories';

// Module Exports
export {
  CATEGORY,
  ENGLISH_CATEGORIES,
  ENGLISH_TAGS,
  FRENCH_CATEGORIES,
  FRENCH_TAGS,
  GERMAN_CATEGORIES,
  GERMAN_TAGS,
  ITALIAN_CATEGORIES,
  ITALIAN_TAGS,
  POLISH_CATEGORIES,
  POLISH_TAGS,
  PORTUGUESE_CATEGORIES,
  PORTUGUESE_TAGS,
  SPANISH_CATEGORIES,
  SPANISH_TAGS,
  SWEDISH_CATEGORIES,
  SWEDISH_TAGS,
  TAG,
};
