// External Imports
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import {
  pxToRem,
  styled,
  Inline,
  NavBarWrapper,
  NavBarSection,
  IconArrowLeft,
  IconClose,
} from '@lessonnine/design-system.lib';

// Internal Imports
import { discoverDeeplinkUrl } from '../../utils/discoverDeeplinkUrl.mjs';
import { selectEdition } from '../../selectors/selectEdition.mjs';
import { selectIsArticlePage } from '../../selectors/selectIsArticlePage.mjs';
import { selectLearnLanguage } from '../../selectors/selectLearnLanguage.mjs';
import { selectLocale } from '../../selectors/selectLocale.mjs';
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { LANGUAGES } from '../../config/types/prop.mjs';
import { learnLanguageToTag } from '../../utils/tagToLearnLanguage.mjs';
import { ButtonLink } from '../Link/ButtonLink.jsx';
import { DsLink } from '../Link/DsLink.jsx';
import { ConditionalDisplay } from '../ConditionalDisplay/ConditionalDisplay.jsx';
import { Logo } from './Logo.jsx';
import { options } from '../../config/reactRedux.mjs';

const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  & nav {
    margin-left: 0 !important;
  }

  @media ${breakpoints.desktop.min} {
    position: relative;
  }
`;

// Adjust the button’s position so that it’s content is aligned with the rest of the content on the page, using a negative right margin equivalent to its padding. We need to keep the padding otherwise the hover effect would not be centered
// To ensure a one-pixel better alignment for the hover effect, set the margin to 15 pixels instead of 16.
const CloseButton = styled(ButtonLink)`
  margin-right: -${pxToRem(15)};
`;

// Component Definition
function InProductHeaderBase({ currentEdition, isArticlePage, learnLanguage, locale }) {
  const linkTo = isArticlePage
    ? `/${currentEdition}/magazine/tags/${learnLanguageToTag(currentEdition, learnLanguage)}`
    : discoverDeeplinkUrl(learnLanguage, locale);
  return (
    <>
      <FixedHeader>
        <NavBarWrapper
          hasMobileMenu={false}
          showLogo={false}
          leftAlignedItems={
            <NavBarSection>
              <ConditionalDisplay hideUpTo="tabletPortrait">
                <Logo />
              </ConditionalDisplay>
              {!isArticlePage && (
                <ConditionalDisplay hideAbove="tabletPortrait">
                  <DsLink to={linkTo} underlined={false} weight="strong">
                    <Inline alignItems="center" gap={8}>
                      <IconArrowLeft />
                      <I18n field="in-app-back-button-mobile" defaultText="Explore" />
                    </Inline>
                  </DsLink>
                </ConditionalDisplay>
              )}
            </NavBarSection>
          }
          rightAlignedItems={
            <NavBarSection>
              <ConditionalDisplay hideUpTo="tabletPortrait">
                <DsLink to={linkTo}>
                  <I18n
                    field={isArticlePage ? 'in-app-article-close' : 'in-app-back-button-desktop'}
                    defaultText={isArticlePage ? 'Close' : 'Back to explore'}
                  />
                </DsLink>
              </ConditionalDisplay>
              <ConditionalDisplay hideAbove="tabletPortrait">
                {isArticlePage && (
                  <CloseButton color="tertiary" icon={<IconClose />} title="Close" to={linkTo} />
                )}
              </ConditionalDisplay>
            </NavBarSection>
          }
        />
      </FixedHeader>
      {!isArticlePage && (
        <Inline align="center" padding={[20, 0, 0, 0]}>
          <ConditionalDisplay hideAbove="tabletPortrait">
            <Logo />
          </ConditionalDisplay>
        </Inline>
      )}
    </>
  );
}

InProductHeaderBase.propTypes = {
  currentEdition: LANGUAGES.isRequired,
  isArticlePage: PropTypes.bool.isRequired,
  learnLanguage: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  currentEdition: selectEdition(state),
  isArticlePage: selectIsArticlePage(state),
  learnLanguage: selectLearnLanguage(state),
  locale: selectLocale(state),
});

const withRedux = connect(mapStateToProps, undefined, undefined, options);
const InProductHeader = withRouter(withRedux(InProductHeaderBase));

// Module Exports
export { InProductHeader };
