// External Imports
import A11yDialog from 'a11y-dialog';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, pxToRem, Stack, IconClose, styled } from '@lessonnine/design-system.lib';

// Internal Imports
import { isIosDevice } from '../../utils/environment.mjs';
import { ToggleContainerContext } from '../../context/toggleContainer.mjs';
import { siteGutter } from '../../config/css/ui.mjs';

const SlideFromRight = styled.div`
  background: none;
  border: none;
  display: block;
  height: 100vh;
  left: 0;
  margin: 0;
  max-height: unset;
  max-width: unset;
  overflow-y: scroll;
  padding: 0;
  position: fixed;
  top: 0;
  transform: translateX(0);

  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties -- the alternative opacity does not remove elements for Accessibility */
  transition: transform 0.3s ease, visibility 0.3s ease;
  visibility: visible;
  width: 100vw;
  z-index: 6;

  &&[aria-hidden] {
    transform: translateX(100%);
    visibility: hidden;
  }
`;

const ModalContent = styled(Stack)`
  background-color: ${({ theme }) => theme.color.surface.container.background};
  min-height: 100%;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CloseButton = styled(Button)`
  right: -${pxToRem(siteGutter)};
`;

// Local Functions
function lockScroll(modalElement) {
  if (isIosDevice()) {
    import('body-scroll-lock').then((bodyScrollLock) => {
      bodyScrollLock.disableBodyScroll(modalElement);
    });
  } else {
    document.body.style.overflow = 'hidden';
  }
}

function unlockScroll(modalElement) {
  if (isIosDevice()) {
    import('body-scroll-lock').then((bodyScrollLock) => {
      bodyScrollLock.enableBodyScroll(modalElement);
    });
  } else {
    document.body.style.overflow = 'auto';
  }
}

// Component Definition
function ToggleContainer({ button, children, modalId, modalTitle }) {
  const dialogElementRef = useRef();
  const allyDialogRef = useRef();
  const [isOpened, setIsOpened] = useState(false);
  const hasOpenedPreviously = useRef(false);

  const close = useCallback(() => {
    allyDialogRef.current.hide();
    unlockScroll(dialogElementRef.current);
  }, []);
  const show = useCallback(() => {
    const onShowed = () => {
      dialogElementRef.current.removeEventListener('transitionend', onShowed);
      hasOpenedPreviously.current = true;
      setIsOpened(true);
    };
    dialogElementRef.current.addEventListener('transitionend', onShowed);
    allyDialogRef.current.show();
    lockScroll(dialogElementRef.current);
  }, []);

  const toggleProviderValue = useMemo(() => ({ close, isOpened }), [close, isOpened]);

  useEffect(() => {
    const refCache = dialogElementRef.current;
    const onClose = () => setIsOpened(false);
    if (refCache) {
      allyDialogRef.current = new A11yDialog(refCache);
      refCache.addEventListener('hide', onClose);
    }
    return () => {
      if (refCache) {
        refCache.removeEventListener('hide', onClose);
      }
    };
  }, []);

  return (
    <>
      <Button
        color="tertiary"
        icon={button.icon}
        onClick={show}
        title={button.label}
        aria-controls={modalId}
        aria-haspopup="dialog"
      />
      <SlideFromRight
        aria-label={modalTitle}
        ref={dialogElementRef}
        /* We need to set aria-hidden initially to hide the dialog,
           but then we should leave its control to a11y-dialog once it has been used
           at least once. */
        // eslint-disable-next-line react/jsx-props-no-spreading -- conditional prop pattern
        {...(hasOpenedPreviously.current ? {} : { 'aria-hidden': true })}
      >
        {/* eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- role="document" can refer to a modal also */}
        <ModalContent alignItems="center" role="document" padding={[0, siteGutter]} id={modalId}>
          <CloseButtonWrapper>
            <CloseButton
              color="tertiary"
              icon={<IconClose />}
              onClick={close}
              title={`Dismiss ${modalTitle}`}
              data-a11y-dialog-hide
            />
          </CloseButtonWrapper>
          <ToggleContainerContext.Provider value={toggleProviderValue}>
            {children}
          </ToggleContainerContext.Provider>
        </ModalContent>
      </SlideFromRight>
    </>
  );
}

ToggleContainer.propTypes = {
  button: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  modalId: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
};

// Module Exports
export { ToggleContainer };
