// External Imports
import sanitizeHtml from 'sanitize-html';

// Internal Imports
import { hostnameRegex } from '../config/pathRegex.mjs';
import { languageCodesArray } from '../config/types/language.mjs';
import { logger } from './rollbar.mjs';

// Local Functions
const isAnchorLink = (url) => url[0] === '#';
const getHostnameRegexWithLocalhostAndNoDelimiters = () => {
  const baseRegex = hostnameRegex.toString();
  // Stripping away the `/` at the beginning and end of the stringified regexp as well as the end $ delimiter
  const baseRegexWithoutDelimiters = baseRegex.slice(1, -2);
  return `(localhost|${baseRegexWithoutDelimiters})`;
};
const magazineUrlRegex = `${getHostnameRegexWithLocalhostAndNoDelimiters()}/(${languageCodesArray.join(
  '|',
)})/magazine/`;
const isMagazineUrl = (url) => new RegExp(magazineUrlRegex).test(url);

const removeLinks = (articleContent, baseUrl) => {
  if (!articleContent.includes('<a')) {
    return articleContent;
  }

  return sanitizeHtml(articleContent, {
    // Don't filter out any attributes
    allowedAttributes: false,
    // Don't filter out any tags
    allowedTags: false,
    allowVulnerableTags: true,
    transformTags: {
      a(tagName, attribs) {
        const { target, ...attributesWithoutTarget } = attribs;
        if (!attributesWithoutTarget.href) {
          // Render the tag as-is but without a "target" attribute
          return { attribs: attributesWithoutTarget, tagName };
        }
        let href;
        try {
          href = new URL(attributesWithoutTarget.href, baseUrl).href;
        } catch (error) {
          // The link wasn't valid, return a span instead and log a warning
          logger.warning(`Invalid href in content link: ${attributesWithoutTarget.href}`, error);
          return { tagName: 'span' };
        }

        if (isAnchorLink(href) || isMagazineUrl(href)) {
          // Render the tag as-is but without a "target" attribute
          return { attribs: attributesWithoutTarget, tagName };
        }

        // Convert to a <span> with no attributes
        return { tagName: 'span' };
      },
    },
  });
};

// Module Exports
export { removeLinks };
