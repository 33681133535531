// External Imports
import { createSelector } from 'reselect';

// Local Functions
/**
 * Get menu items from a menu in a particular position
 *
 * @param {object} state - current app state
 * @param {string} position - menu position name
 */
const selectMenu = createSelector(
  (state) => state.menu,
  (_, position) => position,
  (menu, position) =>
    (menu[position] || []).map((menuItem) => ({
      ...menuItem,
      url: menuItem.fullUrl,
    })),
);

/**
 * Get menu items from a menu in a particular position,
 * with all submenu items filtered out
 *
 * @param {object} state - current app state
 * @param {string} position - menu position name
 */
const selectMenuTopLevel = createSelector(selectMenu, (menu) =>
  menu.filter((menuItem) => filterTopLevel(menuItem)),
);

function filterTopLevel({ parentId }) {
  return !parentId;
}

// Module Exports
export { selectMenu, selectMenuTopLevel };
