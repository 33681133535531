// External Imports
import get from 'lodash/fp/get.js';
import { compile } from 'path-to-regexp';

// Internal Imports
import { CONTENT_ITEM_SINGLE } from '../config/types/path.mjs';
import { selectPathMap } from './selectPathMap.mjs';
import { selectMatch } from './selectMatch.mjs';

// Local Functions
/**
 * Create a selector that will return a content item entity hydrated with related entities
 * based on the slug param of the current route.
 * @param {string} type content item type
 * @return {function}   selector function
 */
const createSelectContentItemBySlug = (type) =>
  function selectContentItemBySlug(state, properties) {
    // Get slug from component match which is resolved automatically from React
    // Router. As a back up use match from Redux store. In most cases these will
    // be the same, but because we control the Redux representation, we can store
    // edge cases such as the home page there.
    const slug = properties?.match.params.slug || selectMatch(state).params.slug;
    const id = get(`contentItem.${type}.idBySlug.${slug}`, state);
    if (!id) {
      return;
    }

    // eslint-disable-next-line consistent-return -- optional return
    return hydrateContentItem(state, type, id);
  };

/**
 * Create a selector that will return a content item entity hydrated with related entities.
 * @param {string} type content item type
 * @return {function}   selector function
 */
const createSelectContentItem = (type) => (state, id) => hydrateContentItem(state, type, id);

const selectItemById = (state, id) => {
  const { contentType: typeMap, contentItem: itemMap } = state;
  const contentType = Object.keys(typeMap).find((type) => !!get(`${type}.byId.${id}`, itemMap));

  if (!contentType) {
    return;
  }

  // eslint-disable-next-line consistent-return -- optional return
  return hydrateContentItem(state, contentType, id);
};

/**
 * @param {object} state
 * @param {object} state.contentItem state content item map
 * @param {object} state.author      state author map
 * @param {object} state.term        state term map
 * @param {string} type              content type
 * @param {number|string} id         content item id
 * @returns {object}                 content item shape
 */
function hydrateContentItem(state, type, id) {
  const { contentItem, author } = state;
  const pathMap = selectPathMap(state);
  const match = selectMatch(state);
  const contentItemByType = contentItem[type];
  const item = contentItemByType.byId[id];
  const postProcessedData = (contentItemByType.postProcessedData || {})[id];
  if (!item) {
    return;
  }

  const itemAuthor = author[item.author];
  const path = getContentItemPath(pathMap, item, match);

  // eslint-disable-next-line consistent-return -- optional return
  return {
    ...item,
    ...(postProcessedData ? { postProcessedData } : {}),
    author: itemAuthor,
    path,
  };
}

/**
 * Select relative path from content item data.
 * @param {object} pathMap
 * @param {object} item
 * @param {string} item.type content item type
 * @param {string} item.date ISO 8601 date
 * @param {string} item.slug content item name
 * @param {object} match
 */
function getContentItemPath(pathMap, item, { params }) {
  const {
    metadata: { type },
    slug,
  } = item;
  const compilePath = compile(pathMap[CONTENT_ITEM_SINGLE]);

  return compilePath({
    ...params,
    contentType: type,
    slug,
  });
}

// Module Exports
export { createSelectContentItem, createSelectContentItemBySlug, selectItemById };
