// External Imports
import compose from 'lodash/fp/flow.js';
import set from 'lodash/fp/set.js';
import { LOCATION_CHANGE } from 'connected-react-router';

// Internal Imports
import {
  RECEIVE_PRIMARY_CONTENT,
  RECEIVE_ERROR,
  RECEIVE_SITE_OPTIONS,
  REQUEST_NEXT_PAGE,
  REQUEST_NEXT_PAGE_FETCH_INITIATED,
  REQUEST_CONTENT_ITEM,
  REQUEST_ARCHIVE,
  REQUEST_SEARCH,
  REQUEST_SITE_OPTIONS,
} from '../config/types/action.mjs';
import { NotFoundError } from '../errors/notFoundError.mjs';
import { selectMatch } from '../selectors/selectMatch.mjs';
import { selectActivePagination } from '../selectors/selectActivePagination.mjs';

// Local Functions
/**
 * Root reducer for global state values.
 * @param {object}   state   state container
 * @param {string}   type
 * @param {*}        payload
 * @returns {object}
 */
function activeReducer(state = {}, { type, payload } = {}) {
  switch (type) {
    case LOCATION_CHANGE: {
      const {
        params: { page },
      } = selectMatch(state);
      return set('active.pagination.currentPage', page ? Number.parseInt(page, 10) : 1, state);
    }

    case REQUEST_NEXT_PAGE: {
      const pagination = selectActivePagination(state);
      const { currentPage, totalPages } = pagination;
      const nextPage = currentPage + 1;
      const newCurrentPage = nextPage <= totalPages ? nextPage : currentPage;
      return set('active.pagination.currentPage', newCurrentPage, state);
    }

    case REQUEST_NEXT_PAGE_FETCH_INITIATED:
      return set('active.loading.more', true, state);

    case REQUEST_SITE_OPTIONS:
      return set('active.loading.siteData', true, state);

    case RECEIVE_SITE_OPTIONS:
      return set('active.loading.siteData', false, state);

    case REQUEST_CONTENT_ITEM:
    case REQUEST_ARCHIVE:
    case REQUEST_SEARCH:
      return set('active.loading.content', true, state);

    case RECEIVE_PRIMARY_CONTENT:
      return compose(
        set('active.loading.content', false),
        set('active.loading.more', false),
      )(state);

    case RECEIVE_ERROR:
      return payload instanceof NotFoundError
        ? set('active.notFound', true, state)
        : set('active.error', payload, state);

    default:
      return state;
  }
}

// Module Exports
export { activeReducer };
