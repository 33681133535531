// External Imports
import get from 'lodash/fp/get.js';
import queryString from 'query-string';
import { createSelector } from 'reselect';

// Internal Imports
import { landingPageTypePathTypeMap } from '../config/types/landingPage.mjs';
import { languageCodePostTypeMap } from '../config/types/language.mjs';
import { archivePaths } from '../config/types/path.mjs';
import { selectFirstMatch } from './selectFirstMatch.mjs';

// Local Functions
const selectMatch = createSelector([get('router'), selectFirstMatch], ({ location }, match) => {
  const {
    pathType,
    params: { contentType },
  } = match ?? { params: {} };
  const isArchive =
    archivePaths.includes(pathType) || Object.keys(landingPageTypePathTypeMap).includes(pathType);
  const isLanding = Object.keys(landingPageTypePathTypeMap).includes(pathType);
  const isMultipleContentType = isArchive && !contentType;

  const query = queryString.parse(location.search || '', {
    arrayFormat: 'bracket',
  });

  return {
    ...match,
    isArchive,
    isLanding,
    isMultipleContentType,
    query,
    url: match?.url || location.pathname,
  };
});

const selectLanguage = createSelector(selectFirstMatch, (match) => match.params.language);

const selectContentType = createSelector(
  [selectMatch],
  ({ params }) => languageCodePostTypeMap[params.language] || params.contentType,
);

const selectArticleSlug = createSelector([selectMatch], ({ params }) => params.slug);

const selectPathType = createSelector([selectMatch], ({ pathType }) => pathType);

const selectUrl = createSelector([selectMatch], ({ url }) => url);

const selectQuery = createSelector([selectMatch], ({ query }) => query);

// Module Exports
export {
  selectArticleSlug,
  selectContentType,
  selectLanguage,
  selectMatch,
  selectPathType,
  selectQuery,
  selectUrl,
};
