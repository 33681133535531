const redirects = [
  {
    pattern: /^\/$/,
    replace: '/en/magazine/',
  },
  {
    pattern: /^\/en-US\/magazine\/(.+)/,
    replace: '/en/magazine/$1',
  },
  {
    pattern: /(^\/\w+\/magazine\/)(.*[A-Z].*)/,
    replace: (match, p1, p2) => (p2.includes('%') ? '' : `${p1}${p2.toLowerCase()}`),
  },
];

// Module Exports
export { redirects };
