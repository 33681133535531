// Internal Imports
import {
  ARTICLE_LINK_CLICKED,
  ARTICLE_SHARED,
  CALL_TO_ACTION_CLICKED,
} from '../config/types/action.mjs';
import { selectPageTrackingData } from '../selectors/selectPageTrackingData.mjs';
import { sendEventToGA } from '../utils/googleAnalytics.mjs';
import { trackPageEvent } from '../utils/tracking.mjs';

// Local Variables
const eventToTrackableMap = {
  [ARTICLE_LINK_CLICKED]: ({ text, url }) => ({
    link_text: text,
    link_url: url,
    name: 'magazine_page:link_clicked',
    version: 2,
  }),
  [ARTICLE_SHARED]: ({ sharingPlatform }) => ({
    name: 'magazine_page:shared',
    sharing_platform: sharingPlatform,
    version: 4,
  }),
  [CALL_TO_ACTION_CLICKED]: ({ placement, url }) => ({
    cta_placement: placement,
    link_url: url,
    name: 'magazine_page:cta_clicked',
    version: 2,
  }),
};

const eventSideEffects = {
  [CALL_TO_ACTION_CLICKED]: ({ placement }) => {
    if (placement === 'article_body') {
      sendEventToGA('article_cta_button');
    } else {
      sendEventToGA('try_babbel_button');
    }
  },
};

const trackableEvents = Object.keys(eventToTrackableMap);

// Local Functions
const trackAction = ({ payload, type }, trackingData) => {
  trackPageEvent({
    ...trackingData,
    ...eventToTrackableMap[type](payload),
  });
  eventSideEffects[type]?.(payload);
};

/*

This middleware is in charge of sending tracking events from redux actions.
It will be triggered on every dispatched action.
As long as all the tracking data has not been retrieved, it adds the actions to a queue to be tracked later.
Once the page tracking data is available, it sends all the events that were added to the queue.
It starts tracking events instantly from that point on.

*/
const trackingMiddleware = (store) => {
  let actionsQueue = [];
  return (next) => (action) => {
    const result = next(action);
    const trackingData = selectPageTrackingData(store.getState());
    if (actionsQueue.length > 0 && !trackingData.isLoading) {
      for (const queuedAction of actionsQueue) trackAction(queuedAction, trackingData.data);
      actionsQueue = [];
    }
    if (trackableEvents.includes(action.type)) {
      if (trackingData.isLoading) {
        actionsQueue.push(action);
      } else {
        trackAction(action, trackingData.data);
      }
    }
    return result;
  };
};

// Module Exports
export { trackingMiddleware };
